<template>
  <v-layout column fill-height justify-space-between>
    <v-card class="ma-5" outlined>
      <v-tabs v-model="type" center-active>
        <v-tab key="particulier" href="#particulier">
          {{ $t('customers.tabs.private') }}
        </v-tab>
        <v-tab key="bedrijf" href="#bedrijf">
          {{ $t('customers.tabs.company') }}
        </v-tab>
        <v-tab-item :value="CustomerType.PRIVATE">
          <customer-table :headers="contactHeaders" :type="CustomerType.PRIVATE" />
        </v-tab-item>
        <v-tab-item :value="CustomerType.COMPANY">
          <customer-table :headers="companyHeaders" :type="CustomerType.COMPANY" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-layout>
</template>

<script>
import customerTable from '@/components/customer/customerTable';
import i18n from '@/translations/i18n';
import { CustomerType } from '@/views/customer/types';

export default {
  components: { customerTable },
  data: () => ({
    contactHeaders: [
      {
        text: i18n.t('customers.table.headers.company'),
        value: 'company',
        selected: false,
      },
      {
        text: i18n.t('customers.table.headers.firstName'),
        value: 'first_name',
        selected: true,
      },
      {
        text: i18n.t('customers.table.headers.lastName'),
        value: 'last_name',
        selected: true,
      },
      {
        text: i18n.t('customers.table.headers.createdAt'),
        value: 'createdAt',
        align: 'end',
        sortable: false,
        selected: true,
      },
    ],
    companyHeaders: [
      {
        text: i18n.t('customers.table.headers.company'),
        value: 'company',
        selected: true,
      },
      {
        text: i18n.t('customers.table.headers.vat'),
        value: 'tva',
        selected: true,
      },
      {
        text: i18n.t('customers.table.headers.firstName'),
        value: 'first_name',
        selected: false,
      },
      {
        text: i18n.t('customers.table.headers.lastName'),
        value: 'last_name',
        selected: false,
      },
      {
        text: i18n.t('customers.table.headers.createdAt'),
        value: 'createdAt',
        align: 'end',
        sortable: false,
        selected: true,
      },
    ],
  }),
  computed: {
    CustomerType() {
      return CustomerType
    },
    type: {
      set(type) {
        if (this.$route.query.type !== type) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              first_name: undefined,
              last_name: undefined,
              company: undefined,
              type,
            },
          });
        }
      },
      get() {
        return this.$route.query.type;
      },
    },
  },
  methods: {
    openCustomerPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/customer/${id}`);
    },
  },
};
</script>
