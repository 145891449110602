export const generateCsvFromHorseSemenTable = (data: Object[]): string => {
  const keys: string[] = Object.keys(data[0]);
  const separator = ',';

  return `data:text/csv;charset=utf-8;\n ${keys.sort().join(separator)}\n${data
    .map((item: any) =>
      keys
        .map((key) => {
          let content: string;

          if (keys.indexOf(key) === keys.length - 1) {
            return `${item[key]}\n`;
          }

          if (key.trim() !== '_id') {
            if (item[key] instanceof Object && item[key].name) {
              content = item[key].name;
            } else {
              content = item[key].toString();
            }
          }

          return content || -1;
        })
        .filter((cell) => cell !== -1)
    )
    .join('')}`;
};

export const generateCsvFromSurrogateTable = (data: Object[]): string => {
  const headers: string[] = Object.keys(data[0]);
  const separator = ',';

  headers.sort();

  const csvData = data.map((item: any) =>
    headers
      .map((key) => {
        let content: string;

        if (headers.indexOf(key) === headers.length - 1) {
          return `${item[key]}\n`;
        }

        if (key.trim() === '__v') {
          return 'x';
        }

        if (key.trim() !== '_id') {
          if (item[key] instanceof Object) {
            if (key.trim() === 'owner') {
              if (item[key].type.trim() === 'bedrijf') {
                return `${item[key].company.replaceAll(',', ' ')}`;
              }
              return `${item[key].first_name} ${item[key].last_name}`;
            }

            if (item[key].name) {
              content = item[key].name;
            } else if (Array.isArray(item[key])) {
              content = item[key].length > 0 ? item[key].length : 0;
            } else {
              content = item[key];
            }
          } else if (typeof item[key] === 'boolean') {
            content = item[key].toString();
          } else {
            content = item[key];
          }
        } else {
          content = item._id;
        }

        return content || 'unavailable';
      })
      .filter((cell) => cell !== 'x')
  );

  const csv = csvData.join('');

  return `data:text/csv;charset=utf-8;\n${headers.join(separator)}\n${csv}`;
};
