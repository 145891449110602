<template>
  <v-card class="mx-5 mt-5 mb-12 no-border-print" outlined>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-switch v-model="groupByLocation" :label="$t('horsetable.group')" class="mx-5 pt-5 no-print" inset />
      <v-col class="pr-0 no-print">
        <search-horse
          :id="$route.query._id"
          :surrogate-only="$route.query.surrogate === 'true'"
          :stud-only="$route.query.stud_horse === 'true'"
          :horse-type="$route.query.type"
          :clearable="true"
          :outlined="true"
          :dense="true"
          @emit-horse="updateSearch"
        />
      </v-col>
      <button-with-menu :hidden="!selectable" :action-menu="actionMenu" :selected="selected" />
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchHorses"
        @fetching="loading = $event"
        @fetchedResults="horses = $event"
        @fetchedTotal="totalHorses = $event"
      />
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      v-model="selected"
      :headers="filteredHeaders"
      :items="horses"
      :options.sync="options"
      :server-items-length="totalHorses"
      :loading="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading-text="$t('common.loading')"
      class="ma-5"
      :show-select="selectable"
      item-key="_id"
      :group-by="groupByLocation ? 'fullLocation' : []"
      multi-sort
    >
      <template #no-data>
        {{ $t('common.noneFound', { item: $t('common.horses') }) }}
      </template>
      <template #[`group.header`]="{ groupBy, group, isOpen, toggle }">
        <td :colspan="filteredHeaders.length">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          <span>{{ customGroupName[groupBy[0]] }}: {{ group || 'onbekend' }}</span>
        </td>
      </template>
      <template #item="props">
        <tr class="clickable" @click="openHorsePage(props.item._id)">
          <td v-if="selectable" @click.stop="(event) => event.stopPropagation()">
            <v-checkbox
              dense
              hide-details
              class="ma-0 py-0"
              :input-value="props.isSelected"
              @change="props.select($event)"
              @click.stop="(event) => event.stopPropagation()"
            />
          </td>
          <td v-if="showColumn('name')">
            {{ props.item.name }}
            <v-icon v-if="props.item.stud_horse === true" small> mdi-arm-flex </v-icon>
            <v-icon v-if="props.item.death" class="grey--text" small> mdi-christianity </v-icon>
          </td>
          <td v-if="studOnly && showColumn('father')">
            {{ studOrigin(props.item) }}
          </td>
          <td v-if="showColumn('type')">
            {{ props.item.type }}
          </td>
          <td v-if="showColumn('surrogate_uid')">
            {{ props.item.surrogate_uid || '-' }}
          </td>
          <td v-if="showColumn('microchip')">
            {{ props.item.microchip || '-' }}
          </td>
          <td v-if="showColumn('location')">
            {{ props.item.fullLocation || '-' }}
          </td>
          <td v-if="showColumn('owner')">
            {{ ownerName(props.item.owner) }}
          </td>
          <td v-if="showColumn('surrogate')" class="text-right">
            <v-icon v-if="props.item.surrogate" class="green--text"> mdi-check </v-icon>
          </td>
          <td v-if="surrogateOnly && showColumn('embryo')">
            <v-edit-dialog :return-value.sync="props.item.embryo" large @save="putHorse(props.item)">
              {{ horseName(props.item.embryo) }}
              <template #input>
                <v-container class="pa-0 pt-3">
                  <span class="text-h6">{{ $t('horsetable.donor') }}</span>
                  <search-horse
                    :horse="props.item.embryo"
                    :clearable="true"
                    horse-type="Merrie"
                    :autofocus="true"
                    :outlined="true"
                    :dense="true"
                    :exclude="props.item._id"
                    @emit-horse="(horse) => selectDonor(horse, props.item)"
                  />
                </v-container>
              </template>
            </v-edit-dialog>
          </td>
          <td v-if="surrogateOnly && showColumn('embryoImplanted')">
            <v-edit-dialog :return-value.sync="props.item.embryo" class="ma-0 pa-0" large @save="calcDate(props.item)">
              {{ formatDate(props.item.embryoImplanted) || '-' }}
              <template #input>
                <v-container class="pa-0 pt-3">
                  <v-layout justify-space-between align-center>
                    <span class="text-h6">{{ $t('horsetable.implant') }}</span>
                    <v-btn v-if="props.item.embryoImplanted" color="warning" height="100%" icon @click="props.item.embryoImplanted = ''">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                  <vc-date-picker
                    v-model="props.item.embryoImplanted"
                    :attributes="[{ key: 'today', highlight: { color: 'gray', fillMode: 'light' }, dates: new Date() }]"
                    :masks="{ title: 'MMM YYYY' }"
                    class="no-border"
                    locale="nl"
                  />
                </v-container>
              </template>
            </v-edit-dialog>
          </td>
          <td v-if="showColumn('createdAt')" class="text-right">
            {{ formatDate(props.item.createdAt) }}
          </td>
          <td v-if="showColumn('updatedAt')" class="text-right">
            {{ formatDate(props.item.updatedAt) }}
          </td>
          <td v-if="showColumn('date_of_birth')" class="text-right">
            {{ formatDate(props.item.date_of_birth) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <horse-location-picker :amount="selected.length" :dialog="locationDialog" @close="locationDialog = $event" @relocate="relocate" />
    <snackbar :snackbar="snackbar" :color="snackColor" :text="snackText" @timedout="snackbar = $event" />
  </v-card>
</template>
<script>
import { ownerName, formatDate, studOrigin, horseName, compareRouteAndPush } from '@/Helpers';
import { horseAPI } from '@/services';
import HorseLocationPicker from '@/components/common/HorseLocationPicker';
import ButtonWithMenu from '@/components/common/ButtonWithMenu';
import SearchHorse from '@/components/search/SearchHorse';
import Snackbar from '@/components/common/Snackbar';
import Columns from '@/components/filters/Columns';
import Filters from '@/components/filters/Filters';
import { mapActions } from 'vuex';
import { displayLocation } from '@/Helpers/location.helper.ts';
import { generateCsvFromSurrogateTable } from '@/Helpers/table.helper.ts';
import i18n from '@/translations/i18n';

export default {
  components: {
    HorseLocationPicker,
    ButtonWithMenu,
    SearchHorse,
    Snackbar,
    Columns,
    Filters,
  },
  props: {
    headers: { type: Array, required: true },
    filters: { type: Object, required: true },
    sortBy: { type: String, default: 'name' },
    selectable: { type: Boolean },
    isSurrogatePerLocationList: { type: Boolean, default: false },
    downloadableCsv: { type: Boolean, default: false },
  },
  data: (data) => ({
    sortDesc: true,
    totalHorses: 0,
    horses: [],
    owners: [],
    totalOwners: 0,
    loading: true,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    filteredHeaders: [],
    snackbar: false,
    snackColor: '',
    snackText: '',
    selected: [],
    locationDialog: false,
    groupByLocation: false,
    customGroupName: { fullLocation: 'Locatie' },
  }),
  computed: {
    actionMenu() {
      return [{ title: i18n.t('horsetable.move'), action: this.openLocationDialog }];
    },
    surrogateOnly() {
      return !!this.headers.some((e) => e.text === 'Embryo' || e.text === '45');
    },
    studOnly() {
      return !!this.headers.some((e) => e.value === 'father');
    },
    title() {
      let title = '';
      if (this.filters.death) {
        title += i18n.t('horsetable.dec');
      }
      if (this.filters.type) {
        if (this.filters.type.value === i18n.t('horsetable.mare')) {
          if (this.filters.surrogate) {
            title += i18n.t('horsetable.surr');
          } else {
            title += i18n.t('horsetable.mares');
          }
        } else if (this.filters.type.value === i18n.t('horsetable.stallion')) {
          if (this.filters.stud_horse) {
            title += i18n.t('horsetable.studs');
          } else {
            title += i18n.t('horsetable.stallions');
          }
        }
      } else {
        title += i18n.t('horsetable.horse');
      }
      return title;
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            surrogatePerLocation: this.isSurrogatePerLocationList,
          },
          this.$route,
          this.$router
        );
      },
    },

    horses: {
      immediate: true,
      handler(newData) {
        // TODO: fix error on surrogates list load
        if (this.downloadableCsv) {
          const csvData = generateCsvFromSurrogateTable(newData);
          this.$emit('update-download-data', {
            dataUri: encodeURI(csvData),
            name: 'surrogates.csv'
          });
        }
      },
    },

    selected() {
      if (this.selectable) {
        this.$emit('selected', this.selected.length);
      }
    },
  },
  methods: {
    formatDate,
    ownerName,
    studOrigin,
    horseName,
    compareRouteAndPush,
    displayLocation,
    generateCsvFromSurrogateTable,
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setError',
    }),
    openLocationDialog() {
      this.locationDialog = true;
    },
    // eslint-disable-next-line no-param-reassign
    selectDonor(horse, selected) {
      selected.embryo = horse || null;
    },
    updateSearch(horse) {
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          _id: horse ? horse._id : undefined,
        },
        this.$route,
        this.$router
      );
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    openHorsePage(id) {
      this.$router.push(`/horse/${id}`);
    },
    calcDate(horse) {
      if (horse.embryoImplanted) {
        return this.putHorse({
          ...horse,
          embryoImplanted: horse.embryoImplanted.setDate(horse.embryoImplanted.getDate() + 37),
        });
      }
      return this.putHorse(horse);
    },
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    },
    async relocate(stable) {
      try {
        await horseAPI.relocateHorse({ horses: this.selected, stable });
        this.locationDialog = false;
        this.selected = [];
        await this.getHorses();
        await this.resetError();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async putHorse(horse) {
      try {
        await horseAPI.putHorse(horse);
        await this.getHorses();
        this.showSnackbar('success', `${horseName(horse)} succesvol opgeslagen`);
      } catch (err) {
        this.showSnackbar('error', `fout opgetreden tijdens het opslagen van ${horseName(horse)}`);
        await this.setError(err.message);
      }
    },
    async getHorse(id) {
      this.loading = true;
      try {
        const { data } = await horseAPI.getHorse(id);
        this.horses = [data];
        this.totalHorses = this.horses.length;
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loading = false;
      }
    },
    fetchHorses(URLparams) {
      return horseAPI.getHorses(URLparams);
    },
    async getHorses() {
      this.loading = true;
      try {
        const {
          data: { results, total },
        } = await horseAPI.getHorses(this.$route.query);
        this.horses = results;
        this.totalHorses = total;
        await this.resetError();
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.no-border {
  border: unset;
}
</style>
