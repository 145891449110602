<template>
  <v-data-table
    v-if="protocol"
    :headers="headers"
    :items="protocol.products"
    :item-key="protocol.products._id"
    hide-default-header
    hide-default-footer
    class="products-table"
  >
    <template #no-data>
      {{ $t('miniproduct.nodata') }}
    </template>
    <template #[`item.amount`]="props">
      <v-edit-dialog v-if="!disabled" :return-value.sync="props.item.amount">
        {{ productFormatAmount(props.item) }}
        <template #input>
          <v-text-field
            v-model="props.item.amount"
            type="number"
            :label="$t('miniproduct.edit')"
            single-line
            autofocus
            :disabled="disabled"
          />
        </template>
      </v-edit-dialog>
      <div v-else>
        {{ productFormatAmount(props.item) }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { productFormatAmount } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  props: ['protocol', 'disabled'],
  data: () => ({
    headers: [
      { text: i18n.t('miniproduct.name'), value: 'product.name', width: '100px' },
      { text: i18n.t('miniproduct.amount'), value: 'amount', align: 'end' },
    ],
  }),
  methods: {
    productFormatAmount,
  },
};
</script>

<style scoped>
.products-table {
  width: 100% !important;
  background-color: #f9f9f9;
}
</style>
