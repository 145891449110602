
import Vue from 'vue';
import i18n from '@/translations/i18n';

export default Vue.extend({
  props: {
    lines: { type: Array, default: () => [] },
    groupTitle: { type: String, default: '' },
  },
  data: () => ({
    headers: [
      { text: i18n.t('cases.invoices.overview.flyoutTable.headers.description'), value: 'description' },
      { text: i18n.t('cases.invoices.overview.flyoutTable.headers.amount'), value: 'quantity' },
      { text: i18n.t('cases.invoices.overview.flyoutTable.headers.pricePerUnit'), value: 'unit_price' },
      { text: i18n.t('cases.invoices.overview.flyoutTable.headers.vat'), value: 'tax_rate_id' },
    ],
    options: {},
  }),
  computed: {
    totalPriceExclVAT(): number {
      return this.lines.reduce((prev, curr) => {
        prev += curr.unit_price.amount * curr.quantity;
        return prev;
      }, 0);
    },
    totalVATPrice(): number {
      return this.lines.reduce((prev, curr) => {
        const vatValue = Number(curr.tax_rate_id.split('%')[0]);
        prev += curr.unit_price.amount * curr.quantity * (vatValue / 100);
        return prev;
      }, 0);
    },
  },
  methods: {
    roundToTwoDecimals(value: number) {
      return Math.round(value * 100) / 100;
    },
  },
});
