<template>
  <v-layout column fill-height>
    <PrintHeader :title="report.name" />
    <v-card v-if="kees" class="mx-5 mt-5 no-border-print" outlined>
      <v-toolbar flat class="no-print">
        <v-toolbar-title>{{ language === 'NL' ? report.name : report.eng }}</v-toolbar-title>
      </v-toolbar>
      <v-layout column class="mx-5 mb-5">
        <span id="customer"
          ><b>{{ language === 'NL' ? 'Klant' : 'Customer' }}:</b> {{ ownerName(kees.customer) }}</span
        >
        <span
          ><b>{{ language === 'NL' ? 'Ik ondergetekende' : 'I, the undersigned' }}:</b> {{ doctor }}</span
        >
        <span
          ><b>{{ language === 'NL' ? 'Verklaar op deze datum' : 'Declare on this date' }}:</b> {{ formatDate(date) }}</span
        >
        <v-layout row class="mx-0" align-baseline>
          <div class="my-8" style="display: flex; flex-direction: column; width: 50%">
            <h4>{{ $t('fatcert.embryo') }}</h4>
            <template v-if="embryo">
              <span>{{ language === 'NL' ? 'Donor merrie' : 'Donor mare' }}: {{ horseName(embryo.donor_mare) }}</span>
              <span>{{ language === 'NL' ? 'Donor hengst' : 'Donor stallion' }}: {{ horseName(embryo.donor_stallion) }}</span>
              <span
                >{{ language === 'NL' ? 'Type embryo' : 'Embryo type' }}:
                {{ language === 'NL' ? 'Bevrozen / ICSI embryo' : 'Frozen / ICSI embryo' }}</span
              >
            </template>
            <template v-else>
              <span>{{ language === 'NL' ? 'Donor merrie' : 'Donor mare' }}: {{ horseName(kees.horse) }}</span>
              <span>{{ language === 'NL' ? 'Donor hengst' : 'Donor stallion' }}: {{ horseName(kees.extra) }}</span>
            </template>
          </div>
          <div class="mb-5" style="display: flex; flex-direction: column; width: 50%">
            <template v-if="embryo">
              <h4>{{ $t('fatcert.emcode') }}</h4>
              <span>{{ embryo.code || '-' }}</span>
            </template>
            <template v-else>
              <h4>{{ $t('fatcert.eminfo') }}</h4>
              <span>{{ language === 'NL' ? 'A.I. datum' : 'A.I. date' }}: {{ formatDate(aiDate) || 'Nog te selecteren' }}</span>
              <span>{{ language === 'NL' ? 'Datum spoeling' : 'Flush date' }}: {{ formatDate(flushDate) || 'Nog te selecteren' }}</span>
            </template>
          </div>
        </v-layout>
        <div class="mb-5" style="display: flex; flex-direction: column">
          <h4>{{ language === 'NL' ? 'Transplantatie' : 'Transplantation' }}</h4>
          <span>{{ language === 'NL' ? 'Datum' : 'Date' }}: {{ formatDate(date) }}</span>
        </div>
        <div class="mb-5" style="display: flex; flex-direction: column">
          <h4>{{ language === 'NL' ? 'Draagmoeder' : 'Recipient mare' }}</h4>
          <span>{{ language === 'NL' ? 'Naam' : 'Name' }}: {{ horseName(protocolHorse) }}</span>
          <span>{{ language === 'NL' ? 'Geboortedatum' : 'Date of Birth' }}: {{ formatDate(new Date(protocolHorse.date_of_birth)) }}</span>
          <span>{{ $t('fatcert.chip') }} {{ protocolHorse.microchip || '-' }}</span>
        </div>
        <div class="mb-5" style="display: flex; flex-direction: column">
          <h4>{{ language === 'NL' ? 'Plaats van implantatie' : 'Place of implantation' }}</h4>
          <span>{{ companyInfo.name }}</span>
          <span>{{ companyInfo.address }}</span>
          <span>{{ companyInfo.postalCode }} {{ companyInfo.city }}</span>
          <span>{{ companyInfo.country }}</span>
        </div>
        <strong>{{ $t('fatcert.salt') }} {{ formatDate(new Date()) }}</strong>
        <signature-display v-if="doctor" class="ma-5" :doctor="doctor" />
      </v-layout>
    </v-card>
    <v-card class="ma-5 no-print" outlined>
      <v-card-title>{{ $t('fatcert.title') }}</v-card-title>
      <v-row class="mx-1 mb-2">
        <v-col cols="6">
          <v-select
            v-model="doctor"
            :items="doctors"
            :label="$t('fatcert.sign')"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="language"
            :items="languages"
            :label="$t('fatcert.lang')"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col v-if="!embryo" cols="6">
          <date-picker
            :date="flushDate"
            :label="$t('fatcert.flush')"
            :hide-details="true"
            :outlined="true"
            :dense="true"
            @select-date="flushDate = $event"
          />
        </v-col>
        <v-col v-if="!embryo" cols="6">
          <date-picker
            :date="aiDate"
            :label="$t('fatcert.ai')"
            :hide-details="true"
            :outlined="true"
            :dense="true"
            @select-date="aiDate = $event"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="end" class="gap-4 mb-5 no-print" dense>
      <Button type="back" :action="() => $router.back()" />

      <v-btn style="width: fit-content" color="success" depressed @click="print">
        {{ $t('fatcert.print') }}
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import { print, formatDate, horseName, ownerName, calculateAge, embryoHorses } from '@/Helpers';
import { getMostRecentProtocolDate } from '@/Helpers/case.helpers';
import { CaseAPI, configAPI, horseAPI, icsiAPI } from '@/services';
import PrintHeader from '@/components/common/PrintHeader';
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay';
import DatePicker from '@/components/DateSelector/DatePicker';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, PrintHeader, SignatureDisplay, DatePicker },
  data: (data) => ({
    doctor: null,
    doctors: [],
    customer: null,
    mare: null,
    protocolHorse: null,
    date: null,
    flushDate: null,
    aiDate: null,
    fetching: false,
    kees: null,
    embryo: null,
    title: '',
    report: {},
    language: 'NL',
    languages: ['NL', 'ENG'],
    companyInfo: data.$store.state.companyInfo,
  }),
  beforeMount() {
    const { caseId, date, horse, embryo, title } = this.$route.query;
    this.date = new Date(date);
    this.flushDate = this.date;
    Promise.all([this.getAllCases(caseId), this.getProtocolHorse(horse), this.getEmbryo(embryo), this.getProtocolReportTypes(title)]);
  },
  methods: {
    print,
    horseName,
    ownerName,
    formatDate,
    embryoHorses,
    calculateAge,
    async getProtocolHorse(horseId) {
      try {
        if (horseId) {
          const { data } = await horseAPI.getHorse(horseId);
          this.protocolHorse = data;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getEmbryo(embryoId) {
      try {
        if (embryoId) {
          const { data } = await icsiAPI.getEmbryo(embryoId);
          this.embryo = data;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getAllCases(caseId) {
      try {
        const { data } = await CaseAPI.get(caseId);
        this.kees = data;
        this.aiDate = getMostRecentProtocolDate(this.kees);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getProtocolReportTypes(title) {
      try {
        const {
          data: { reportTypes },
        } = await configAPI.getProtocolReportTypes();
        const [report] = reportTypes.filter((rep) => rep.name === title);
        this.report = report;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    getMostRecentProtocolDate,
  },
};
</script>

<style scoped>
h4 {
  text-decoration: underline;
  margin-bottom: 0.5em;
}
b,
h4,
span {
  color: #314d9e;
}
strong,
.v-toolbar__title {
  font-weight: bolder;
  color: #e9622c;
}
#customer {
  margin-bottom: 1rem;
}
</style>
