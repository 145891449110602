export const displayLocation = (location) => {
  if (location) {
    if (location.prefix) {
      return `${location.stable} ${location.prefix}-${location.name}`;
    }
    return `${location.stable} ${location.name}`;
  }
  return '-';
};

export const getLocationPrefixes = (locations) => Array.from(new Set(locations.map(({ prefix }) => prefix))).sort();
