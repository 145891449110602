<template>
  <v-card class="ma-5 no-border-print" outlined>
    <v-toolbar flat color="primary" dark class="d-print-none">
      <v-toolbar-title>{{ $t('yearlyreport.title') }}</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="selectedTab">
      <v-tab key="general">
        {{ $t('yearlyreport.gen') }}
      </v-tab>
      <v-tab-item>
        <general-info-table />
      </v-tab-item>
      <v-tab key="tables">
        {{ $t('yearlyreport.tables') }}
      </v-tab>
      <v-tab-item key="tables">
        <v-row class="mx-5 mt-5">
          <v-col cols="12" md="6" lg="3">
            <v-select :value="year" :label="$t('yearlyreport.select')" :items="reportYearOptions" outlined @change="selectYear" />
          </v-col>
        </v-row>
        <v-list-item
          v-for="(tableType, index) of tableTypes"
          :key="tableType.type"
          :to="{ name: 'type-yearly-report-table', params: { type: tableType.type }, query: { year: year } }"
        >
          <div class="rows">
            <span class="font-weight-bold">{{ $t('yearlyreport.table') }} {{ index + 1 }}. {{ yearlyReportTitles[tableType.type] }}</span>
            <component :is="tableType.component" class="print-page-break" />
          </div>
        </v-list-item>
        <v-row dense justify="end" class="no-print">
          <v-btn
            style="width: fit-content"
            color="success"
            class="mr-5 mb-5 no-print"
            :disabled="loading"
            :loading="loading"
            depressed
            @click="print"
          >
            {{ $t('yearlyreport.print') }}
          </v-btn>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import EmbryoGainTable from '@/components/yearly-report/table/mare/EmbryoGainTable';
import EmbryoTransplantationTable from '@/components/yearly-report/table/mare/EmbryoTransplantationTable';
import GeneralInfoTable from '@/components/yearly-report/table/GeneralInfoTable';
import SpermGainTable from '@/components/yearly-report/table/stallion/SpermGainTable';
import SpermSentReceivedTable from '@/components/yearly-report/table/stallion/SpermSentReceivedTable';
import { print } from '@/Helpers';
import {
  semenGainType,
  semenImportExportType,
  ovumGainType,
  yearlyReportTitles,
  embryoTransplantation,
} from '@/consts/yearlyReport/yearlyReport.types';
import YearlyReportAPI from '@/services/YearlyReportAPI';

export default {
  components: {
    SpermSentReceivedTable,
    EmbryoGainTable,
    EmbryoTransplantationTable,
    SpermGainTable,
    GeneralInfoTable,
  },
  data: (data) => ({
    tableTypes: [
      {
        type: semenGainType,
        component: SpermGainTable,
      },
      {
        type: semenImportExportType,
        component: SpermSentReceivedTable,
      },
      {
        type: ovumGainType,
        component: EmbryoGainTable,
      },
      {
        type: embryoTransplantation,
        component: EmbryoTransplantationTable,
      },
    ],
    yearlyReportTitles,
    selectedTab: data.$route.query.tab === 'tables' ? 1 : 0,
    reportYearOptions: [],
  }),
  computed: {
    year() {
      return this.$store.state.yearlyReport.year;
    },
    loading() {
      return (
        this.$store.state.yearlyReport.semenByStallion.loading ||
        this.$store.state.yearlyReport.semenByBreed.loading ||
        this.$store.state.yearlyReport.ovumByBreed.loading ||
        this.$store.state.yearlyReport.icsiEmbryoByBreed.loading ||
        this.$store.state.yearlyReport.rinsedEmbryoByBreed.loading
      );
    },
  },
  watch: {
    selectedTab(newValue) {
      const updatedTab = newValue === 1 ? 'tables' : 'general';
      if (this.$route.query.tab !== updatedTab) {
        this.$router.replace({ query: { tab: updatedTab } });
      }
    },
  },
  async created() {
    const optionsData = await YearlyReportAPI.getOptions();
    this.reportYearOptions = optionsData.data;
    // Always take the previous year as the default when there is none selected
    if (!this.$store.state.yearlyReport.year) {
      this.selectYear(new Date().getFullYear() - 1);
    }
  },
  methods: {
    print,
    selectYear(year) {
      this.$store.dispatch('yearlyReport/setYear', { year });
    },
  },
};
</script>

<style scoped>
.rows {
  margin: 1rem;
  display: grid;
  grid-template-rows: 1fr;
}
</style>
