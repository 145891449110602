
import { productsAPI } from '@/services';
import format from 'date-fns/format';
import Vue from 'vue';
import i18n from '@/translations/i18n';

export default Vue.extend({
  name: 'ToExpireProductsBlock',
  data: () => ({
    headers: [
      { text: i18n.t('expireproductblock.name'), value: 'name', sortable: false },
      { text: i18n.t('expireproductblock.lot'), sortable: false },
      { text: i18n.t('expireproductblock.expiration'), sortable: false },
    ],
    toExpireProducts: [],
    loading: false,
  }),
  beforeMount() {
    this.getToExpireProducts();
  },
  methods: {
    format,
    goToProduct(item) {
      this.$router.push(`/stock/${item._id}`);
    },
    async getToExpireProducts() {
      this.loading = true;
      try {
        const { data } = await productsAPI.getToExpireProducts({ limit: 5 });
        this.toExpireProducts = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
});
