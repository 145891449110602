
import AnnouncementAPI from '@/services/AnnouncementAPI';
import Vue from 'vue';
import SearchHorseBlock from '@/components/dashboard/SearchHorseBlock.vue';
import ToExpireProductsBlock from '@/components/dashboard/ToExpireProductsBlock.vue';
import EventCalendar from '../event/EventCalendar.vue';

export default Vue.extend({
  name: 'Dashboard',
  components: { SearchHorseBlock, ToExpireProductsBlock, EventCalendar },
  data: () => ({
    announcement: {},
  }),
  beforeMount() {
    // this.getLatestAnnouncement();
  },
  methods: {
    async getLatestAnnouncement() {
      try {
        const { data } = await AnnouncementAPI.getLatestAnnouncement();
        this.announcement = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
  },
});
