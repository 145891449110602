<template>
  <div>
    <v-row class="mx-2">
      <v-col v-if="itemsWithParameters && itemsWithParameters.length" cols="12" class="py-0">
        <highlight-tag-group :highlightable-items="itemsWithParameters" />
      </v-col>
      <v-col cols="12" md="6">
        <protocols-table
          title="Protocols"
          :data="combined.protocols"
          :case-id="id"
          :show-date="true"
          :show-footer="combined.protocols.length >= 5"
        />
      </v-col>
      <v-col cols="12" md="6">
        <labo-table
          :data="combined.labos"
          title="Labo-onderzoeken"
          :case-id="id"
          :show-date="true"
          :show-footer="combined.labos.length >= 5"
        />
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col cols="12" md="6" style="display: flex; justify-content: space-between; flex-direction: column">
        <products-table :data="combined.products" title="products" :show-date="true" :show-footer="combined.products.length >= 5" />
      </v-col>
      <v-col cols="12" md="6">
        <pension-cost
          :horse="kees.horse"
          :pensions="kees.pension"
          title="pensionkosten"
          :disabled="kees.invoice"
          :success="success"
          @save="kees.pension = $event"
          @select-pensions="selected.pensions = $event.pensions"
        />
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col cols="12">
        <research-sheet-table
          :key="kees.researchSheets.length"
          title="Onderzoeksfiches"
          :kees="kees._id"
          :items-per-page="kees.researchSheets.length"
          :research-sheets="kees.researchSheets"
          :hide-footer="true"
          :success="success"
          @select-sheets="selected.sheets = $event.sheets"
        />
      </v-col>
    </v-row>
    <div class="mr-2">
      <v-row class="mt-4" justify="end" dense>
        <v-btn depressed color="primary" class="mr-2" @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          {{ $t('caseoverview.backicon') }}
        </v-btn>
        <v-btn v-if="deletable" :disabled="!deletable" depressed color="error" class="mr-2" @click="openDeleteDialog(id)">
          {{ $t('caseoverview.delete') }}
        </v-btn>
        <v-btn
          v-if="checkIntermediateInvoiceable"
          :disabled="!kees.customer"
          color="success"
          depressed
          class="mr-2"
          @click="$emit('post-invoice', selected)"
        >
          {{ $t('caseoverview.bill') }}
        </v-btn>
        <v-btn
          v-if="kees.invoice"
          :disabled="kees.status === 'Gesloten'"
          depressed
          color="warning"
          class="mr-2"
          @click="$emit('close-case', true)"
        >
          {{ $t('caseoverview.close') }}
        </v-btn>
        <v-btn v-if="!kees.invoice" color="success" class="mr-4" depressed @click="$emit('save-case', { saveOnly: true })">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('caseoverview.save') }}
        </v-btn>
      </v-row>
    </div>
    <delete-dialog :delete-dialog="deleteDialog" item="Case" :delete-queue="deleteQueue" @delete="deleteCase" @close="closeDeleteDialog" />
  </div>
</template>

<script>
import PensionCost from '@/components/case/PensionCost';
import LaboTable from '@/components/researchSheet/LaboTable';
import ProductsTable from '@/components/researchSheet/ProductsTable';
import ProtocolsTable from '@/components/researchSheet/ProtocolsTable';
import ResearchSheetTable from '@/components/researchSheet/Table';
import HighlightTagGroup from '@/components/case/HightlightTagGroup';
import DeleteDialog from '@/components/common/DeleteDialog';
import { CaseAPI } from '@/services';

export default {
  components: {
    ProtocolsTable,
    ResearchSheetTable,
    ProductsTable,
    LaboTable,
    PensionCost,
    DeleteDialog,
    HighlightTagGroup,
  },
  props: {
    id: { type: String, default: '' },
    kees: { type: Object, required: true },
    combined: { type: Object, required: true },
    initialStatus: { type: String, default: 'Open' },
    success: { type: Boolean },
  },
  data: () => ({
    deleteQueue: {},
    deleteDialog: false,
    selected: {
      sheets: [],
      pensions: [],
    },
  }),
  computed: {
    checkIntermediateInvoiceable() {
      const { sheets, pensions } = this.selected;
      return (!pensions.length || pensions.every((pension) => pension)) && ((sheets && sheets.length) || (pensions && pensions.length));
    },
    deletable() {
      return (
        this.kees && !this.kees.researchSheets.length && !this.kees.pension.length && !this.kees.invoice && this.kees.status === 'Open'
      );
    },
    itemsWithParameters() {
      return [
        ...this.combined.protocols.filter((item) => item.protocol.parameters.length),
        ...this.combined.labos.filter((item) => item.labo.parameters.length),
      ];
    },
  },
  watch: {
    success() {
      if (this.success) {
        this.selected.sheets = [];
        this.selected.pensions = [];
      }
    },
  },
  methods: {
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
    async deleteCase() {
      this.$emit('error', '');
      try {
        await CaseAPI.delete(this.id);
        await this.$router.back();
      } catch (err) {
        this.$emit('error', err.message);
      }
    },
  },
};
</script>
