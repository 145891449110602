<template>
  <protocol-form
    :protocol="protocol"
    :loading="loading"
    :disabled="disabled"
    :config="config"
    :parameter-options="parameterOptions"
    :simple-parameter-options="simpleParameterOptions"
    @update-protocol="protocol = $event"
    @change-config="config = $event"
  />
</template>

<script>
import protocolForm from '@/components/protocols/Form';
import i18n from '@/translations/i18n';
import protocolAPI from '@/services/ProtocolAPI';

export default {
  components: { protocolForm },
  props: {
    id: { type: String, default: '' },
  },
  data() {
    return {
      disabled: false,
      protocol: {
        translations: {
          fr: '',
          en: '',
        },
        products: [],
        parameters: [],
        notifications: [],
      },
      parameterOptions: [
        {
          name: i18n.t('protoprot.semen'),
          value: 'SemenCollection',
          switch: false,
        },
        {
          name: i18n.t('protoprot.embryo'),
          value: 'Embryo',
          switch: false,
        },
        {
          name: i18n.t('protoprot.horse'),
          value: 'Horse',
          switch: false,
          select: true,
          selectText: i18n.t('protoprot.sex'),
          selectValue: undefined,
          specification: true,
          specificationSwitch: false,
        },
        {
          name: i18n.t('protoprot.client'),
          value: 'Customer',
          switch: false,
        },
      ],
      simpleParameterOptions: [
        {
          name: i18n.t('protoprot.amount'),
          value: 'Amount',
          switch: false,
          input: true,
          inputText: i18n.t('protoprot.spec'),
          inputValue: undefined,
        },
      ],
      loading: false,
      config: false,
    };
  },
  beforeMount() {
    if (this.id !== 'undefined') {
      this.getProtocol(this.id);
    }
  },
  methods: {
    async getProtocol(id) {
      try {
        this.loading = true;
        const { data } = await protocolAPI.getProtocol(id);
        this.protocol = data;
        this.config =
          !!this.protocol.parameters.length ||
          !!this.protocol.notifications.length ||
          !!this.protocol.simple_parameters.length ||
          !!this.protocol.transactionConfig.length;
        this.parameterOptions = this.parameterOptions.map((option) => {
          const index = this.protocol.parameters.findIndex((param) => param.elementModel === option.value);
          return {
            ...option,
            switch: index >= 0,
            selectValue: index >= 0 ? this.protocol.parameters[index].elementType : option.selectValue,
            specificationSwitch: index >= 0 ? this.protocol.parameters[index].elementSpecification : false,
          };
        });
        this.simpleParameterOptions = this.simpleParameterOptions.map((option) => {
          const foundParam = this.protocol.simple_parameters.find((param) => param.elementType === option.value);
          return {
            ...option,
            switch: !!foundParam,
            inputValue: foundParam ? foundParam.elementName : undefined,
          };
        });
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
