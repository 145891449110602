<template>
  <v-layout column fill-height>
    <PrintHeader class="print-only" :title="title" />
    <v-card v-if="kees" class="mx-5 mt-5 no-border-print" outlined>
      <v-toolbar flat class="no-print">
        <v-toolbar-title>{{ $t('reports.insemination.title') }}</v-toolbar-title>
      </v-toolbar>
      <div class="mx-4 mb-4">
        <h4>{{ $t('reports.insemination.client') }}</h4>
        <span>{{ ownerName(kees.customer) }}</span>
      </div>
      <v-layout class="ma-4 mt-0">
        <div v-if="kees.horse" class="mare d-flex flex-column" style="width: 50%">
          <h4>{{ $t('reports.insemination.mare') }}</h4>
          <span>{{ $t('reports.insemination.name') }} {{ horseName(kees.horse) }}</span>
          <span>{{ $t('reports.insemination.ueln') }} {{ kees.horse.ueln || '-' }}</span>
          <span>{{ $t('reports.insemination.chip') }} {{ kees.horse.microchip || '-' }}</span>
        </div>
        <div v-if="protocolHorse" class="stud d-flex flex-column" style="width: 50%">
          <h4>{{ $t('reports.insemination.stud') }}</h4>
          <span>{{ $t('reports.insemination.name') }} {{ horseName(protocolHorse) }}</span>
          <span>{{ $t('reports.insemination.ueln') }} {{ protocolHorse.ueln || '-' }}</span>
          <span>{{ $t('reports.insemination.chip') }} {{ protocolHorse.microchip || '-' }}</span>
        </div>
      </v-layout>
      <div v-if="date" class="mx-4 mb-4 grid">
        <b>{{ $t('reports.insemination.dateInsemination') }}</b> <time>{{ formatDate(date) }}</time>
        <b>{{ $t('reports.insemination.dateControl') }}</b><time>{{ formatDate(controlDate) }}</time> <b>{{
          $t('reports.insemination.datePregnancy') }}</b><time>{{ formatDate(avgDateOfBirth) }}</time>
      </div>
      <div v-if="pregnancyStatement" class="mx-4">
        <h4>{{ $t('reports.insemination.pregnancyStatement') }}</h4>
        <p>
          {{ $t('reports.insemination.undersigned') }}
        </p>
        <time>{{ formatDate(executionDate) || '..../...../.......' }}</time>
        <div class="grid mt-10">
          <div>
            <p>{{ companyInfo.name }}</p>
            <p>{{ companyInfo.address }}</p>
            <p>{{ companyInfo.postalCode }} {{ companyInfo.city }}</p>
            <p>{{ companyInfo.country }}</p>
          </div>
          <div>
            <p>{{ $t('reports.insemination.date') }}: {{ formatDate(startOfToday()) }}</p>
            <p>{{ $t('reports.insemination.vet') }}: {{ doctor }}</p>
          </div>
        </div>
      </div>
      <signature-display v-if="doctor" class="ma-3" :doctor="doctor" />
    </v-card>
    <v-card class="ma-5 no-print" outlined>
      <v-card-title>{{ $t('reports.insemination.title2') }}</v-card-title>
      <v-row class="mx-1 mb-2">
        <v-col cols="6">
          <v-text-field v-model="daysToAdd" :label="$t('reports.insemination.amount')" type="number" hide-details outlined
            dense />
        </v-col>
        <v-col cols="6">
          <v-select v-model="doctor" :items="doctors" class="mx-5 mb-5"
            :label="$t('reports.insemination.selectUndersigned')" :menu-props="{ offsetY: true }" hide-details outlined
            dense />
        </v-col>
        <v-col cols="6">
          <date-picker :date="executionDate" :label="$t('reports.insemination.dateExecution')" :hide-details="true"
            :outlined="true" :dense="true" @select-date="executionDate = $event" />
        </v-col>
        <v-col cols="6">
          <v-checkbox v-model="pregnancyStatement" class="mx-5 mb-5" :label="$t('reports.insemination.addStatement')" />
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="end" class="gap-4 mb-5 no-print" dense>
      <Button type="back" :action="() => $router.back()" />

      <v-btn style="width: fit-content" color="success" depressed @click="print">
        {{ $t('common.actions.print') }}
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import { print, formatDate, horseName, ownerName } from '@/Helpers';
import { CaseAPI, horseAPI, semenAPI } from '@/services';
import PrintHeader from '@/components/common/PrintHeader';
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay';
import DatePicker from '@/components/DateSelector/DatePicker';
import startOfToday from 'date-fns/startOfToday';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, PrintHeader, SignatureDisplay, DatePicker },
  data: (data) => ({
    customer: null,
    mare: null,
    protocolHorse: null,
    date: null,
    fetching: false,
    kees: null,
    daysToAdd: 14,
    semen: null,
    title: '',
    pregnancyStatement: true,
    doctor: null,
    doctors: [],
    executionDate: startOfToday(),
    companyInfo: data.$store.state.companyInfo,
  }),
  computed: {
    controlDate() {
      return this.date.addDays(this.daysToAdd);
    },
    avgDateOfBirth() {
      return this.date.addDays(335);
    },
  },
  beforeMount() {
    const { caseId, date, horse, semencollection, title } = this.$route.query;
    this.date = new Date(date);
    this.title = title;
    Promise.all([this.getAllCases(caseId), this.getProtocolHorse(horse), this.getSemenCollection(semencollection)]);
  },
  methods: {
    print,
    horseName,
    ownerName,
    formatDate,
    startOfToday,
    async getSemenCollection(semenId) {
      try {
        if (semenId) {
          const { data } = await semenAPI.getSemenCollections(semenId);
          this.semen = data;
          this.protocolHorse = data.stallion;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getProtocolHorse(horseId) {
      try {
        if (horseId) {
          const { data } = await horseAPI.getHorse(horseId);
          this.protocolHorse = data;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getAllCases(caseId) {
      try {
        const { data } = await CaseAPI.get(caseId);
        this.kees = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>

<style scoped>
h4 {
  text-decoration: underline;
  margin-bottom: 0.5em;
}

b,
h4,
span,
time,
p {
  color: #314d9e;
}

strong,
.v-toolbar__title {
  font-weight: bolder;
  color: #e9622c;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
