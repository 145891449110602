<template>
  <v-card v-if="config" outlined class="ma-5">
    <v-toolbar flat dense light>
      <v-toolbar-title>{{ $t('advconfigtitle') }}</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <transaction-config :transaction-config="transactionConfig" @transaction-config="$emit('update:transaction-config', $event)" />
    <parameter-selector
      :parameter-options="parameterOptions"
      :simple-parameter-options="simpleParameterOptions"
      :simple-parameters="simpleParameters"
      :parameters="parameters"
      :required="mandatoryFields"
    />
    <notification-editor :data="notifications" @update="notifications = $event" />
    <report-type-selector
      :report-type="reportType"
      :parameters="parameters"
      @select-type="$emit('select-type', $event)"
      @emit-mandatory-fields="mandatoryFields = $event"
    />
  </v-card>
</template>

<script>
import TransactionConfig from '@/components/extraConfig/TransactionConfig';
import NotificationEditor from '@/components/notification/NotificationEditor';
import ParameterSelector from '@/components/extraConfig/ParameterSelector';
import reportTypeSelector from '@/components/extraConfig/reportTypeSelector';

export default {
  name: 'AdvancedConfig',
  components: { TransactionConfig, NotificationEditor, ParameterSelector, reportTypeSelector },
  props: {
    notifications: { type: Array, default: () => [] },
    parameters: { type: Array, default: () => [] },
    simpleParameters: { type: Array, default: () => [] },
    parameterOptions: { type: Array, required: true },
    simpleParameterOptions: { type: Array, required: true },
    reportType: { type: Array, default: undefined },
    transactionConfig: { type: Array, default: undefined },
    config: { type: Boolean },
  },
  data: () => ({
    mandatoryFields: [],
  }),
};
</script>
