<template>
  <v-card class="mx-5 mt-5 mb-12" outlined>
    <mods :title="title" :filters="filters" :headers="headers" />
  </v-card>
</template>

<script>
import mods from '@/components/stock/Mods';
import i18n from '@/translations/i18n';

export default {
  components: { mods },
  data: () => ({
    title: i18n.t('delivprod.title'),
    headers: [
      { text: i18n.t('delivprod.type'), value: 'type', selected: true },
      { text: i18n.t('delivprod.prod'), value: 'product', selected: true },
      { text: i18n.t('delivprod.lot'), value: 'batch', selected: true },
      { text: i18n.t('delivprod.sup'), value: 'batch.supplier', selected: true },
      { text: i18n.t('delivprod.amount'), value: 'amount', selected: true },
      { text: i18n.t('delivprod.date'), align: 'end', value: 'createdAt', selected: true },
    ],
  }),
  computed: {
    filters() {
      return {
        product: { vTag: 'searchProduct' },
        dateRange: {
          vTag: 'dateRange',
          label: i18n.t('delivprod.reach'),
          from: 'from',
          to: 'to',
        },
      };
    },
  },
};
</script>
