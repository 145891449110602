<template>
  <v-card flat>
    <v-form ref="form" v-model="valid">
      <v-row class="mx-5">
        <v-col cols="4">
          <search-labo :label="$t('labresult.select')" :labo="info.labo" :required="true" :clearable="true" @emit-labo="updateLabo" />
        </v-col>
        <v-col cols="4">
          <date-picker
            :date="info.date"
            :label="$t('labresult.date')"
            :max="new Date()"
            :required="true"
            :hide-details="true"
            mode="dateTime"
            @select-date="info.date = $event"
          />
        </v-col>
        <v-col cols="4">
          <v-file-input v-model="file" show-size :rules="required" :label="$t('labresult.file')" />
        </v-col>
      </v-row>
      <v-layout justify-end class="mb-4">
        <v-btn color="success" class="mr-4" depressed :disabled="!valid" :loading="uploading" @click="createLabResult">
          {{ $t('labresult.upload') }}
        </v-btn>
      </v-layout>
    </v-form>
    <v-data-table :headers="headers" :items="horse.labResults" :sort-by="sortBy" :sort-desc="sortDesc" item-key="_id" class="mx-5">
      <template #no-data>
        {{ $t('labresult.nodata') }}
      </template>
      <template #item="props">
        <tr>
          <td>{{ splitFilename(props.item.filename) }}</td>
          <td>{{ props.item.lab.name || '-' }}</td>
          <td>{{ formatDate(props.item.uploadDate) }}</td>
          <td class="text-end">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon class="mr-2" color="primary" @click="() => openFile(props.item)" v-on="on"> mdi-eye </v-icon>
              </template>
              <span>{{ $t('labresult.see') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon color="primary" @click="() => openDeleteDialog(props.item)" v-on="on"> mdi-delete </v-icon>
              </template>
              <span>{{ $t('labresult.delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Labo-resultaat"
      :delete-queue="deleteQueue"
      @delete="deleteLabResult"
      @close="closeDeleteDialog"
    />
  </v-card>
</template>
<script>
import DatePicker from '@/components/DateSelector/DatePicker';
import SearchLabo from '@/components/search/SearchLabo';
import horseAPI from '@/services/HorseAPI';
import DeleteDialog from '@/components/common/DeleteDialog';
import { formatDate, required } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  name: 'LabResults',
  components: { SearchLabo, DatePicker, DeleteDialog },
  props: {
    horse: { type: Object, required: true },
  },
  data: () => ({
    valid: false,
    file: null,
    info: {},
    url: '',
    sortBy: 'uploadDate',
    sortDesc: true,
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: [
      { text: i18n.t('labresult.filename'), value: 'filename' },
      { text: i18n.t('labresult.research'), value: 'lab' },
      { text: i18n.t('labresult.researchdate'), value: 'uploadDate' },
      { text: '', value: 'actions', align: 'end' },
    ],
    deleteQueue: {},
    deleteDialog: false,
    uploading: false,
  }),
  computed: { required },
  methods: {
    formatDate,
    updateLabo(searchResult) {
      this.info.labo = { _id: searchResult ? searchResult._id : undefined };
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
    splitFilename(path) {
      const splitted = path.split('/');
      return splitted[splitted.length - 1];
    },
    async openFile(labResult) {
      try {
        const {
          data: { link },
        } = await horseAPI.getPdfUrl(this.horse._id, {
          labId: labResult.lab._id,
          filename: this.splitFilename(labResult.filename),
        });
        window.open(link, '_blank');
      } catch (err) {
        console.log(err);
      }
    },
    async deleteLabResult(labResult) {
      try {
        const index = this.horse.labResults.findIndex((lab) => lab._id === labResult._id);
        this.horse.labResults.splice(index, 1);
        await horseAPI.deleteLabResult(this.horse._id, labResult.lab._id, this.splitFilename(labResult.filename));
        this.closeDeleteDialog(false);
      } catch (err) {
        console.log(err);
      }
    },
    async createLabResult() {
      this.uploading = true;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('lab', this.info.labo._id);
        formData.append('date', this.info.date);
        const { data } = await horseAPI.postLabResult(this.horse._id, formData);
        this.$refs.form.reset();
        this.$emit('update-lab', data);
      } catch (err) {
        console.log(err);
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>
