<template>
  <v-card outlined>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-btn v-if="researchSheets && researchSheets.length" color="primary" text @click="selectBillable">
        {{ selectAll ? $t('researchtable.select') : $t('researchtable.deselect') }}
      </v-btn>
    </v-toolbar>
    <v-divider v-if="researchSheets && researchSheets.length" />
    <v-data-table
      v-if="researchSheets && researchSheets.length"
      v-model="selectedSheets"
      item-key="_id"
      class="ma-5"
      :headers="filteredHeaders"
      :items="researchSheets"
      :loading="loading"
      :loading-text="$t('common.loading')"
      :items-per-page="itemsPerPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :options.sync="options"
      :single-select="false"
      :hide-default-footer="hideFooter"
    >
      <template #no-data> {{ $t('researchtable.nodata1') }} {{ title }} {{ $t('researchtable.nodata2') }} </template>
      <template #item="props">
        <tr @click="$router.push(`/case/${kees}?tab=${props.item._id}`)">
          <td @click.stop="(event) => event.stopPropagation()">
            <v-checkbox
              class="ma-0 py-0"
              hide-details
              dense
              :disabled="!props.item.billable"
              :input-value="props.isSelected"
              @change="props.select($event)"
              @click.stop="(event) => event.stopPropagation()"
            />
          </td>
          <td v-if="showColumn('researchDate')">
            {{ formatDateFull(props.item.researchDate) }}
          </td>
          <td v-if="showColumn('doctor')">
            {{ props.item.doctor || '-' }}
          </td>
          <td v-if="showColumn('billable')" class="text-end">
            <Tag
              :normal-size="true"
              :color="props.item.billable ? 'success' : 'grey'"
              :text="props.item.billable ? 'Factureerbaar' : 'Gefactureerd'"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ownerName, formatDateFull } from '@/Helpers';
import Tag from '@/components/common/Tag';
import i18n from '@/translations/i18n';

export default {
  components: { Tag },
  props: {
    researchSheets: { type: Array, required: true },
    title: { type: String, default: 'Onderzoeksfiches' },
    itemsPerPage: { type: Number, default: undefined },
    kees: { type: String, default: '' },
    success: { type: Boolean },
    hideFooter: { type: Boolean },
  },
  data: () => ({
    filteredHeaders: [],
    options: {},
    sortBy: 'name',
    sortDesc: false,
    filters: {},
    toFilter: ['doctor'],
    loading: false,
    selectedSheets: [],
    selectAll: true,
    headers: [
      { text: i18n.t('researchtable.date'), value: 'researchDate', selected: true },
      { text: i18n.t('researchtable.doctor'), value: 'doctor', selected: true },
      { text: i18n.t('researchtable.billable'), value: 'billable', selected: true },
    ],
  }),
  watch: {
    selectedSheets() {
      this.$emit('select-sheets', { sheets: this.selectedSheets.map((sheet) => sheet._id) });
    },
    success() {
      if (this.success) {
        this.selectedSheets = [];
      }
    },
  },
  methods: {
    ownerName,
    formatDateFull,
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    selectBillable() {
      this.selectedSheets = this.selectAll ? this.researchSheets.filter((rs) => rs.billable) : [];
      this.selectAll = !this.selectAll;
    },
  },
};
</script>
