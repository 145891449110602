import { getInstance } from '@/auth';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

const makeFetchCall = async (url: string, config: RequestInit): Promise<any> => {
  const accessToken = await getInstance().getTokenSilently();
  const fetchConfig: RequestInit = {
    mode: 'cors', // no-cors, cors, same-origin
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    ...config,
  };
  if (typeof config.body === 'string') {
    fetchConfig.headers['Content-Type'] = 'application/json';
  }

  try {
    const response = await fetch(url.startsWith('/') ? baseUrl + url : url, fetchConfig);
    if (response.ok) {
      if (response.status !== 204) {
        const data = await response.json();
        return { data };
      }
      return response;
    }
    const data = await response.json();
    throw data;
  } catch (err) {
    // TODO display nice error?
    if (err.status === 401) {
      getInstance().loginWithRedirect();
    }
    throw err;
  }
};

export const get = async (url: string, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    ...config,
  });

export const post = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'POST',
    body: JSON.stringify(data),
    ...config,
  });

export const postMultiPart = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'POST',
    body: data,
    ...config,
  });

export const deleteR = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'DELETE',
    body: JSON.stringify(data),
    ...config,
  });

export const put = async (url: string, data: any, config?: RequestInit) =>
  makeFetchCall(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    ...config,
  });
