<template>
  <v-col cols="12" class="py-0" style="display: grid">
    <v-switch v-model="option.switch" inset :rules="rules" :label="option.name" @change="$emit('check-parameter', option)" />
    <v-row v-if="option.switch && option.input">
      <v-col cols="6" md="3">
        <v-text-field v-model="option.inputValue" :label="option.inputText" @input="$emit('input-text', option)" />
      </v-col>
    </v-row>
    <v-row class="d-flex pb-5">
      <v-col v-if="option.switch && option.select" cols="6" md="3">
        <v-select
          v-model="option.selectValue"
          :menu-props="{ offsetY: true }"
          :label="option.selectText"
          :items="horseTypes"
          hide-details
          clearable
          @input="$emit('select-horse', option)"
        />
      </v-col>
      <v-col v-if="option.switch && option.specification && option.selectValue" cols="6" md="3">
        <v-switch
          v-model="option.specificationSwitch"
          :label="generateLabel(option.selectValue)"
          hide-details
          class="pt-0"
          inset
          @change="$emit('select-specification', option)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    required: {
      type: Array,
      default: undefined,
    },
    option: {
      type: Object,
      required: true,
    },
    horseTypes: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    rules() {
      return [
        (v) => {
          const req = this.required.includes(this.option.value.toLowerCase());
          return this.required && req ? !!v || 'dit veld is verplicht voor het geselecteerde rapport' : true;
        },
      ];
    },
  },
  methods: {
    generateLabel(selectValue) {
      switch (selectValue) {
        case 'Merrie':
          return 'Enkel draagmoeders';
        default:
          return 'Enkel dekhengsten';
      }
    },
  },
};
</script>

<style scoped></style>
