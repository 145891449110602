<template>
  <v-dialog v-model="verificationDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <p>
          {{ $t('verificationdialog.confirm1') }} {{ item }} {{ $t('verificationdialog.confirm2') }} {{ action
          }}{{ $t('verificationdialog.confirm3') }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close', false)">
          {{ $t('verificationdialog.cancel') }}
        </v-btn>
        <v-btn color="error" text @click="$emit('submit', queue)">
          {{ capitalize(action) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { capitalize } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  name: 'VerificationDialog',
  props: {
    verificationDialog: {
      type: Boolean,
      required: true,
    },
    queue: Array,
    item: {
      type: String,
      default: i18n.t('verificationdialog.default1'),
    },
    title: {
      type: String,
      default: i18n.t('verificationdialog.default2'),
    },
    action: {
      type: String,
      default: i18n.t('verificationdialog.default3'),
    },
  },
  methods: {
    capitalize,
  },
};
</script>
