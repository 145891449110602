<template>
  <v-layout column fill-height>
    <v-card class="mx-5 mt-5 no-border-print" elevation="0">
      <v-card-title class="print-only">
        {{ title }}
      </v-card-title>
      <v-container key="company">
        <span><strong>{{ $t('generalinfo.company') }}</strong></span>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.name') }}
          </v-col>
          <v-col>{{ generalInfo.company.name }}</v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.nr') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.company.company_number }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.company.company_number" placeholder="company number" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.address') }}
          </v-col>
          <v-col>{{ generalInfo.company.address }}</v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.zip') }}
          </v-col>
          <v-col>{{ generalInfo.company.postalCode }} {{ generalInfo.company.city }}</v-col>
        </v-row>
      </v-container>
      <v-container key="contact">
        <span><strong>{{ $t('generalinfo.contact') }}</strong></span>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.fullname') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.contact.representative_name }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.contact.representative_name" dense />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.tel') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.contact.home }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.contact.home" dense />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.phone') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.contact.phone }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.contact.phone" dense />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.fax') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.contact.fax }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.contact.fax" dense />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.mail') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.contact.email }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.contact.email" dense />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.site') }}
          </v-col>
          <v-col class="print-only">
            {{ generalInfo.contact.website }}
          </v-col>
          <v-col :md="3" class="no-print">
            <v-text-field v-model="generalInfo.contact.website" dense />
          </v-col>
        </v-row>
      </v-container>
      <v-container key="signature">
        <span><strong>{{ $t('generalinfo.fill') }}</strong></span>
        <v-row dense>
          <p class="font-weight-bold">
            {{ $t('generalinfo.accept') }}
          </p>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.date') }}
          </v-col>
          <v-col>{{ formatDate(today()) }}</v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.sign') }}
          </v-col>
          <v-col dense>
            <signature-display v-if="doctor" class="ma-5" :doctor="doctor" dense />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col :md="3">
            {{ $t('generalinfo.fullname2') }}
          </v-col>
          <v-col dense>
            <p>{{ doctor }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card class="ma-5 no-print" outlined>
      <v-card-title>
        {{ $t('generalinfo.selection') }}
      </v-card-title>

      <v-row class="mx-1 mb-2">
        <v-col :md="5">
          <v-select v-model="doctor" :items="doctors" :label="$t('generalinfo.select')" :menu-props="{ offsetY: true }"
            hide-details outlined dense />
        </v-col>
      </v-row>
    </v-card>
    <v-row dense justify="end" class="no-print">
      <v-btn style="width: fit-content" color="success" class="mr-5 mb-5 no-print" depressed @click="print">
        {{ $t('generalinfo.print') }}
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay';
import { formatDate, print, today } from '@/Helpers';

export default {
  components: { SignatureDisplay },
  data: () => ({
    generalInfo: {
      company: {
        name: '',
        company_number: 1,
        address: '',
        postalCode: '',
        city: '',
      },
      contact: {
        representative_name: '',
        home: '',
        phone: '',
        fax: '/',
        email: '',
        website: '',
      },
    },
    doctor: null,
    doctors: [],
    title: `Activiteitenverslag ${today().getFullYear()} over het winnen,produceren, behandelen of opslaan van sperma,eicellen en embryo’s`,
  }),
  methods: {
    formatDate,
    print,
    today,
  },
};
</script>
