<template>
  <v-layout column fill-height justify-space-between>
    <products-table :title="$t('prodindex.title')" :headers="headers" :filters="filters" />
    <v-btn style="width: fit-content; align-self: flex-end" color="primary" class="mr-5 mb-5" depressed @click="() => openProductPage()">
      {{ $t('prodindex.add') }}
      <v-icon right> mdi-plus </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import productsTable from '@/components/products/Table';
import i18n from '@/translations/i18n';
import { configAPI } from '@/services';

export default {
  components: { productsTable },
  data() {
    return {
      types: [],
      headers: [
        { text: i18n.t('prodindex.name'), value: 'name', sortable: true, selected: true },
        { text: i18n.t('prodindex.type'), value: 'type', sortable: true, selected: true },
        { text: i18n.t('prodindex.cnk'), value: 'CNK', sortable: true, selected: true },
        { text: i18n.t('prodindex.dose'), value: 'dosage', sortable: false, selected: true },
        { text: i18n.t('prodindex.unit'), value: 'outgoingUnit', sortable: false, selected: true },
        { text: i18n.t('prodindex.wait'), value: 'waitingTime', sortable: false, selected: true },
        { text: i18n.t('prodindex.tax'), value: 'tax', align: 'left', sortable: false, selected: true },
        { text: i18n.t('prodindex.sup'), value: 'supplementAdministration', align: 'end', sortable: false, selected: true },
        { text: i18n.t('prodindex.edit'), value: 'action', align: 'end', sortable: false, selected: true },
      ],
    };
  },
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: i18n.t('prodindex.filter'),
          items: this.types,
          options: { multiple: true },
        },
      };
    },
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    openProductPage(id) {
      this.$router.push({ path: `/settings/product/${id}` });
    },
    async getConfig() {
      try {
        const {
          data: { types },
        } = await configAPI.getProductConfig();
        this.types = types;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
