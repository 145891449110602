<template>
  <div>
    <v-card flat outlined>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $t('prodtable.title') }}</v-toolbar-title>
        <v-spacer />
        <v-btn v-if="sheet && sheet.billable" color="primary" icon @click="openCreateDialog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="products && products.length" />
      <v-data-table
        v-if="products && products.length"
        class="ma-5"
        :headers="headers"
        :items="products"
        item-key="_id"
        single-expand
        :items-per-page="5"
        hide-default-header
        :hide-default-footer="!showFooter"
      >
        <template #no-data> {{ $t('prodtable.nodata1') }} {{ title }} {{ $t('prodtable.nodata2') }} </template>
        <template #[`item.amount`]="props">
          <v-edit-dialog v-if="sheet && !sheet.billable" :return-value.sync="props.item.amount" large>
            {{ productFormatAmount(props.item) }}
            <template #input>
              <v-text-field
                v-model="props.item.amount"
                type="number"
                :label="$t('prodtable.edit')"
                single-line
                autofocus
                counter
                :disabled="!sheet || !sheet.billable"
              />
            </template>
          </v-edit-dialog>
          <div v-else>
            {{ productFormatAmount(props.item) }}
          </div>
        </template>
        <template #[`item.date`]="props">
          <span class="grey--text">{{ formatDateFull(props.item.date) }}</span>
        </template>
        <template v-if="sheet && sheet.billable" #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon dark color="primary" @click="() => openDeleteDialog(item)" v-on="on"> mdi-delete </v-icon>
            </template>
            <span>{{ $t('prodtable.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="createDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>{{ $t('prodtable.title2') }}</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" :disabled="sheet && !sheet.billable">
            <v-row dense class="mb-5">
              <v-col>
                <search-product
                  :dense="true"
                  :product="editQueue.product"
                  :autofocus="true"
                  @emit-product="(searchResult) => editQueue.push({ product: searchResult, amount: 1 })"
                />
              </v-col>
            </v-row>
            <v-row v-if="!editQueue.length">
              <v-col dense cols="12">
                <p class="text-center">
                  {{ $t('prodtable.none') }}
                </p>
              </v-col>
            </v-row>
            <template v-if="editQueue">
              <v-row v-for="(product, i) in editQueue" :key="i" dense class="d-flex justify-end">
                <v-col cols="1">
                  <v-btn color="error" icon class="mt-4" @click="removeProductFromQueue(i)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="7" md="6">
                  <v-text-field v-model="product.product.name" hide-details disabled />
                </v-col>
                <v-col cols="2" md="1">
                  <v-text-field v-model="product.amount" class="centered-input" type="number" hide-details />
                </v-col>
                <v-col cols="2">
                  <v-text-field v-model="product.product.outgoingUnit" class="centered-input" hide-details readonly />
                </v-col>
                <v-col cols="11" md="2" class="mt-1">
                  <v-switch v-model="product.sell" inset :label="$t('prodtable.sell')" />
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeCreateDialog">
            {{ $t('prodtable.cancel') }}
          </v-btn>
          <v-btn color="success" text @click="addProduct(editQueue)">
            {{ $t('prodtable.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Product"
      :delete-queue="deleteQueue"
      @delete="removeProduct"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import deleteDialog from '@/components/common/DeleteDialog';
import searchProduct from '@/components/search/SearchProduct';
import { productFormatAmount, formatDateFull } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  components: { searchProduct, deleteDialog },
  props: {
    sheet: { type: Object, default: undefined },
    title: { type: String, default: 'Medicatie' },
    data: { type: Array, required: true },
    showFooter: { type: Boolean },
    showDate: { type: Boolean },
  },
  data: () => ({
    createDialog: false,
    products: [],
    editQueue: [],
    deleteDialog: false,
    deleteQueue: {},
    valid: false,
  }),
  computed: {
    headers() {
      if (this.showDate) {
        return [
          { text: i18n.t('prodtable.amount'), value: 'amount', align: 'end' },
          { text: i18n.t('prodtable.name'), value: 'product.name' },
          { text: i18n.t('prodtable.dose'), value: 'date', align: 'end' },
        ];
      }
      return [
        { text: i18n.t('prodtable.amount'), value: 'amount', align: 'end' },
        { text: i18n.t('prodtable.name'), value: 'product.name' },
        { text: i18n.t('prodtable.edit2'), value: 'action', align: 'end' },
      ];
    },
  },
  watch: {
    data() {
      if (this.sheet) {
        this.products = this.sheet.products;
        return;
      }
      this.products = this.data;
    },
  },
  mounted() {
    if (this.sheet) {
      this.products = this.sheet.products;
    } else {
      this.products = this.data;
    }
  },
  methods: {
    formatDateFull,
    productFormatAmount,
    removeProductFromQueue(item) {
      this.editQueue.splice(item, 1);
    },
    addProduct(items) {
      items.forEach((product) => this.sheet.products.push(product));
      this.editQueue = [];
      this.createDialog = false;
    },
    removeProduct(item) {
      const index = this.sheet.products.findIndex((prod) => prod.product._id === item.product._id);
      this.sheet.products.splice(index, 1);
      this.closeDeleteDialog(false);
    },
    openCreateDialog(item) {
      if (item.product) this.editQueue = item;
      this.createDialog = true;
    },
    closeCreateDialog() {
      this.editQueue = [];
      this.createDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
