export const getMostRecentProtocolDate = (kees) =>
  kees.researchSheets.reduce((rv, curr) => {
    const check = curr.protocols.some(
      (prot) =>
        prot.protocol.name === 'VERS ZAAD - Diepe inseminatie met sperma-analyse HamiltonThorne' ||
        prot.protocol.name === 'DIEPVRIES ZAAD - Diepe inseminatie met sperma-analyse HamiltonThorne'
    );
    const researchDate = new Date(curr.researchDate);
    return check && rv < researchDate ? researchDate : rv;
  }, new Date(0));
