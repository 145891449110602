export const horses = {
  noneFound: 'Geen paarden gevonden',
  nav: {
    stallion: 'Hengsten',
    stud: 'Dekhengsten',
    mare: 'Merries',
    surrogate: 'Draagmoeders',
  },
  horseDetail: {
    form: {
      general: {
        title: 'Algemeen',
        name: 'Naam paard*',
        gender: 'Geslacht*',
        dateOfBirth: 'Geboortedatum',
        owner: 'Eigenaar zoeken',
        ueln: 'UELN',
        chip: 'Microchip',
        stallionOwner: 'Hengsthouder zoeken',
        fee: 'Dekgeld',
        deceasedDate: 'Overlijdensdatum',
        surrogateUid: 'Draagmoeder brandnummer',
      },
      location: {
        title: 'Locatie',
        stable: 'Stal',
        prefix: 'Prefix',
        location: 'Locatie',
        billableLodging: 'Is dit verblijf factureerbaar?',
        tooltip: 'Bij het aanmaken van een nieuw paard met locatie zal er automatisch ook een lopend verblijf aangemaakt worden',
      },
      features: {
        title: 'Kenmerken',
        breed: 'Ras*',
        studbook: 'Stamboek*',
        color: 'Vachtkleur',
        father: 'Vader',
        mother: 'Moeder',
        grandFather: 'Grootvader',
      },
      extra: {
        title: 'Extra',
        stud: 'Dekhengst',
        wffsFree: 'WFFS vrij',
        surrogate: 'surrogate',
        foodChain: 'Houden voor voedselketen',
        deceased: 'Gestorven',
      },
      confirmation: {
        title: 'Paard verwijderen?',
        description: 'Ben je zeker dat je het paard {horse} wilt verwijderen? Dit kan niet meer ongedaan gemaakt worden.',
        success: 'Paard is succesvol opgeslagen',
      },
      actions: {
        downloadFiche: 'Onderzoeksfiche downloaden',
      },
    },
  },
};
