export const cases = {
  add: 'Case toevoegen',
  title: 'Cases',
  table: {
    headers: {
      ref: 'Ref',
      horse: 'Paard',
      client: 'Klant',
      extra: 'Extra',
      activity: 'Hoofdactiviteit',
      amount: 'Aantal',
      status: 'status',
      updatedOn: 'Gewijzigd',
      createdAt: 'Gemaakt',
    },
  },
  search: 'Zoeken op referentie of paardnaam',
  filters: {
    status: 'Filter op status',
    activity: 'Filter op activiteit',
    hideSurrogates: 'Verberg draagmoeders',
    onlySurrogates: 'Enkel draagmoeders',
  },
  dialog: {
    title: 'Case aanmaken',
    labels: {
      activity: 'Hoofdactiviteit',
    },
  },
  invoices: {
    overview: {
      title: 'Facturatieoverzicht',
      customer: 'Klant',
      handledDates: 'Behandelingsdata',
      mainActivity: 'Hoofdactiviteit',
      billHousing: 'Stockage kosten aanrekenen',
      invoice: 'Factureren',
      flyoutTable: {
        headers: {
          description: 'Beschrijving',
          amount: 'Aantal',
          pricePerUnit: 'Eenheidsprijs',
          vat: 'BTW %',
          totalNoVAT: 'Totaal (eBTW)',
        },
        totals: {
          totalNoVAT: 'Totaal eBTW',
          vatTotal: 'BTW bedrag',
          total: 'Totaal'
        },
        groups: {
          products: 'Producten',
          protocols: 'Protocollen',
          labos: "Labo's",
          pension: 'Verblijven',
          "embryo storage": 'Embryo stockage',
          "semen storage": 'Sperma stockage'
        }
      },

    },
  },
};
