export const common = {
  loading: 'Bezig met laden...',
  noData: 'Geen data beschikbaar',
  noneFound: 'Geen {item} gevonden.',
  noneAdded: 'Geen {item} toegevoegd.',
  unknown: 'Onbekend',
  horse: 'Paard',
  horseTypes: {
    stallion: 'Hengst',
    stud: 'Dekhengst',
    mare: 'Merrie',
    surrogate: 'Draagmoeder',
  },
  actions: {
    filters: 'Filters',
    columns: 'Kolommen',
    close: 'Sluiten',
    back: 'Terug',
    save: 'Opslaan',
    edit: 'Bijwerken',
    editItem: '{item} bijwerken',
    delete: 'Verwijderen',
    deleteItem: '{item} verwijderen',
    cancel: 'Annuleren',
    search: 'Zoeken',
    print: 'Print',
    create: 'Aanmaken',
    createItem: '{item} aanmaken',
    checkIn: 'Check-in',
    checkOut: 'Check-out',
    add: 'Toevoegen',
    addItem: '{item} toevoegen',
  },
  container: 'Container',
  tube: 'Koker',
  expireDate: 'Vervaldatum',
  search: {
    stud: 'Dekhengst zoeken',
    stallion: 'Hengst zoeken',
    surrogate: 'Draagmoeder zoeken',
    mare: 'Merrie zoeken',
    horse: 'Paard zoeken',
    customer: 'Klant zoeken',
  },
  nav: {
    logout: 'Logout',
    client: 'Klanten',
    horse: {
      main: 'Paarden',
      stallion: 'Hengsten',
      mare: 'Merries',
      surrogate: 'Draagmoeders',
      stud: 'Dekhengsten',
    },
    medication: 'Medicatie',
    fertility: {
      main: 'Fertiliteit',
      icsi: 'ICSI',
      semen: 'Sperma',
    },
    case: 'Cases',
    reports: {
      main: 'Rapporten',
      locationOverview: 'Overzicht vrije locaties',
      surrogateByLocation: 'Draagmoeders per locatie',
      deceased: 'Overleden paarden',
      semenStock: 'Stock sperma',
      deliveredProducts: 'Geleverde producten',
      outgoingProducts: 'Uitgaande producten',
      appliedMedication: 'Toegediende medicatie',
      yearlyReports: 'Jaarlijks rapport',
      presenceRegister: 'Register aanwezige dieren',
      deckedMares: 'Lijst gedekte merries',
      stallionOwnerTransactions: 'Transacties per hengsthouder',
    },
    config: {
      main: 'Beheer',
      locations: 'Locaties',
      nitrogenContainers: 'Stikstof vaten',
      downloadResearchSheet: 'Onderzoeksfiches downloaden',
      products: 'Producten',
      protocols: 'Protocollen',
      labs: 'Labo-onderzoeken',
      pensionTypes: 'Pension types',
      upload: 'Upload',
      tenant: 'Tenant',
    },
  },
};
