<template>
  <v-layout column fill-height>
    <v-row class="mt-0 mx-0 mb-2">
      <v-col cols="12" class="information d-flex align-center">
        <span class="ml-2">{{ $t('transactionconfig') }}</span>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col v-if="transactConfig.length" cols="12">
        <transaction-config-selector
          v-for="(item, index) of transactConfig"
          :key="index"
          :transaction="item"
          :all-transaction-actions="allTransactionActions"
          :transaction-actions-per-type="transactionActionsPerType"
          :all-transaction-types="allTransactionTypes"
          :delete-transaction="transactConfig.length !== 1 ? deleteTransactionFunction(index) : undefined"
          @update-transaction="updateTransaction(index, $event)"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0 mb-4">
      <v-col cols="6" md="2">
        <v-btn depressed color="primary" @click="addTransaction">
          <v-icon center> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import TransactionConfigSelector from '@/components/extraConfig/TransactionConfigSelector';
import { configAPI } from '@/services';

export default {
  components: { TransactionConfigSelector },
  props: {
    transactionConfig: { type: Array, default: undefined },
  },
  data: () => ({
    transactConfig: [],
    allTransactionTypes: [],
    allTransactionActions: [],
    transactionActionsPerType: {},
  }),
  mounted() {
    this.getTransactionTypes();
    this.getTransactionActions();
    if (this.transactionConfig && this.transactionConfig.length) {
      this.transactConfig = [...this.transactionConfig];
    } else {
      this.addTransaction();
    }
  },
  methods: {
    async getTransactionTypes() {
      try {
        const {
          data: { transactionTypes },
        } = await configAPI.getTransactionTypes();
        this.allTransactionTypes = transactionTypes;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
    async getTransactionActions() {
      try {
        const {
          data: { transactionActions, transactionActionPerType },
        } = await configAPI.getTransactionActions();
        this.allTransactionActions = transactionActions;
        this.transactionActionsPerType = transactionActionPerType;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
    addTransaction() {
      this.transactConfig.push({ transactionType: '', transactionAction: '' });
    },
    updateTransaction(index, transaction) {
      this.transactConfig.splice(index, 1, transaction);
      this.updateTransactions();
    },
    deleteTransactionFunction(index) {
      return () => this.deleteTransaction(index);
    },
    deleteTransaction(index) {
      this.transactConfig.splice(index, 1);
      this.updateTransactions();
    },
    updateTransactions() {
      // Do not include empty items
      this.$emit(
        'transaction-config',
        this.transactConfig.filter((config) => config.transactionType || config.transactionAction)
      );
    },
  },
};
</script>

<style scoped>
.information {
  background-color: #f6f6f6;
  border-left: solid 4px rgba(0, 0, 0, 0.12);
}
</style>
