<template>
  <div>
    <v-card flat outlined>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn v-if="sheet && sheet.billable" color="primary" icon @click="openLaboDialog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider v-if="data && data.length" />
      <v-data-table
        v-if="data && data.length"
        class="ma-5 small-first"
        :headers="tableHeaders"
        :items="data"
        item-key="_id"
        :items-per-page="5"
        hide-default-header
        :hide-default-footer="!showFooter && data.length < 6"
        :expanded.sync="expanded"
        show-expand
        single-expand
      >
        <template #no-data> {{ $t('searchlabotable.nodata1') }} {{ title }} {{ $t('searchlabotable.nodata2') }} </template>
        <template #[`item.date`]="props">
          <span class="grey--text">{{ formatDateFull(props.item.date) }}</span>
        </template>
        <template v-if="sheet && sheet.billable" #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon dark color="primary" @click="() => openDeleteDialog(item)" v-on="on"> mdi-delete </v-icon>
            </template>
            <span>{{ $t('searchlabotable.delete') }}</span>
          </v-tooltip>
        </template>
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0 subitem">
            <mini-products-table
              v-if="item.labo.products.length || !item.labo.parameters.length"
              class="border-top"
              :protocol="item.labo"
              :disabled="!sheet || !sheet.billable"
            />
            <mini-parameters-table
              v-if="item.labo.parameters.length"
              class="border-top"
              :protocol="item.labo"
              :disabled="!sheet || !sheet.billable"
            />
            <template v-if="caseId && item.labo.reportType.length">
              <v-btn
                v-for="(report, idx) in item.labo.reportType"
                :key="idx"
                color="accent"
                class="primary--text"
                style="width: 100%; border-radius: unset"
                depressed
                @click="openReport(item, report)"
              >
                {{ report.name }}
              </v-btn>
            </template>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="laboDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>{{ `${title} ${editQueue ? 'aanpassen' : 'toevoegen'}` }}</v-card-title>
        <v-card-text>
          <v-form ref="form" :disabled="!sheet || !sheet.billable">
            <v-row dense class="mb-2">
              <v-col cols="12">
                <search-labo
                  :label="$t('searchlabotable.select')"
                  :labo="editQueue.labo"
                  :clearable="true"
                  :autofocus="true"
                  :outlined="true"
                  :dense="true"
                  @emit-labo="selectLabo"
                />
              </v-col>
            </v-row>
            <v-row v-if="!editQueue.labo">
              <v-col dense cols="12">
                <p class="text-center">
                  {{ $t('searchlabotable.none') }}
                </p>
              </v-col>
            </v-row>
            <template v-if="editQueue.labo">
              <mini-products-table :disabled="!sheet || !sheet.billable" :protocol="editQueue.labo" />
              <parameter-form v-if="editQueue.labo.parameters.length" :protocol="editQueue.labo" @emit-select="valid = $event" />
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeLaboDialog">
            {{ $t('searchlabotable.cancel') }}
          </v-btn>
          <v-btn color="success" :disabled="!valid" text @click="addLabo(editQueue)">
            {{ $t('searchlabotable.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Labo-onderzoek"
      :delete-queue="deleteQueue"
      @delete="removeLabo"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import searchLabo from '@/components/search/SearchLabo';
import deleteDialog from '@/components/common/DeleteDialog';
import { formatDateFull, reportQueryFormatter } from '@/Helpers';
import miniProductsTable from './miniTables/MiniProductsTable';
import miniParametersTable from './miniTables/MiniParametersTable';
import parameterForm from './ParameterForm';
import i18n from '@/translations/i18n';

export default {
  components: { searchLabo, deleteDialog, miniProductsTable, miniParametersTable, parameterForm },
  props: {
    sheet: { type: [Object || undefined] },
    title: { type: String, default: '' },
    data: { type: Array, required: true },
    caseId: { type: String, default: undefined },
    showFooter: { type: Boolean },
    showDate: { type: Boolean },
  },
  data: () => ({
    productHeaders: [
      { text: i18n.t('searchlabotable.prodname'), value: 'product.name', divider: true, width: '100px' },
      { text: i18n.t('searchlabotable.amount'), value: 'amount', align: 'center' },
    ],
    laboDialog: false,
    editQueue: {},
    deleteDialog: false,
    deleteQueue: {},
    valid: false,
    expanded: [],
  }),
  computed: {
    tableHeaders() {
      if (this.showDate) {
        return [
          { text: i18n.t('searchlabotable.labname'), value: 'labo.name' },
          { text: i18n.t('searchlabotable.date'), value: 'date', align: 'end' },
        ];
      }
      return [
        { text: i18n.t('searchlabotable.labname'), value: 'labo.name' },
        { text: '', value: 'action', align: 'end', width: '110px' },
      ];
    },
    parameters() {
      return this.editQueue.labo.parameters.map((param) => param.elementModel);
    },
  },
  methods: {
    formatDateFull,
    reportQueryFormatter,
    openReport: (item, report) => {
      const qs = reportQueryFormatter(item);
      this.$router.push(`/report/${report.url}?caseId=${this.caseId}&date=${item.date}&title=${report.name}${qs}`);
    },
    selectLabo(labo) {
      if (labo) {
        this.valid = !labo.parameters.length;
      }
      this.editQueue = { labo };
    },
    addLabo(item) {
      this.sheet.labos.push(item);
      this.editQueue = {};
      this.laboDialog = false;
    },
    removeLabo(item) {
      const index = this.sheet.labos.findIndex((labo) => labo.labo === item.labo);
      this.sheet.labos.splice(index, 1);
      this.closeDeleteDialog(false);
    },
    openLaboDialog(item) {
      if (item.labo) this.editQueue = item;
      this.laboDialog = true;
    },
    closeLaboDialog() {
      this.editQueue = {};
      this.laboDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
  },
};
</script>

<style scoped>
.subitem .v-data-table {
  border-radius: unset !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
