
import Vue from 'vue';
import { ownerName, semenDescription, strawColor } from '@/Helpers';
import semenAPI from '@/services/SemenAPI';
import SearchHorse from '@/components/search/SearchHorse.vue';

export default Vue.extend({
  name: 'SelectSemenForm',
  components: { SearchHorse },
  props: {
    parameter: { type: Object, default: undefined },
    checkType: { type: Function, default: () => {} },
  },
  data: () => ({
    selected: undefined,
    selectedDonor: { _id: '' },
    semen: [],
    elementLoading: false,
  }),
  computed: {
    requiredAmount() {
      if (this.selected) {
        return [
          (v) =>
            (v > 0 && v <= this.selected.current_inventory) ||
            `Dit veld is verplicht, moet groter zijn dan 0 en kleiner dan ${this.selected.current_inventory}`,
        ];
      }
      return [true];
    },
  },
  watch: {
    selectedDonor() {
      this.getAllSemen(this.selectedDonor._id);
    },
  },
  methods: {
    ownerName,
    semenDescription,
    strawColor,
    select(element) {
      this.selected = element;
      this.$emit('select-param', element);
    },
    async getAllSemen(stallion) {
      try {
        this.elementLoading = true;
        const {
          data: { results },
        } = await semenAPI.getAllSemenCollection({ stallion, hideEmpty: true });
        this.semen = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.elementLoading = false;
      }
    },
  },
});
