import { get, post, put, deleteR } from '@/services/API';

export default {
  post: (obj) => post('/api/notification', obj),
  getAll: (params) => get(`/api/notification?${new URLSearchParams(params)}`),
  get: (id) => get(`/api/notification/${id}`),
  put: (obj) => put(`/api/notification/${obj._id}`, obj),
  delete: (id) => deleteR(`/api/notification/${id}`),
  filter: (params) => get(`/api/notification/filter?${new URLSearchParams(params)}`),
};
