
import Vue from 'vue';
import horseAPI from '@/services/HorseAPI';
import CoveredMaresTable from '@/components/covered-mares/CoveredMaresTable.vue';

export default Vue.extend({
  name: 'CoveredMaresList',
  components: { CoveredMaresTable },
  data() {
    return {
      horses: [],
      years: [],
      selectedYear: parseInt(this.$route.query.year as string, 10) || 0,
      loading: false,
    };
  },
  watch: {
    selectedYear: {
      handler() {
        if (this.selectedYear) {
          this.getCoveredMaresPerStallion(this.selectedYear);
          this.$router.replace({ query: { year: this.selectedYear.toString() } });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    this.getYears();
  },
  methods: {
    async getYears() {
      this.loading = true;
      try {
        const { data } = await horseAPI.getYears();
        this.years = data;
        if (!this.selectedYear && data.length) {
          const [first] = data;
          this.selectedYear = first;
        }
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async getCoveredMaresPerStallion(year: number) {
      this.loading = true;
      try {
        const { data } = await horseAPI.getCoveredMares(year);
        this.horses = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
});
