import { Tenant } from '@/models/tenant';

type TenantInfoState = {
  activeTenant: Tenant | undefined;
};

export const tenantInfo = {
  namespaced: true,
  state: (): TenantInfoState => ({
    activeTenant: undefined,
  }),
  mutations: {
    setActiveTenant(state: TenantInfoState, activeTenant: Tenant) {
      state.activeTenant = activeTenant;
    },
  },
  actions: {
    setActiveTenant({ commit }, { tenant }) {
      commit('setActiveTenant', tenant);
    },
  },
  getters: {
    getActiveTenantId(state: TenantInfoState) {
      return state.activeTenant?._id;
    },
    getActiveTenant(state: TenantInfoState) {
      return state.activeTenant;
    },
  },
};
