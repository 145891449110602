<template>
  <v-layout fill-height column>
    <div :class="[isPrint ? 'print-only' : 'ma-5']">
      <v-data-table
        :items="embryoDataList"
        :headers="subheaders"
        :options.sync="options"
        :loading="icsiEmbryoByBreed.loading || rinsedEmbryoByBreed.loading"
        :hide-default-footer="hideFooter"
        :disable-pagination="disablePagination"
        hide-default-header
      >
        <template #no-data> Geen data beschikbaar </template>
        <template #header>
          <thead>
            <tr>
              <th v-for="head in headers" :key="head.value" :colspan="head.colspan">
                {{ head.text }}
              </th>
            </tr>
            <tr>
              <th v-for="subheader in subheaders" :key="subheader.value">
                {{ subheader.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template #item="props">
          <tr>
            <td v-for="subheader of subheaders" :key="subheader.value">
              <template v-if="subheader.value === 'breed'">
                {{ props.item[subheader.value] || 'Onbekend' }}
              </template>
              <template v-else>
                {{ props.item[subheader.value] }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-layout>
</template>

<script>
import { generateCsvData } from '@/Helpers/yearly-report.helper';

export default {
  props: {
    isPrint: { type: Boolean, default: true },
    disablePagination: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: true },
  },
  data: (data) => ({
    options: {
      page: 1,
      itemsPerPage: data.disablePagination ? -1 : 10,
    },
    icsiEmbryoByBreed: data.$store.state.yearlyReport.icsiEmbryoByBreed,
    rinsedEmbryoByBreed: data.$store.state.yearlyReport.rinsedEmbryoByBreed,
    headers: [
      { value: '', text: '', colspan: 1 },
      { value: 'mares', text: 'Merries', colspan: 2 },
      { value: 'amount', text: "Aantal embryo's", colspan: 4 },
      { value: 'pregnancy', text: 'Drachtigheid', colspan: 1 },
    ],
    subheaders: [
      { value: 'transplantType', text: 'In vitro of in vivo' },
      { value: 'breed', text: 'Ras' },
      { value: 'horseCount', text: 'Aantal donormerries' },
      { value: 'gainedEmbryo', text: 'Gewonnen (in vivo) of zelf geproduceerd (in vitro)' },
      { value: 'frozenEmbryo', text: 'Ingevroren op het centrum' },
      { value: 'sentEmbryo', text: 'Verzonden' },
      { value: 'plantedEmbryo', text: 'Ingeplant op het centrum' },
      {
        value: 'minimalPregnancy',
        text: 'Aantal drachtige draagmerries op het einde van het seizoen',
      },
    ],
  }),
  computed: {
    year() {
      return this.$store.state.yearlyReport.year;
    },
    embryoDataList() {
      if (this.icsiEmbryoByBreed.data.length && this.rinsedEmbryoByBreed.data.length) {
        return [...this.rinsedEmbryoByBreed.data, ...this.icsiEmbryoByBreed.data];
      }
      return [];
    },
  },
  watch: {
    year: {
      immediate: true,
      handler(newYear) {
        if (newYear) {
          this.fetchEmbryoTransplantationData();
        }
      },
    },
    embryoDataList: {
      immediate: true,
      handler(newData) {
        const csvData = generateCsvData(this.headers, this.subheaders, newData);
        this.$emit('update-download-data', { dataUri: encodeURI(csvData), name: 'embryotransplantaties.csv' });
      },
    },
  },
  methods: {
    async fetchEmbryoTransplantationData() {
      if (
        !this.icsiEmbryoByBreed.data.length ||
        this.icsiEmbryoByBreed.year !== this.year ||
        !this.rinsedEmbryoByBreed.data.length ||
        this.rinsedEmbryoByBreed.year !== this.year
      ) {
        this.$store.dispatch('yearlyReport/getIcsiEmbryoByBreed');
        this.$store.dispatch('yearlyReport/getRinsedEmbryoByBreed');
      }
    },
  },
};
</script>
