import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Languages
import { dutch } from '@/translations/dutch';
import { english } from '@/translations/english';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'nl',
  messages: {
    nl: dutch,
    en: english,
  },
});
