import { getInstance } from './authWrapper';

// eslint-disable-next-line consistent-return
export const authGuard = (to, from, next) => {
  const authService = getInstance();

  // eslint-disable-next-line consistent-return
  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }
    authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
    });
  };

  if (!authService.loading) {
    return fn();
  }

  // eslint-disable-next-line consistent-return
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
