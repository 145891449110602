<template>
  <div>
    <v-btn color="primary" depressed class="ml-4 d-print-none" @click.stop="columnDialog = true">
      <v-icon left> mdi-cog </v-icon>
      {{ $t('common.actions.columns') }}
    </v-btn>
    <v-dialog v-model="columnDialog" max-width="690">
      <v-card>
        <v-card-text>
          <v-list>
            <v-row dense>
              <v-col v-for="header in headers" :key="header.text" cols="12" sm="6" md="4">
                <v-list-item>
                  <v-checkbox v-model="header.selected" :label="header.text" :value="header.selected" @change="emitFiltered" />
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="columnDialog = false">
            {{ $t('common.actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    headers: { type: Array, required: true },
  },
  data: () => ({
    columnDialog: false,
  }),
  computed: {
    filteredHeaders() {
      return this.headers.filter((header) => header.selected);
    },
  },
  created() {
    this.emitFiltered();
  },
  methods: {
    emitFiltered() {
      this.$emit('emit-headers', this.filteredHeaders);
    },
  },
};
</script>
