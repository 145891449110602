
import Vue from 'vue';
import i18n from '@/translations/i18n';
import { formatDate, horseName, ownerName } from '@/Helpers';
import Tag from '@/components/common/Tag.vue';
import FlyoutTable from './FlyoutTable.vue';

export default Vue.extend({
  props: {
    caseInfo: { type: Object, default: undefined },
    groupedLines: { type: Array, default: () => [] },
    note: { type: String, default: '' },
  },
  components: {
    Tag,
    FlyoutTable,
  },
  data: () => ({
    billHousingCost: false,
  }),
  methods: {
    ownerName,
    formatDate,
    horseName,
    async postInvoice() {
      return null;
    },
  },
});
