<template>
  <v-layout column>
    <upload-form :title="$t('uploadstock.semen')" :uploading="uploading.semen" :success="uploaded" type="Semen" @upload="upload" />
    <upload-form :title="$t('uploadstock.stay')" :uploading="uploading.lodging" :success="uploaded" type="Lodging" @upload="upload" />
    <snackbar :snackbar="snackbar" :color="snackColor" :text="snackText" :top="true" @timedout="snackbar = $event" />
  </v-layout>
</template>

<script>
import SemenAPI from '@/services/SemenAPI';
import Snackbar from '@/components/common/Snackbar';
import UploadForm from '@/components/upload/Form';

export default {
  name: 'UploadSemenStock',
  components: { Snackbar, UploadForm },
  data: () => ({
    uploading: {
      semen: false,
      lodging: false,
    },
    uploaded: false,
    snackbar: false,
    snackText: '',
    snackColor: '',
  }),
  methods: {
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    },
    async upload({ file, type }) {
      this.uploaded = false;
      if (type === 'Semen') {
        this.uploading.semen = true;
      } else {
        this.uploading.lodging = true;
      }
      try {
        await this.$store.dispatch('errorMessage/resetError');
        const formData = new FormData();
        formData.append('file', file);
        if (file.name.endsWith('.csv')) {
          if (type === 'Semen') {
            await SemenAPI.uploadSemenStock(formData);
          } else {
            await SemenAPI.uploadLodging(formData);
          }
          this.uploaded = true;
        } else {
          throw 'Invalid file uploaded, make sure the extension is .csv';
        }
        this.showSnackbar('success', 'Succesvol geupload');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.uploading.semen = false;
        this.uploading.lodging = false;
      }
    },
  },
};
</script>
