export const medication = {
  title: 'Stock overzicht',
  import: 'import medini',
  table: {
    headers: {
      name: 'Productnaam',
      type: 'Type',
      cnk: 'CNK',
      dose: 'Dosering',
      unit: 'Eenheid',
      tax: 'BTW',
      exp: 'Vervaldatum',
      waitTime: 'Wachttijd (dagen)',
      supplement: 'Supplement',
      totalPrice: 'Totale verkoopprijs',
      remainingPrice: 'Resterende aankoopprijs',
      remaining: 'Resterend',
    },
  },
  filters: {
    type: 'Filter op type',
    tax: 'Filter op BTW',
    remaining: 'Filter op resterend',
  },
};
