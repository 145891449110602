<template>
  <v-dialog v-model="newCaseDialog" max-width="490" persistent>
    <v-card>
      <v-card-title>{{ $t('cases.dialog.title') }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <search-customer :outlined="true" :clearable="true" :customer="kees.customer" @emit-customer="addCustomer" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <search-horse :clearable="true" :show-owner="true" :horse="kees.horse" :outlined="true" @emit-horse="addHorse" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="kees.mainActivity"
                :menu-props="{ offsetY: true }"
                :label="$t('cases.dialog.labels.activity')"
                :items="activities"
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="toggleDialog">
          {{ $t('common.actions.cancel') }}
        </v-btn>
        <v-btn color="success" text :disabled="disabled" :loading="loading" @click="createCase">
          {{ $t('common.actions.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchCustomer from '@/components/search/SearchCustomer';
import SearchHorse from '@/components/search/SearchHorse';
import { configAPI } from '@/services';
import CaseAPI from '@/services/CaseAPI';

export default {
  components: { SearchHorse, SearchCustomer },
  props: { newCaseDialog: { type: Boolean } },
  data: () => ({
    disabled: true,
    kees: {},
    activities: [],
    loading: false,
  }),
  mounted() {
    this.getCaseConfig();
  },
  methods: {
    setDisabled() {
      this.disabled = !this.kees.horse;
    },
    addCustomer(customer) {
      this.kees.customer = customer ? customer._id : customer;
      this.setDisabled();
    },
    addHorse(horse) {
      this.kees.horse = horse ? horse._id : horse;
      this.setDisabled();
    },
    toggleDialog() {
      this.$emit('toggle-dialog', !this.newCaseDialog);
      this.$refs.form.reset();
      this.disabled = true;
      this.kees = {};
    },
    openCasePage(id) {
      this.$router.push({ path: `/case/${id}` });
    },
    async getCaseConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { activities },
        } = await configAPI.getCaseStatus();
        this.activities = activities;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async createCase() {
      await this.$store.dispatch('errorMessage/resetError');
      this.loading = true;
      try {
        const { data } = await CaseAPI.post(this.kees);
        this.openCasePage(data._id);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
