<template>
  <v-autocomplete
    v-model="selected"
    :menu-props="{ closeOnContentClick: true }"
    prepend-inner-icon="mdi-magnify"
    :search-input.sync="search"
    :clearable="clearable"
    :items="items"
    hide-no-data
    :hide-details="!showDetails"
    item-text="description"
    item-value="_id"
    :label="$t('common.search.customer')"
    :rules="rule"
    return-object
    :outlined="outlined"
    :disabled="disabled"
    :dense="dense"
    @click:clear="clear"
  >
    <template #item="{ item }">
      <v-list-item @click="emitCustomer(item)">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.description }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.company">
            {{ `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}` }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="showTVA && item.company"> {{ $t('vat') }} {{ item.tva || '-' }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import { customerAPI } from '@/services';
import { ownerName, capitalize } from '@/Helpers';

export default {
  props: {
    customer: { type: [Object, String, undefined] },
    customerProps: { type: [Object, undefined], default: undefined },
    type: { type: String, default: undefined },
    id: { type: String, default: undefined },
    showDetails: { type: Boolean },
    clearable: { type: Boolean },
    required: { type: Boolean },
    outlined: { type: Boolean },
    disabled: { type: Boolean },
    showTVA: { type: Boolean },
    dense: { type: Boolean },
    label: { type: String, default: undefined },
  },
  data: () => ({
    loading: false,
    entries: [],
    search: null,
    selected: null,
  }),
  computed: {
    rule() {
      return [(value) => (this.required ? !!value || 'dit veld is required' : true)];
    },
    items() {
      if (this.selected && this.selected._id && !this.entries.length) {
        return [
          {
            ...this.selected,
            icon: this.selected.type === 'bedrijf' ? 'mdi-factory' : 'mdi-account',
            description: ownerName(this.selected),
          },
        ];
      }
      return this.entries.map((entry) => ({
        ...entry,
        description: ownerName(entry),
        icon: entry.type === 'bedrijf' ? 'mdi-factory' : 'mdi-account',
      }));
    },
  },
  watch: {
    search(val) {
      if (val && val !== this.selected) this.querySelections(val);
    },
    customer() {
      if (this.customer) {
        this.selected = this.customer;
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.entries.length) {
          if (this.customerProps && this.customerProps.first_name && this.customerProps.last_name) {
            this.getCustomers({
              first_name: this.customerProps.first_name,
              last_name: this.customerProps.last_name,
            });
          }
          if (this.customerProps && this.customerProps.company) {
            this.getCustomers({
              company: this.customerProps.company,
            });
          }
          if (this.id && !this.entries.length) {
            this.getCustomerById();
          }
        }
        if (!this.id && !this.customer) {
          this.selected = {};
          this.entries = [];
        }
      },
    },
  },
  mounted() {
    if (this.customer) {
      this.selected = this.customer;
    }
  },
  methods: {
    capitalize,
    ownerName,
    async querySelections(v) {
      this.loading = true;
      try {
        const { data } = await customerAPI.searchCustomer(v, { limit: 10, type: this.type });
        this.entries = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async getCustomerById() {
      this.loading = true;
      try {
        const { data } = await customerAPI.getCustomer(this.id);
        this.selected = data;
        this.$emit('fetched-item', data);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async getCustomers(params) {
      this.loading = true;
      try {
        const {
          data: { customers },
        } = await customerAPI.getCustomers(params);
        if (customers && customers.length) {
          // eslint-disable-next-line prefer-destructuring
          this.selected = customers[0];
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.selected = {};
      this.$emit('emit-customer', undefined);
    },
    emitCustomer(item) {
      this.$emit('emit-customer', item);
      this.selected = item;
    },
  },
};
</script>
