<template>
  <v-card class="ma-5" outlined>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-col cols="4">
        <search-protocol :id="$route.query._id" :dense="true" :outlined="true" :clearable="true" @emit-protocol="updateSearch" />
      </v-col>
      <sync-button type="accounts" :exec="syncAccounts" />
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchProtocols"
        @fetching="loading = $event"
        @fetchedResults="protocols = $event"
        @fetchedTotal="totalProtocols = $event"
      />
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      class="ma-5"
      :headers="filteredHeaders"
      :items="protocols"
      :loading="loading"
      loading-text="Bezig met laden..."
      :server-items-length="totalProtocols"
      :options.sync="options"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template #no-data> {{ $t('protocoltable.nodata1') }} {{ title }} {{ $t('protocoltable.nodata1') }} </template>
      <template #item="props">
        <tr>
          <td v-if="showColumn('name')">
            {{ props.item.name }}
          </td>
          <td v-if="showColumn('en')">
            {{ props.item.translations.en }}
          </td>
          <td v-if="showColumn('fr')">
            {{ props.item.translations.fr }}
          </td>
          <td v-if="showColumn('account')">
            {{ accountName(props.item.account) }}
          </td>
          <td v-if="showColumn('price')">
            {{ props.item.price ? '€ ' + props.item.price.toFixed('2') : '-' }}
          </td>
          <td v-if="showColumn('fixedPrice')">
            <v-icon v-if="props.item.fixedPrice" class="green--text"> mdi-check </v-icon>
          </td>
          <td v-if="showColumn('tax')">
            {{ props.item.tax }}
          </td>
          <td v-if="showColumn('action')" class="text-right d-print-none">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon dark color="primary" class="mr-2" @click="() => openProtocolPage(props.item._id)" v-on="on"> mdi-pencil </v-icon>
              </template>
              <span>{{ $t('protocoltable.edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon dark color="primary" @click="() => openDeleteDialog(props.item)" v-on="on"> mdi-delete </v-icon>
              </template>
              <span>{{ $t('protocoltable.delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Protocol"
      :delete-queue="deleteQueue"
      @delete="deleteItem"
      @close="closeDeleteDialog"
    />
  </v-card>
</template>

<script>
import { accountName, compareRouteAndPush } from '@/Helpers';
import { protocolAPI, teamleaderAPI } from '@/services';
import DeleteDialog from '@/components/common/DeleteDialog';
import SearchProtocol from '@/components/search/SearchProtocol';
import SyncButton from '@/components/common/SyncButton';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';

export default {
  components: { Filters, Columns, DeleteDialog, SearchProtocol, SyncButton },
  props: {
    title: { type: String, default: '' },
    headers: { type: Array, required: true },
    filters: { type: Object, default: () => ({}) },
  },
  data: (data) => ({
    filteredHeaders: [],
    protocols: [],
    totalProtocols: 0,
    loading: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    deleteDialog: false,
    deleteQueue: {},
    sortBy: 'name',
    sortDesc: false,
  }),
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
  },
  methods: {
    accountName,
    compareRouteAndPush,
    openProtocolPage(id) {
      this.$router.push(`/settings/protocol/${id}`);
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    updateSearch(protocol) {
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          _id: protocol ? protocol._id : undefined,
        },
        this.$route,
        this.$router
      );
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
    async syncAccounts() {
      await teamleaderAPI.syncAccounts();
      await this.getAllProtocols();
    },
    async deleteItem(item) {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        await protocolAPI.deleteProtocol(item._id);
        this.deleteDialog = false;
        this.deleteQueue = {};
        await this.getAllProtocols();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    fetchProtocols(URLparams) {
      return protocolAPI.getAllProtocols(URLparams);
    },
    async getAllProtocols() {
      try {
        this.loading = true;
        const {
          data: { results, total },
        } = await protocolAPI.getAllProtocols(this.$route.query);
        this.protocols = results;
        this.totalProtocols = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
