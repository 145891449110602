<template>
  <v-card class="ma-5" outlined>
    <v-toolbar flat dense light>
      <v-toolbar-title>{{ product.name }}</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-tabs v-model="tab">
      <v-tab key="details" class="d-print-none" href="#details">
        <v-icon left> mdi-alpha-d-circle </v-icon>
        {{ $t('stocksbatch.details') }}
      </v-tab>
      <v-tab key="batches" class="d-print-none" href="#batches">
        <v-icon left> mdi-alpha-l-circle </v-icon>
        {{ $t('stocksbatch.lot') }}
      </v-tab>
      <v-tab key="mods" class="d-print-none" href="#mods">
        <v-icon left> mdi-alpha-m-circle </v-icon>
        {{ $t('stocksbatch.mods') }}
      </v-tab>
      <v-tab-item class="mb-5" value="details">
        <product-form
          :product="product"
          :loading="loading"
          :disabled="true"
          :background="true"
          route="/stock"
          @update-product="updateProduct"
        />
      </v-tab-item>
      <v-tab-item class="ma-5" value="batches">
        <batch-table :id="id" :headers="headers" :product="product" />
      </v-tab-item>
      <v-tab-item class="ma-5" value="mods">
        <batch-mods-table :id="id" :product="product" @update-refresh="refresh = false" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import BatchTable from '@/components/stock/BatchTable';
import ProductForm from '@/components/products/Form';
import BatchModsTable from '@/components/stock/BatchModsTable';
import { productsAPI } from '@/services';
import i18n from '@/translations/i18n';

export default {
  components: {
    BatchTable,
    ProductForm,
    BatchModsTable,
  },
  props: {
    id: { type: String, default: '' },
  },
  data: () => ({
    dialog: false,
    disabled: true,
    valid: false,
    product: {},
    totalBatches: 0,
    loading: false,
    editedIndex: -1,
    headers: [
      { text: i18n.t('stocksbatch.nr'), value: 'lotNumber', selected: true },
      { text: i18n.t('stocksbatch.exp'), value: 'expirationDate', selected: true },
      { text: i18n.t('stocksbatch.deliv'), value: 'deliveryDate', selected: true },
      { text: i18n.t('stocksbatch.sup'), value: 'supplier', selected: true },
      { text: i18n.t('stocksbatch.buy'), value: 'buyInPrice', align: 'end', selected: true },
      { text: i18n.t('stocksbatch.sell'), value: 'sellingPrice', align: 'end', selected: true },
      { text: i18n.t('stocksbatch.sellunit'), value: 'sellingPricePerUnit', align: 'end', selected: true },
      { text: i18n.t('stocksbatch.ini'), value: 'initialAmount', align: 'end', selected: false },
      { text: i18n.t('stocksbatch.rest'), value: 'remainingAmount', align: 'end', selected: true },
      { text: i18n.t('stocksbatch.last'), value: 'updatedAt', align: 'end', selected: false },
    ],
    filters: {
      remaining: 'All',
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? i18n.t('stocksbatch.new') : i18n.t('stocksbatch.edit');
    },
    tab: {
      set(tab) {
        if (this.$route.query.tab !== tab) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              tab,
              sortBy: this.setSortBy(tab),
              sortDesc: this.setSortDesc(tab),
            },
          });
        }
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  mounted() {
    this.getProduct(this.id);
  },
  methods: {
    setSortBy(tab) {
      if (tab === 'batches') return 'expirationDate';
      if (tab === 'mods') return 'createdAt';
      return undefined;
    },
    setSortDesc(tab) {
      if (tab === 'batches') return 'false';
      if (tab === 'mods') return 'true';
      return undefined;
    },
    async getProduct(id) {
      this.loading = true;
      try {
        const { data } = await productsAPI.getProduct(id);
        this.product = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    filterBatch(filter) {
      this.options.remaining = filter;
    },
    updateProduct(product) {
      this.product = product;
    },
  },
};
</script>
