<template>
  <v-card flat class="light-grey">
    <v-form ref="form" v-model="valid">
      <v-card outlined class="ma-5">
        <v-toolbar flat dense light>
          <v-toolbar-title>{{ $t('laboform.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <v-text-field v-model="labo.name" :rules="required" :label="$t('laboform.nlname')" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="labo.translations.en" :label="$t('laboform.enname')" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="labo.translations.fr" :label="$t('laboform.frname')" />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <v-select
              v-model="labo.account"
              :items="accounts"
              :item-text="accountName"
              item-value="_id"
              :label="$t('laboform.account')"
              :menu-props="{ offsetY: true }"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select v-model="labo.tax" :rules="required" :items="taxes" :label="$t('laboform.tax')" :menu-props="{ offsetY: true }" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="labo.price"
              :rules="required"
              type="number"
              :label="$t('laboform.price')"
              prefix="€"
              placeholder="0.00"
            />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <v-switch v-model="labo.fixedPrice" inset :label="$t('laboform.pricefix')" />
          </v-col>
          <v-col cols="12" md="4">
            <v-switch v-model="configTab" inset :label="$t('laboform.config')" @change="$emit('change-config', configTab)" />
          </v-col>
        </v-row>
      </v-card>
      <add-product :products="labo.products" />
      <advanced-config
        :notifications="labo.notifications"
        :parameters="labo.parameters"
        :parameter-options="parameterOptions"
        :simple-parameter-options="[]"
        :config="config"
        :report-type="labo.reportType"
        @select-type="labo.reportType = $event"
      />
    </v-form>
    <div class="mr-2 mb-5">
      <v-row justify="end" class="gap-4" dense>
        <Button type="back" :action="() => $router.back()" />

        <v-btn v-if="!labo._id" :disabled="!valid" color="success" depressed @click="createLabo">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('laboform.save') }}
        </v-btn>
        <v-btn v-if="labo._id" :disabled="!valid" color="success" depressed @click="editLabo">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('laboform.edit') }}
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { accountName, required } from '@/Helpers';
import { laboAPI, configAPI, accountAPI } from '@/services';
import AddProduct from '@/components/common/AddProduct';
import AdvancedConfig from '@/components/extraConfig/AdvancedConfig';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, AddProduct, AdvancedConfig },
  props: {
    labo: { type: Object, required: true },
    parameterOptions: { type: Array, required: true },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    config: { type: Boolean },
  },
  data: () => ({
    valid: false,
    taxes: [],
    products: [],
    accounts: [],
    configTab: false,
  }),
  computed: { required },
  watch: {
    config: {
      handler() {
        this.configTab = this.config;
      },
    },
  },
  created() {
    Promise.all([this.getProductConfig(), this.getAccounts()]);
  },
  methods: {
    accountName,
    async laboHandler(data) {
      this.$emit('update-labo', data);
      await this.$router.back();
    },
    async createLabo() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await laboAPI.postLabo(this.labo);
        await this.laboHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async editLabo() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await laboAPI.putLabo(this.labo);
        await this.laboHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getProductConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { tax },
        } = await configAPI.getProductConfig();
        this.taxes = tax;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getAccounts() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { results },
        } = await accountAPI.getAllAccounts({ number: '707102' });
        this.accounts = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
