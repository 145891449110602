import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#c87669',
        secondary: '#6c7e93',
        accent: '#eeeae5',
        background: '#fcf9f5',
      },
    },
  },
});
