<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <v-text-field v-model="batch.lotNumber" :label="$t('semenbatch.lot')" hide-details outlined disabled />
    </v-col>
    <v-col cols="5">
      <date-picker
        :date="startInvoice"
        :label="$t('semenbatch.start')"
        :hide-details="true"
        :close-on-input="true"
        :required="true"
        :outlined="true"
        :min="batch.import_date || batch.production_date"
        :disabled="!!batch.last_invoice_date"
        @select-date="startInvoice = $event"
      />
    </v-col>
    <v-col cols="5">
      <date-picker
        :date="endInvoice"
        :label="$t('semenbatch.end')"
        :hide-details="true"
        :close-on-input="true"
        :required="true"
        :outlined="true"
        :min="batch.last_invoice_date"
        :disabled="!!batch.empty_date"
        @select-date="endInvoice = $event"
      />
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from '@/components/DateSelector/DatePicker';

export default {
  components: { DatePicker },
  props: {
    batch: { type: Object, required: true },
    globalEndInvoice: { type: String, default: null },
  },
  data: () => ({
    startInvoice: null,
    endInvoice: null,
  }),
  watch: {
    globalEndInvoice() {
      if (this.globalEndInvoice && !this.endInvoice) {
        this.endInvoice = this.globalEndInvoice;
      }
    },
    endInvoice() {
      if (this.endInvoice && !this.batch.empty_date) {
        this.batch.endInvoice = this.endInvoice;
        this.$emit('end-date-selected', this.endInvoice);
      }
    },
    startInvoice() {
      if (this.startInvoice) {
        this.batch.startInvoice = this.startInvoice;
      }
    },
  },
  mounted() {
    if (this.batch.empty_date) {
      this.endInvoice = this.batch.empty_date;
    }
    if (this.batch.last_invoice_date) {
      this.startInvoice = this.batch.last_invoice_date;
    } else {
      this.startInvoice = this.batch.import_date ? this.batch.import_date : this.batch.production_date;
    }
  },
};
</script>
