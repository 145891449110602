<template>
  <v-card class="mx-5 mt-5 mb-12" outlined>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchICSI"
        @fetching="loading = $event"
        @fetchedResults="icsis = $event"
        @fetchedTotal="totalICSIs = $event"
      />
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      :headers="filteredHeaders"
      :items="icsis"
      :options.sync="options"
      :server-items-length="totalICSIs"
      :loading="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading-text="$t('common.loading')"
      class="ma-5"
      multi-sort
    >
      <template #no-data>
        {{ $t('noicsidata') }}
      </template>

      <template v-for="header in filteredHeaders" #[`header.${header.value}`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #item="props">
        <tr class="clickable" @click="openICSIPage(props.item._id)">
          <td v-if="showColumn('code')">
            {{ props.item.code }}
          </td>
          <td v-if="showColumn('donor_mare')">
            {{ horseName(props.item.donor_mare) }}
          </td>
          <td v-if="showColumn('donor_stallion')">
            {{ horseName(props.item.donor_stallion) }}
          </td>
          <td v-if="showColumn('amount')">
            {{ props.item.embryos.filter((el) => el.active).length }}
          </td>
          <td v-if="showColumn('createdAt')" class="text-right">
            {{ formatDate(props.item.createdAt) }}
          </td>
          <td v-if="showColumn('updatedAt')" class="text-right">
            {{ formatDate(props.item.updatedAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { formatDate, horseName, compareRouteAndPush } from '@/Helpers';
import Columns from '@/components/filters/Columns';
import Filters from '@/components/filters/Filters';
import { icsiAPI } from '@/services';

export default {
  components: {
    Filters,
    Columns,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: (data) => ({
    totalICSIs: 0,
    icsis: [],
    loading: true,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    filteredHeaders: [],
    sortBy: ['donor_mare', 'donor_stallion', 'code'],
    sortDesc: [false, false, false],
  }),
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
  },
  methods: {
    formatDate,
    horseName,
    compareRouteAndPush,
    openICSIPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/icsi/${id}`);
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    fetchICSI(URLparams) {
      return icsiAPI.getAllICSI(URLparams);
    },
  },
};
</script>
