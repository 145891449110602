<template>
  <v-menu bottom offset-y>
    <template #activator="{ on, attrs }">
      <v-chip :outlined="outlined" v-bind="attrs" :color="color" :text-color="textColor" label :small="small" v-on="on">
        <span v-if="selected">{{ selected }}</span>
        <v-icon v-if="!selected" small> mdi-tag-outline </v-icon>
      </v-chip>
    </template>
    <v-list>
      <v-list-item v-for="(option, i) in options" :key="i" :disabled="disable(option)" @click="selectOption(option)">
        <v-list-item-title>{{ option }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    current: { type: String, default: '' },
    options: { type: Array, required: true },
    color: { type: String, default: 'primary' },
    textColor: { type: String, default: 'primary' },
    disableChild: { type: Object, default: undefined },
    outlined: { type: Boolean },
    disabled: { type: Boolean },
    small: { type: Boolean },
  },
  emits: ['select'],
  data: () => ({ selected: '' }),
  watch: {
    current() {
      this.selected = this.current;
    },
  },
  mounted() {
    this.selected = this.current;
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.$emit('select', this.selected);
    },
    disable(option) {
      return this.disabled || (this.disableChild && option === this.disableChild.child ? this.disableChild.check : false);
    },
  },
};
</script>
