<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12" md="12" sm="12">
          <v-radio-group v-model="customer.type" row :rules="required">
            <v-radio :label="$t('form.radio1')" value="particulier" />
            <v-radio :label="$t('form.radio2')" value="bedrijf" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="customer.type === 'bedrijf'" cols="12" md="4" sm="6">
          <v-text-field v-model="customer.company" :counter="64" :rules="required64" :label="$t('form.company')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.last_name" :counter="64" :rules="required64" :label="$t('form.last')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.first_name" :counter="64" :rules="required64" :label="$t('form.first')" :loading="loading" />
        </v-col>
        <v-col v-if="customer.type === 'bedrijf'" cols="12" md="4" sm="6">
          <v-text-field v-model="customer.tva" :label="$t('form.vat')" persistent-hint :hint="$t('form.vathint')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-select
            v-model="customer.vat_liability"
            :label="$t('form.vatliab')"
            :items="vatLiabllity"
            item-text="text"
            item-value="value"
            :loading="loading"
            clearable
            :menu-props="{ offsetY: true }"
          />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-select
            v-model="customer.language"
            :rules="required"
            :items="languages"
            :label="$t('form.language')"
            :loading="loading"
            :menu-props="{ offsetY: true }"
          />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.email" :counter="64" :rules="required64" :label="$t('form.mail')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field
            v-model="customer.telephone_mobile"
            :counter="16"
            :label="$t('form.phone')"
            persistent-hint
            :hint="$t('form.phonehint')"
            :loading="loading"
          />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field
            v-model="customer.telephone_fixed"
            :counter="16"
            :label="$t('form.phonefix')"
            persistent-hint
            :hint="$t('form.phonefixhint')"
            :loading="loading"
          />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.addressLine" :label="$t('form.address')" :loading="loading" :rules="required" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.zip" :counter="16" :label="$t('form.zip')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.city" :counter="32" :label="$t('form.city')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field v-model="customer.country" :rules="required" :label="$t('form.country')" :loading="loading" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-text-field
            v-if="activeTenant.integrations.teamleader"
            v-model="customer.teamleader_id"
            :label="$t('form.tlid')"
            :loading="loading"
          />
        </v-col>
      </v-row>
      <v-row justify="end" class="gap-4">
        <Button type="back" :action="() => $router.back()" />
        <v-btn v-if="!customer._id" :disabled="!valid" color="success" depressed @click="createCustomer()">
          {{ $t('form.save') }}
        </v-btn>
        <v-btn v-if="customer._id" :disabled="!valid" color="success" depressed @click="updateCustomer()">
          {{ $t('form.edit') }}
        </v-btn>
        <v-btn v-if="customer._id" color="warning" depressed @click="deleteDialog = true">
          {{ $t('form.deleteclient') }}
        </v-btn>
      </v-row>
      <v-dialog v-model="deleteDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">
            {{ $t('form.deleteconfirm') }}
          </v-card-title>
          <v-card-text>
            {{ $t('form.confirm1') }} <b>{{ customer.first_name }} {{ customer.last_name }}</b> {{ $t('form.confirm2') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" depressed @click="deleteDialog = false">
              {{ $t('form.cancel') }}
            </v-btn>
            <v-btn color="error" depressed @click="deleteCustomer()">
              {{ $t('form.delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <snackbar :snackbar="snackbar" color="success" :text="$t('form.success')" @timeout="snackbar = $event" />
    </v-container>
  </v-form>
</template>
<script>
import { configAPI } from '@/services';
import customerAPI from '@/services/CustomerAPI.js';
import { required, required64 } from '@/Helpers';
import { mapActions } from 'vuex';
import Snackbar from '@/components/common/Snackbar';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, Snackbar },
  props: {
    customer: { type: Object, default: () => ({}) },
    loading: { type: Boolean },
  },
  emits: ['update-horse', 'close-dialog', 'update-customer'],
  data: () => ({
    deleteDialog: false,
    languages: ['NL', 'FR', 'EN'],
    snackbar: false,
    valid: false,
    vatLiabllity: [],
  }),
  computed: {
    required,
    required64,
    activeTenant() {
      return this.$store.state.tenantInfo.activeTenant;
    },
  },
  mounted() {
    this.getCustomerConfig();
  },
  methods: {
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setError',
    }),
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    async getCustomerConfig() {
      try {
        const {
          data: { vatLiability },
        } = await configAPI.getCustomerConfig();
        this.vatLiabllity = vatLiability;
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async createCustomer() {
      await this.resetError();
      try {
        const response = await customerAPI.postCustomer(this.customer);
        this.$emit('update-horse', response.data);
        await this.$router.push(`/customer/${response.data._id}`);
        this.snackbar = true;
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async deleteCustomer() {
      await this.resetError();
      try {
        await customerAPI.deleteCustomer(this.customer._id);
        this.$emit('close-dialog', false);
        await this.$router.back();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async updateCustomer() {
      await this.resetError();
      try {
        const response = await customerAPI.putCustomer(this.customer);
        this.$emit('update-customer', response.data);
        this.snackbar = true;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
