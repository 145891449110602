export const customers = {
    add: 'Add client',
  tabs: {
    private: 'Private individuals',
    company: 'Companies',
  },
  table: {
    headers: {
      company: 'Company',
      firstName: 'Firstname',
      lastName: 'Lastname',
      createdAt: 'Created at',
      vat: 'VAT-nr',
    },
  },
  general: {},
  horses: {
    table: {
      name: 'Name horse',
      familyTree: 'Family tree',
      type: 'Horse type',
    },
  },
  embryos: {},
  semen: {},
  researchFiles: {},
};
