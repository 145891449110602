<template>
  <v-layout column fill-height justify-space-between>
    <horse-table :title="$t('fertility.semen.title')" :headers="headers" @update-download-data="downloadData = $event" />
    <v-row class="mb-5 mr-5" justify="end">
      <v-btn
        style="width: fit-content; align-self: end"
        color="success"
        class="mr-5"
        depressed
        tag="a"
        :href="downloadData.dataUri"
        :download="downloadData.name"
      >
        {{ $t('fertility.semen.download') }}

        <v-icon right> mdi-file-download </v-icon>
      </v-btn>

      <v-btn style="width: fit-content; align-self: end" color="primary" depressed @click="() => openSemenForm()">
        {{ $t('fertility.semen.add') }}
        <v-icon right> mdi-plus </v-icon>
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import HorseTable from '@/components/semenCollection/HorseTable';
import i18n from '@/translations/i18n';

export default {
  components: { HorseTable },
  data: () => ({
    createDialog: false,
    headers: [
      { text: i18n.t('fertility.semen.table.headers.name'), value: 'horse' },
      { text: i18n.t('fertility.semen.table.headers.initial'), value: 'initial' },
      { text: i18n.t('fertility.semen.table.headers.current'), value: 'current' },
      { text: i18n.t('fertility.semen.table.headers.mods'), value: 'mods' },
    ],
    downloadData: {},
  }),
  methods: {
    openSemenForm(id) {
      this.$router.push({ path: `/semen/create/${id}` });
    },
  },
};
</script>
