export const lodging = {
    labels: {
        days: 'days',
        add: 'Add new stay',
        arrivalDate: 'arrival date',
        departureDate: 'departure date',
        stable: 'Stable',
        prefix: 'Prefix',
        location: 'Location',
        full: '(Full)',
        free: 'Amount of free spots:',
        pension: 'Guesthouse type',
        stay: 'Duration of stay',
        status: 'Facturation status',
    },
    dialog: {
        edit: 'Edit stays',
        delete: 'Delete stay',
        billable: 'Is this stay billable?',
    },
    warnings: {
        foundDupe: 'Overlaps were found',
        chooseStable: 'Please choose a stable',
        chooseLocation: 'Please choose a location',
        dateDupe: 'With the current arrival and departure dates, overlaps were found with other stays',
    },
};
