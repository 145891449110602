var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"d-flex justify-end mr-5"},[_c('v-switch',{staticClass:"ml-2",attrs:{"inset":"","label":_vm.$t('horses.nav.stallion'),"disabled":_vm.filters.surrogate},on:{"change":function($event){!_vm.filters.stallion ? (_vm.filters.stud_horse = false) : ''}},model:{value:(_vm.filters.stallion),callback:function ($$v) {_vm.$set(_vm.filters, "stallion", $$v)},expression:"filters.stallion"}}),_c('v-switch',{staticClass:"ml-5",attrs:{"inset":"","label":_vm.$t('horses.nav.stud'),"disabled":!_vm.filters.stallion},on:{"change":() => {
          if (_vm.filters.stud_horse) {
            _vm.filters.mare = false;
            _vm.filters.surrogate = false;
          }
        }},model:{value:(_vm.filters.stud_horse),callback:function ($$v) {_vm.$set(_vm.filters, "stud_horse", $$v)},expression:"filters.stud_horse"}}),_c('v-switch',{staticClass:"ml-5",attrs:{"inset":"","label":_vm.$t('horses.nav.mare'),"disabled":_vm.filters.stud_horse},on:{"change":function($event){!_vm.filters.mare ? (_vm.filters.surrogate = false) : ''}},model:{value:(_vm.filters.mare),callback:function ($$v) {_vm.$set(_vm.filters, "mare", $$v)},expression:"filters.mare"}}),_c('v-switch',{staticClass:"ml-5",attrs:{"inset":"","label":_vm.$t('horses.nav.surrogate'),"disabled":!_vm.filters.mare},on:{"change":() => {
          if (_vm.filters.surrogate) {
            _vm.filters.stallion = false;
            _vm.filters.stud_horse = false;
          }
        }},model:{value:(_vm.filters.surrogate),callback:function ($$v) {_vm.$set(_vm.filters, "surrogate", $$v)},expression:"filters.surrogate"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.horses,"loading":_vm.loading,"server-items-length":_vm.totalHorses,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"options":_vm.options,"loading-text":_vm.$t('common.loading')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{staticClass:"clickable",on:{"click":function($event){return _vm.openHorsePage(props.item._id)}}},[_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(_vm.checkFamily(props.item.father, props.item.grandfather)))]),_c('td',[_vm._v(_vm._s(props.item.type))])])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('horses.noneFound'))+" ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }