<template>
  <v-chip-group column>
    <HighlightTagWrapper v-for="(item, idx) in highlightableItems" :key="idx" :data="item.protocol || item.labo" />
  </v-chip-group>
</template>

<script>
import HighlightTagWrapper from '@/components/case/HighlightTagWrapper';

export default {
  components: { HighlightTagWrapper },
  props: {
    highlightableItems: { type: Array, default: () => [] },
  },
  data: () => ({
    panel: 0,
  }),
};
</script>
