<template>
  <v-card flat>
    <v-form ref="form" v-model="valid" :disabled="!sheet.billable">
      <v-row>
        <v-col class="pb-0" cols="4">
          <v-select
            v-model="sheet.doctor"
            :items="vets"
            :label="$t('researchform.doc')"
            :menu-props="{ offsetY: true }"
            :rules="required"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col class="pb-0" cols="4">
          <date-picker
            :date="sheet.researchDate"
            :label="$t('researchform.date')"
            :max="new Date()"
            :outlined="true"
            :required="true"
            mode="dateTime"
            :dense="true"
            @select-date="sheet.researchDate = $event"
          />
        </v-col>
        <v-col class="pb-0" cols="4">
          <history-dialog v-if="horse.type === 'Merrie'" :customer="customer" :mare="horse" />
        </v-col>
      </v-row>
      <v-row v-if="horse && horse.type === 'Merrie'">
        <v-col cols="12" md="6" outlined>
          <ovary-table
            title="Linker ovarium"
            :disabled="!!sheet._id"
            :ovary-data="sheet.information.leftOvary"
            :ovary-tags="horse.ovaryTags.leftOvary.tags"
            :follicle-tags="follicleTags"
            :ovary-tags-list="ovaryTagsList.filter((tag) => !horse.ovaryTags.leftOvary.tags.includes(tag))"
          />
        </v-col>
        <v-col cols="12" md="6" outlined>
          <ovary-table
            title="Rechter ovarium"
            :disabled="!!sheet._id"
            :ovary-data="sheet.information.rightOvary"
            :ovary-tags="horse.ovaryTags.rightOvary.tags"
            :follicle-tags="follicleTags"
            :ovary-tags-list="ovaryTagsList.filter((tag) => !horse.ovaryTags.rightOvary.tags.includes(tag))"
          />
        </v-col>
      </v-row>
      <v-row v-if="horse && horse.type === 'Merrie' && sheet.information.uterus">
        <v-col cols="12" outlined>
          <uterus-form
            :moist-list="moistList"
            :moist-type="moistType"
            :pregnant-options="pregnantOptions"
            :uterus-data="sheet.information.uterus"
            :pregnant="sheet.information.pregnant"
            @emit-pregnant="sheet.information.pregnant = $event"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pb-0" outlined>
          <protocols-table :sheet="sheet" :data="sheet.protocols" title="Protocols" />
        </v-col>
        <v-col cols="12" md="6" class="pb-0" outlined>
          <labo-table :sheet="sheet" :data="sheet.labos" title="Labo-onderzoeken" />
        </v-col>
        <v-col cols="12" md="6" outlined>
          <products-table :sheet="sheet" :data="sheet.products" title="Products" />
        </v-col>
        <v-col v-if="horse && horse.type === 'Merrie'" cols="12" md="6" outlined>
          <cystsTable title="Cystes" :sheet="sheet" :cyst-types="cystTypes" :cysts="horse.cysts" @emit-cysts="horse.cysts = $event" />
        </v-col>
      </v-row>
    </v-form>
    <div class="mr-1 mb-5 mt-4">
      <v-row justify="end" class="gap-4" dense>
        <Button type="back" :action="() => $router.push('/cases')" />

        <v-btn v-if="checkIfEmpty" depressed color="error" @click="openDeleteDialog(sheet)">
          <v-icon left> mdi-delete </v-icon>
          {{ $t('researchform.delete') }}
        </v-btn>
        <v-btn v-if="sheet.billable" :disabled="!valid" color="success" depressed :loading="saving" @click="createResearchSheet">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('researchform.save') }}
        </v-btn>
      </v-row>
    </div>
    <snackbar :snackbar="snackbar" :color="snackColor" :text="snackText" @timedout="snackbar = $event" />
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Onderzoeksfiche"
      :delete-queue="deleteQueue"
      @delete="deleteResearchSheet"
      @close="closeDeleteDialog"
    />
  </v-card>
</template>

<script>
import DeleteDialog from '@/components/common/DeleteDialog';
import Snackbar from '@/components/common/Snackbar';
import DatePicker from '@/components/DateSelector/DatePicker';
import laboTable from '@/components/researchSheet/LaboTable';
import cystsTable from '@/components/researchSheet/mareData/CystsTable';
import historyDialog from '@/components/researchSheet/mareData/HistoryDialog';
import ovaryTable from '@/components/researchSheet/mareData/OvaryTable';
import uterusForm from '@/components/researchSheet/mareData/UterusForm';
import productsTable from '@/components/researchSheet/ProductsTable';
import protocolsTable from '@/components/researchSheet/ProtocolsTable';
import { required } from '@/Helpers';
import { configAPI, horseAPI, researchSheetAPI } from '@/services';
import Button from '@/components/buttons/Button.vue';

export default {
  components: {
    Button,
    protocolsTable,
    laboTable,
    productsTable,
    ovaryTable,
    uterusForm,
    cystsTable,
    historyDialog,
    DatePicker,
    Snackbar,
    DeleteDialog,
  },
  props: {
    kees: {
      type: Object,
      default: () => ({}),
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
    horse: {
      type: Object,
      default: () => ({
        ovaryTags: {
          leftOvary: { tags: [] },
          rightOvary: { tags: [] },
        },
        cysts: {},
      }),
    },
    sheet: {
      type: Object,
      required: true,
    },
    loading: { type: Boolean },
  },
  data: () => ({
    saving: false,
    valid: false,
    vets: [],
    moistList: [],
    moistType: [],
    pregnantOptions: [],
    follicleTags: [],
    ovaryTagsList: [],
    cystTypes: [],
    snackbar: false,
    snackColor: '',
    snackText: '',
    deleteQueue: {},
    deleteDialog: false,
  }),
  computed: {
    required,
    checkIfEmpty() {
      return !!this.sheet._id && !this.sheet.protocols.length && !this.sheet.labos.length && !this.sheet.products.length;
    },
  },
  watch: {
    horse() {
      this.addMareInfo();
    },
  },
  mounted() {
    this.getResearchConfig();
    this.getVetNames();
  },
  beforeMount() {
    this.addMareInfo();
  },
  methods: {
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
    showSnackbar(color, text) {
      this.snackbar = true;
      this.snackColor = color;
      this.snackText = text;
    },
    addMareInfo() {
      if (this.horse.type !== 'Merrie') {
        this.sheet.information = undefined;
      }
      if (!this.sheet._id && this.kees.researchSheets.length) {
        const { information } = this.kees.researchSheets[this.kees.researchSheets.length - 1];
        this.sheet.information = information;
      }
      if (!this.sheet.information) {
        this.sheet.information = {
          leftOvary: {
            follicles: [],
          },
          rightOvary: {
            follicles: [],
          },
          uterus: {
            rating: 0,
            content: {},
          },
        };
      }
      if (!this.sheet._id && !this.sheet.information.uterus) {
        this.sheet.information.uterus = {
          rating: 0,
          content: {},
        };
      }
    },
    filterTags(data) {
      return data.information
        ? {
            ...data,
            information: {
              ...data.information,
              rightOvary: data.information.rightOvary.follicles.filter((follicle) => !!follicle.follicleTag),
              leftOvary: data.information.leftOvary.follicles.filter((follicle) => !!follicle.follicleTag),
            },
          }
        : data;
    },
    async sheetHandler(data) {
      if (this.horse && this.horse.type === 'Merrie') {
        try {
          await horseAPI.putHorse(this.horse);
        } catch (error) {
          await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
        }
      }
      this.$emit('update-sheet', data);
      await this.$router.replace(`/case/${this.kees._id}?tab=general`);
    },
    async createResearchSheet() {
      this.saving = true;
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const sheet = {
          ...this.sheet,
          client: this.customer._id,
          horse: this.horse._id,
          case: this.kees._id,
        };
        const { data } = await (sheet._id ? researchSheetAPI.putResearchSheet(sheet) : researchSheetAPI.postResearchSheet(sheet));
        await this.sheetHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.saving = false;
      }
    },
    async editResearchSheet() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await researchSheetAPI.putResearchSheet(this.filterTags(this.sheet));
        await this.sheetHandler(data);
        this.showSnackbar('success', 'Onderzoeksfiche succesvol opgeslagen');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async deleteResearchSheet() {
      try {
        await researchSheetAPI.deleteResearchSheet(this.sheet._id);
        this.showSnackbar('success', 'Onderzoeksfiche succesvol opgeslagen');
        this.$emit('update-sheet', true);
        await this.$router.push(`/case/${this.kees._id}?tab=general`);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getVetNames() {
      try {
        const {
          data: { vets },
        } = await configAPI.getVetNames();
        this.vets = vets;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getResearchConfig() {
      try {
        const {
          data: { moistList, moistType, pregnantOptions, follicleTags, ovaryTags, cystType },
        } = await configAPI.getResearchConfig();
        this.moistList = moistList;
        this.moistType = moistType;
        this.pregnantOptions = pregnantOptions;
        this.follicleTags = follicleTags;
        this.ovaryTagsList = ovaryTags;
        this.cystTypes = cystType;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
