<template>
  <v-layout column fill-height justify-space-between>
    <stock-table
      :title="$t('medication.title')"
      :headers="headers"
      :filters="filters"
      :sort-by="sortBy"
      :update="update"
      @updated="update = $event"
    />
    <v-btn
      disabled="!mediniCredentials"
      style="width: fit-content; align-self: flex-end"
      color="primary"
      class="mr-5 mb-5"
      depressed
      @click="mediniDialog = true"
    >
      {{ $t('medication.import') }}
      <v-icon right> mdi-autorenew </v-icon>
    </v-btn>
    <medini-dialog :show-delivery-products="mediniDialog" @close="mediniDialog = $event" @update-stock="update = $event" />
  </v-layout>
</template>

<script>
import stockTable from '@/components/stock/Table';
import mediniDialog from '@/components/mediniDialog';
import { configAPI } from '@/services';
import { mapState } from 'vuex';
import i18n from '@/translations/i18n';

export default {
  components: { stockTable, mediniDialog },
  data: () => ({
    update: false,
    mediniDialog: false,
    headers: [
      { selected: true, text: i18n.t('medication.table.headers.name'), value: 'name' },
      { selected: true, text: i18n.t('medication.table.headers.type'), value: 'type' },
      { selected: true, text: i18n.t('medication.table.headers.cnk'), value: 'CNK' },
      { selected: true, text: i18n.t('medication.table.headers.dose'), value: 'dosage' },
      { selected: true, text: i18n.t('medication.table.headers.unit'), value: 'outgoingUnit' },
      { selected: true, text: i18n.t('medication.table.headers.tax'), value: 'tax' },
      { selected: true, text: i18n.t('medication.table.headers.exp'), value: 'expirationDate' },
      { selected: false, sortable: false, text: i18n.t('medication.table.headers.waitTime'), value: 'waitingTime' },
      { align: 'end', selected: false, text: i18n.t('medication.table.headers.supplement'), value: 'supplementAdministration' },
      { align: 'end', selected: true, text: i18n.t('medication.table.headers.totalPrice'), value: 'sellValue' },
      { align: 'end', selected: false, text: i18n.t('medication.table.headers.remainingPrice'), value: 'buyinValue' },
      { align: 'end', selected: true, text: i18n.t('medication.table.headers.remaining'), value: 'remaining' },
    ],
    sortBy: ['name'],
    productTypes: [],
    taxes: [],
  }),
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      mediniCredentials: (state) => state.tenantInfo.activeTenant?.integrations.medini,
    }),
    filters() {
      return {
        type: {
          vTag: 'select',
          label: i18n.t('medication.filters.type'),
          items: this.productTypes,
          options: { multiple: true },
        },
        tax: {
          vTag: 'select',
          label: i18n.t('medication.filters.tax'),
          items: this.taxes,
          options: { multiple: true },
        },
        remaining: {
          vTag: 'select',
          label: i18n.t('medication.filters.remaining'),
          items: ['In stock', 'Out of stock'],
        },
      };
    },
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler() {
        this.sortBy = this.$route.query.sortBy;
      },
    },
  },
  mounted() {
    this.getProductConfig();
  },
  methods: {
    async getProductConfig() {
      try {
        if (!this.productTypes.length && !this.taxes.length) {
          const {
            data: { types, tax },
          } = await configAPI.getProductConfig();
          this.productTypes = types;
          this.taxes = tax;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
