<template>
  <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="200">
    <template #activator="{ on }">
      <v-text-field
        :value="dateDisplayText"
        :rules="rules"
        :label="label"
        readonly
        :outlined="outlined"
        :dense="dense"
        :disabled="disabled"
        :hide-details="hideDetails"
        :clearable="clearable"
        v-on="on"
        @click:clear="datePicker = { start: undefined, end: undefined }"
      />
    </template>
    <simple-date-picker
      :close-on-input="closeOnInput"
      :range="range"
      :expanded="expanded"
      :date="datePicker"
      :no-border="noBorder"
      :required="required"
      :mode="mode"
      :min="min"
      :max="max"
      @close-menu="dateMenu = $event"
      @select-date="datePicker = $event"
    />
  </v-menu>
</template>

<script>
import { formatDate, formatDateFull } from '@/Helpers';
import SimpleDatePicker from '@/components/DateSelector/SimpleDatePicker';

export default {
  components: { SimpleDatePicker },
  props: {
    date: { type: [Date, String, Object], default: undefined },
    max: { type: [Date, String], default: undefined },
    min: { type: [Date, String], default: undefined },
    mode: { type: String, default: 'date' },
    label: { type: String, default: 'datum' },
    dense: { type: Boolean },
    range: { type: Boolean },
    noBorder: { type: Boolean },
    expanded: { type: Boolean },
    required: { type: Boolean },
    outlined: { type: Boolean },
    disabled: { type: Boolean },
    clearable: { type: Boolean },
    hideDetails: { type: Boolean },
    closeOnInput: { type: Boolean },
  },
  data: () => ({
    dateMenu: false,
    datePicker: '',
    attributes: [
      {
        key: 'today',
        highlight: {
          color: 'gray',
          fillMode: 'light',
        },
        dates: new Date(),
      },
    ],
  }),
  computed: {
    dateDisplayText() {
      if (this.range) {
        return this.datePicker.start && this.datePicker.end
          ? `${formatDate(this.datePicker.start)} > ${formatDate(this.datePicker.end)}`
          : '';
      }
      if (!this.range && this.mode === 'dateTime') {
        return formatDateFull(this.datePicker);
      }
      return formatDate(this.datePicker);
    },
    rules() {
      return [(value) => (this.required ? !!value || 'dit veld is required' : true)];
    },
  },
  watch: {
    date() {
      if (this.range && this.datePicker.start !== this.date.start && this.datePicker.end !== this.date.end) {
        this.datePicker = this.date;
      } else {
        this.datePicker = this.date;
      }
    },
    datePicker() {
      this.$emit('select-date', this.datePicker);
    },
  },
  mounted() {
    this.datePicker = this.date;
  },
  methods: {
    formatDate,
    formatDateFull,
  },
};
</script>
