export const dutchToRefactor = {
  announcement: 'Geen nieuwe aankondigingen',
  loading: 'Bezig met laden...',
  location: {
    table: 'Geen vrije plaatsen voor deze stal',
    header1: 'Naam locatie',
    header2: 'Vrije plaatsen',
  },
  searchhorse: {
    title: 'Zoek paard',
    subtitle: 'Navigeer hier direct naar het gewenste paard',
  },
  caseoverview: {
    backicon: 'terug',
    delete: 'Case verwijderen',
    bill: 'Tussentijds factureren',
    close: 'Case afsluiten',
    save: 'Opslaan',
    protocols: 'protocols',
  },
  expandtable: {
    tablecase: 'Geen cases toegevoegd',
    loading: 'Onderzoeksfiches ophalen...',
    header1: 'Onderzoeksdatum',
    header2: 'Dokter',
  },
  pensioncost: {
    nodata: 'Geen pension gevonden',
    header1: 'Type',
    header2: 'Locatie',
    header3: 'Aankomst',
    header4: 'Vertrek',
  },
  addproduct: {
    add: 'Producten toevoegen',
    id: 'product met id:',
    gone: 'bestaat niet meer',
  },
  buttonaction: 'Acties',
  deletedialog: {
    title: 'verwijderen',
    readyto: 'U staat op het punt om',
    todelete: 'te verwijderen.',
    confirm: 'Bent u zeker dat u dit item wil verwijderen?',
    cancel: 'Annuleren',
    delete: 'Verwijderen',
  },
  horselocation: {
    title: 'Paarden verplaatsen',
    amount: 'Aantal vrije plaatsen:',
    cancel: 'Annuleren',
    move: 'Verplaatsen',
    stable: 'Stal',
    location: 'Locatie',
    nodata: 'Geen locatie beschikbaar',
  },
  relocatedialog: {
    title: 'Locatie wijzigen',
    cancel: 'Annuleren',
    move: 'Verplaatsen',
    tube: 'Koker',
    container: 'Stikstof vat *',
    position: 'Positie *',
  },
  verificationdialog: {
    confirm1: 'Bent u zeker dat u',
    confirm2: 'wil',
    confirm3: '?',
    cancel: 'Annuleren',
    default1: 'dit item',
    default2: 'Bevestiging',
    default3: 'verwijderen',
  },
  coveredmares: {
    horsesexpand: 'alle gedekte merries',
    nodata: 'Geen data beschikbaar',
    merrie: 'merrie',
    loading: 'Bezig met laden...',
    name: 'Naam paard',
    type: 'Type paard',
    cover: 'Gedekt',
    ueln: 'UELN',
    chip: 'Microchip',
    owner: 'Eigenaar',
    stallion: 'Hengsthouder',
  },
  contacts: {
    add: 'Contact toevoegen',
    cancel: 'Annuleren',
    save: 'Opslaan',
    nodata: 'Deze klant heeft nog geen contacten',
    first: 'Voornaam',
    last: 'Achternaam',
    phone: 'Telefoon',
    mail: 'Email',
    role: 'Opslaan',
    edit: 'Opslaan',
    new: 'Nieuw contact',
    edit2: 'Contact bewerken',
    loading: 'Bezig met laden...',
  },
  form: {
    back: 'terug',
    save: 'Klant opslaan',
    edit: 'Klant bijwerken',
    deleteclient: 'Klant verwijderen',
    deleteconfirm: 'Klant verwijderen?',
    confirm1: 'Ben je zeker dat je de klant',
    confirm2: 'wilt verwijderen? Dit kan niet meer ongedaan gemaakt worden',
    cancel: 'Annuleren',
    delete: 'Verwijderen',
    part: 'Annuleren',
    radio1: 'Particulier',
    radio2: 'Bedrijf',
    company: 'Bedrijfsnaam *',
    last: 'Achternaam',
    first: 'Voornaam',
    vat: 'BTW-nummer',
    vathint: 'voorbeeld: BE0123456789',
    vatliab: 'BTW-plichtigheid',
    language: 'Taal *',
    mail: 'E-mail adres',
    phone: 'Telefoonnummer - mobiel',
    phonehint: 'voorbeeld: +32486688502',
    phonefix: 'Telefoonnummer - vast',
    phonefixhint: 'voorbeeld: +3211781113',
    address: 'Adres Regel *',
    zip: 'Postcode',
    city: 'Gemeente',
    country: 'Land *',
    tlid: 'Teamleader ID',
    success: 'Klant is succesvol opgeslaan',
  },
  searchhorseblock: {
    title: 'Meest bezochte paarden',
    subtitle: 'Er zijn geen resultaten om weer te geven.',
  },
  expirelabblock: {
    title: "Labo's",
    subtitle: "Eerste 5 labo's die bijna vervallen",
    nodata: "Geen labo's die recent vervallen",
    lab: 'Labo',
    horse: 'Paard',
    client: 'Klant',
    expiration: 'Vervaldatum',
  },
  expireproductblock: {
    title: 'Producten',
    subtitle: 'Eerste 5 product loten die bijna vervallen',
    nodata: 'Geen producten die recent vervallen',
    btn: 'Meer info',
    name: 'Product',
    lot: 'Lot',
    expiration: 'Vervaldatum',
  },
  highlighttitle: 'Uitgelicht',
  advconfigtitle: 'Geavanceerde configuratie',
  paramselecttitle: 'Met deze knoppen kan je extra velden toevoegen.',
  typeselect: 'Met deze selector kan je rapporten genereren binnen een case.',
  transactionconfig: 'Met deze selectors kan je het transactie type en de actie selecteren voor dit protocol ivm het activiteitenverslag.',
  transconfigselect: {
    type: 'Transactie type',
    action: 'Transactie actie',
  },
  labresult: {
    select: 'Labo-onderzoek selecteren',
    date: 'Onderzoeksdatum',
    file: 'Bestand selecteren',
    upload: 'Uploaden',
    nodata: 'Geen labo-resultaten',
    see: 'Bestand bekijken',
    delete: 'Bestand verwijderen',
    filename: 'bestandsnaam',
    research: 'Labo-onderzoek',
    researchdate: 'Onderzoeksdatum',
  },
  maretranssum: {
    win: 'Gewonnen',
    received: 'Ontvangen',
    sent: 'Verzonden',
    frozen: 'Bevroren',
    icsi: 'Gebruikt voor ICSI',
    egg: 'Eicel',
    prod: 'Geproduceerd',
    plan: 'Ingepland',
    preg: 'Drachtig',
    days: '42 dagen',
    embryo1: 'Embryo door spoeling',
    embryo2: 'Embryo door ICSI',
  },
  passport: {
    upload: 'Passpoort uploaden',
    see: 'Passpoort bekijken',
    delete: 'Passpoort verwijderen',
  },
  staltranssum: {
    win: 'Gewonnen',
    use: 'Gebruik',
    sent: 'Verzonden',
    import: 'Import',
    export: 'Export',
    amount: 'Dosissen zaad (vers)',
    seed: 'Rietjes zaad (diepvries)',
  },
  horsetable: {
    group: 'Groepeer per locatie',
    nodata: 'Geen paarden gevonden',
    donor: 'Donor merrie',
    implant: 'Datum implantatie',
    move: 'Verplaatsen',
    horse: 'Paarden',
    dec: 'Overleden',
    mare: 'Merrie',
    surr: 'Draagmoeders',
    mares: 'Merries',
    stallions: 'Hengesten',
    studs: 'Dekhengsten',
    stallion: 'Hengst',
  },
  transtable: {
    nodata: 'Geen transacties gevonden.',
    rap: 'Transactie rapport',
    filtertype: 'Filter op type',
    filteraction: 'Filter op actie',
    type: 'Type',
    action: 'Actie',
    in: 'In',
    out: 'Uit',
    dest: 'Bestemming',
    date: 'Datum',
  },
  invoicedialog: {
    embryo: "Stockage embryo's factureren -",
    batch: 'batch code',
    from: 'van *',
    untill: 'tot *',
    cancel: 'Annuleren',
    total: 'Afrekenen',
  },
  actiondialog: {
    embryo: 'Embryo actie',
    house: 'In house stockage',
    owner: 'Stockage bij de eigenaar',
    select: 'Selecteer een embryo',
    cancel: 'Annuleren',
  },
  embryotable: {
    embryos: 'Embryos',
    nodata: 'Geen Embryos gevonden',
    cooker: '- Koker',
    embryo: 'embryo',
    export: 'Export',
    change: 'Verander eigenaar',
    stock: 'Stock factureren',
    edit: 'Locatie wijzigen',
    damage: 'Beschadigd',
  },
  icsiform: {
    title: 'ICSI toevoegen',
    icsi: 'Code ICSI *',
    rules: 'Dit veld is verplicht english moet groter zijn dan 0',
    emamount: 'Aantal embryos',
    owner: 'Eigenaar',
    importdate: 'Datum import',
    title2: 'Locatie',
    color: 'Kleur rietjes',
    container: 'Stikstof vat *',
    cooker: 'Koker *',
    position: 'Positie *',
    saved: 'ICSI is succesvol opgeslaan',
    select: 'Embryo selecteren',
    selectall: 'Alles selecteren',
    deselect: 'Deselecteren',
    back: 'terug',
    save: 'ICSI opslaan',
    edit: 'bijwerken',
    delete: 'verwijderen',
    deletecon: 'ICSI verwijderen?',
    question1: 'Ben je zeker dat je het lot',
    question2: 'wilt verwijderen? Dit kan niet meer ongedaan gemaakt worden',
    cancel: 'Annuleren',
    delete2: 'Verwijderen',
    remainingSpots: 'Resterende plaatsen:',
  },
  noicsidata: 'Geen ICSI loten gevonden',
  laboform: {
    title: 'Labo-onderzoek informatie',
    nlname: 'Onderzoeksnaam - NL *',
    enname: 'Onderzoeksnaam - EN',
    frname: 'Onderzoeksnaam - FR',
    account: 'Onderzoek account',
    tax: 'Onderzoek tax *',
    price: 'Prijs *',
    pricefix: 'Vaste prijs *',
    config: 'Geavanceerde configuratie',
    back: 'terug',
    save: 'Labo-onderzoek opslaan',
    edit: 'Labo-onderzoek aanpassen',
  },
  labotable: {
    nodata1: 'Geen',
    nodata2: 'gevonden',
    edit: 'Onderzoek bewerken',
    delete: 'Onderzoek verwijderen',
  },
  nodata: 'Geen data beschikbaar',
  livestockreg: {
    title: 'Register aanwezige dieren',
    daily: 'Dagelijks overzicht',
    yearly: 'Jaarlijks overzicht',
    year: 'Jaar',
    avg: 'Gemiddelde bezetting per stal',
  },
  yearlylivestock: {
    nodata: 'Geen paarden op stal',
    month: 'Maand',
    amount: 'Aantal paarden per stal',
  },
  locationselector: {
    container1: 'Locatie - stikstof vat',
    container2: 'Locatie - stikstof vat - koker',
    container3: 'Locatie - stikstof vat - koker - positie',
  },
  notifeditor: {
    span: 'Hier heb je de mogelijkheid om meldingen toe te voegen.',
    notif: 'Notificatie',
    notiftext: 'Boodschap',
    days: 'Aantal dagen',
    tag: 'Tags',
  },
  notifmenu: {
    title: 'Geen nieuwe notificaties',
    subtitle: 'Woohoo!',
    less: 'Minder laden',
    more: 'Meer laden',
  },
  pensionform: {
    title: 'Pension informatie',
    nlname: 'Pensionnaam - NL *',
    enname: 'Pensionnaam - EN *',
    frname: 'Pensionnaam - FR *',
    account: 'Pension account',
    tax: 'Pension tax *',
    price: 'Prijs per dag*',
    available: 'Beschikbaar voor',
    back: 'terug',
    save: 'Pension opslaan',
    edit: 'Pension aanpassen',
  },
  pensiontable: {
    nodata1: 'Geen',
    nodata2: 'gevonden',
    edit: 'Pension bewerken',
    delete: 'Pension verwijderen',
  },
  productform: {
    title: 'Product informatie',
    name: 'Productnaam *',
    type: 'Product type *',
    tax: 'Product tax *',
    cnk: 'CNK *',
    dosage: 'aantal/batch *',
    unit: 'Eenheid',
    wait: 'Wachttijd',
    supplement: 'Supplement toediening',
    price: 'Verkoop prijs per eenheid',
    back: 'terug',
    save: 'Product opslaan',
    edit: 'Product aanpassen',
  },
  producttable: {
    nodata: 'Geen',
    edit: 'Product bewerken',
    delete: 'Product verwijderen',
  },
  protocolform: {
    title: 'Protocol informatie',
    nlname: 'Protocol - NL *',
    enname: 'Protocol - EN *',
    frname: 'Protocol - FR *',
    account: 'Protocol account',
    tax: 'Protocol tax *',
    price: 'Prijs *',
    invoice: 'Factuur beschrijving',
    pricefix: 'Vaste prijs *',
    config: 'Geavanceerde configuratie',
    custom: 'Custom factuurbeschrijving',
    back: 'terug',
    save: 'Protocol opslaan',
    edit: 'Protocol aanpassen',
  },

  protocoltable: {
    nodata1: 'Geen',
    nodata2: 'gevonden',
    edit: 'Protocol bewerken',
    delete: 'Protocol verwijderen',
  },

  sementable: {
    showmods: 'Toon stockwijzigingen',
    mods: 'Stockwijziging',
    stallion: 'Hengst',
    info: 'lotnummer / info',
    start: 'Start stock',
    edits: 'Wijziging',
    left: 'Resterend',
    date: 'Datum',
    rap: 'Stock rapport',
    nodata: 'Geen stock gevonden',
    select: 'Selecteer een klant & een paard voor het rapport',
  },

  sementotal: {
    span1: 'Initieel',
    span2: 'Afname',
    span3: 'Voorraad',
  },

  marecyst: {
    nodata: 'Geen cystes gevonden',
    editsize: 'Dikte aanpassen',
    delete: 'Cyste verwijderen',
    cyst: 'Cyst',
    size: 'Dikte',
    location: 'Locatie',
  },

  historydialog: {
    ev: 'evolutie',
    nodata: 'Geen evolutie beschikbaar/gevonden',
    close: 'sluiten',
    left: 'Linker ovarium',
    uterus: 'Uterus',
    right: 'Rechter ovarium',
    preg: 'Drachtig?',
  },

  mareovary: {
    nodata: 'Geen follicles gevonden',
    editsize: 'Dikte aanpassen',
    delete: 'Follikel verwijderen',
    fol: 'Follikel',
    size: 'Dikte',
    shape: 'Vorm',
  },

  uterusform: {
    title: 'Uterus',
    span: 'Oedeem',
    moist: 'Vocht',
    type: 'Type vocht',
    preg: 'Drachtig',
    amount: 'Aantal',
  },

  miniparam: {
    nodata: 'Geen parameters in dit protocol',
    horse: 'Paard:',
    custom: 'Bestemmeling:',
    amount: 'Specifieke hoeveelheid:',
    horse2: 'Paard',
    amount2: 'Aantal',
    location: 'Locatie',
  },

  miniproduct: {
    nodata: 'Geen producten in dit protocol',
    edit: 'Aanpassen',
    name: 'Productnaam',
    amount: 'Aantal',
  },

  researchform: {
    doc: 'Dierenarts',
    date: 'Onderzoeksdatum',
    back: 'terug',
    delete: 'verwijderen',
    save: 'Onderzoeksfiche opslaan',
  },

  searchlabotable: {
    nodata1: 'Geen',
    nodata2: 'toegevoegd',
    delete: 'Onderzoek verwijderen',
    select: 'Labo-onderzoek selecteren',
    none: 'Niets geselecteerd',
    cancel: 'Annuleren',
    add: 'Toevoegen',
    prodname: 'Productnaam',
    amount: 'Aantal',
    labname: 'Labonaam',
    date: 'Datum',
  },

  paramform: {
    add: 'Voeg spermalot toe',
    select: 'Embryo selecteren',
    nodata: 'Geen embryos in stock van deze donor',
  },

  prodtable: {
    title: 'Medicatie',
    nodata1: 'Geen',
    nodata2: 'toegevoegd',
    edit: 'Aanpassen',
    delete: 'Product verwijderen',
    title2: 'Medicatie toevoegen',
    none: 'Niets geselecteerd',
    sell: 'Verkoop',
    cancel: 'Annuleren',
    add: 'Toevoegen',
    amount: 'Aantal',
    name: 'Productnaam',
    dose: 'Toedining',
    edit2: 'Bewerken',
  },

  certtable: {
    nodata1: 'Geen',
    nodata2: 'toegevoegd',
    span: 'Certificaten',
    name: 'Protocolnaam',
    date: 'Datum',
  },

  prototable: {
    nodata1: 'Geen',
    nodata2: 'toegevoegd',
    delete: 'Protocol verwijderen',
    none: 'Niets geselecteerd',
    desc: 'Factuur beschrijving:',
    desc2: 'Factuur beschrijving',
    place: 'Vul hier uw factuur beschrijving in',
    cancel: 'Annuleren',
    add: 'Toevoegen',
    amount: 'Aantal',
    name: 'Protocolnaam',
    date: 'Datum',
  },

  selectsemen: {
    select: 'Sperma selecteren',
    nodata: 'Geen sperma in stock van deze donor',
    rest: 'Resterend:',
    straw: 'rietjes',
    dose: 'Xpaill/dosis:',
    amount: 'Aantal rietjes *',
    max: 'maximum',
    oblig: 'Dit veld is verplicht, moet groter zijn dan 0 english kleiner dan',
  },

  researchtable: {
    select: 'Selecteer alle factureerbare',
    deselect: 'Deselecteer alle factureerbare',
    nodata1: 'Geen',
    nodata2: 'gevonden',
    date: 'Onderzoeksdatum',
    doctor: 'Dokter',
    billable: 'Factureerbaar',
  },

  vat: 'BTW:',

  basicresearch: {
    chip: 'chip:',
    nr: 'brandnr:',
  },

  horsesearch: {
    stud: 'Dekhengst zoeken',
    stallion: 'Hengst zoeken',
    sur: 'Draagmoeder zoeken',
    mare: 'Merrie zoeken',
    search: 'Paard zoeken',
    req: 'dit veld is required',
  },

  labosearch: {
    search: 'Labo-onderzoek zoeken',
    req: 'dit veld is required',
  },

  prodsearch: {
    all: 'Alle producten',
    stock: 'in stock',
    search: 'Product zoeken',
  },

  protosearch: {
    select: 'selecteren',
    protselect: 'Protocol selecteren',
    search: 'Protocol zoeken',
    req: 'dit veld is required',
  },

  semeninvoice: {
    title: 'Stockage factureren -',
    cancel: 'Annuleren',
    bill: 'Factureren',
  },

  semenbatch: {
    lot: 'lot',
    start: 'van *',
    end: 'tot *',
  },

  actiondia: {
    house: 'In house stockage',
    owner: 'Stockage bij de eigenaar',
    entire: 'Volledige loten',
    amount: 'Aantal',
    cancel: 'Annuleren',
    add: 'Toepassen',
    action: 'Sperma actie',
    mult: 'Vermeerderen',
    sub: 'Verminderen',
    from: 'van de',
    selected: 'geselecteerd)',
  },

  dialogcreate: {
    info: 'Gegevens van het nieuwe lot',
    editinfo: 'Lot bewerken',
    type: 'Type sperma lot *',
    prod: 'Datum productie sperma',
    import: 'Datum import sperma',
    container: 'Stikstof vat *',
    cooker: 'Koker *',
    position: 'Positie *',
    straw: 'Aantal rietjes *',
    color: 'Kleur rietjes',
    concen: 'Concentratie',
    moti: 'Motiliteit',
    prog: 'Progressivieit',
    velo: 'Velocity',
    dose: 'Xpail/dosis',
    via: 'Viabiliteit',
    cancel: 'Annuleren',
    save: 'Opslaan',
    edit: 'Bijwerken',
  },

  dialogdelete: {
    span: 'Lot verwijderen',
    fill: 'Vul de naam in van de hengst (',
    confirm: ') om te bevestigen dat je dit sperma lot wilt verwijderen.',
    cancel: 'Annuleren',
    delete: 'Verwijderen',
  },

  dialogedit: {
    span: 'Stockwijziging aan dit lot toevoegen',
    type: 'Type',
    export: 'Export naar',
    amount: 'Aantal rietjes',
    cancel: 'Annuleer',
    save: 'Opslaan',
    return: 'Na controle 1 rietje te kort vuld dan -1 in / Na controle 1 rietje te veel vuld dan 1 in',
  },

  exporttype: {
    select: 'Selecteer het export type (',
    from: 'van de',
    selected: 'geselecteerd)',
    cancel: 'Annuleren',
    save: 'Opslaan',
  },
  moddialog: {
    span: 'Details van de stockwijzigingen aan dit lot',
    nodata: 'Nog geen wijzigingen aan dit lot gevonden',
    close: 'Sluiten',
    type: 'Type',
    client: 'Klant',
    straw: 'Aantal rietjes',
    horse: 'Paard',
    date: 'Datum',
  },

  tablesemen: {
    nodata: 'Geen sperma loten gevonden',
    cooker: '- Koker',
    edit: 'Lot bewerken',
    see: 'Bekijk stockwijzigingen',
    params: 'Kwaliteitsparameters',
    lot: 'Lot',
    stallion: 'Hengst',
    owner: 'Eigenaar',
    ini: 'Initieel',
    left: 'Resterend',
    prod: 'Productiedatum',
    import: 'Importdatum',
    exportdate: 'Exportdatum',
    invoice: 'Laatste facturatie',
    location: 'Locatie & kleur',
    exptype: 'Export type',
    type: 'Type',
    export: 'Export',
    change: 'Verander eigenaar',
    stock: 'Stock facturere',
    editlocation: 'Locatie wijzigen',
    damage: 'Beschadigd',
    destroy: 'Vernietigen',
    control: 'Controle',
    filtertype: 'Filter op type',
    filterexp: 'Filter op export type',
    hide: 'Lege loten verbergen',
  },

  valuedialog: {
    span: 'Kwalitatieve parameters',
    params: 'Geen kwalitatieve parameters gevonden voor dit lot',
    conc: 'Concentratie',
    moti: 'Motiliteit',
    prog: 'Progressiviteit',
    velo: 'Velocity',
    dose: 'Xpail/dosis',
    via: 'Viabliteit',
    close: 'Sluiten',
  },

  selectsigned: 'selecteer ondergetekende',

  createbatch: {
    title: 'Nieuw lot toevoegen',
    nr: 'Lot nummer *',
    exp: 'Verval datum *',
    sup: 'Leverancier *',
    deliv: 'Leveringsdatum *',
    buy: 'Aankoopprijs',
    sell: 'verkoopprijs/eenheid *',
    ini: 'Initieel aantal *',
    cancel: 'Annuleer',
    save: 'Opslaan',
  },

  stockbatch: {
    title: 'Product aanpassen - type:',
    type: 'type *',
    amount: 'aantal *',
    cancel: 'Annuleer',
    save: 'Opslaan',
  },

  batchmods: {
    nodata1: 'Geen stock modificaties voor product',
    nodata2: 'gevonden.',
    type: 'Type',
    prod: 'Product',
    lot: 'Lot nummer',
    client: 'Klant',
    horse: 'Paard',
    amount: 'Aantal',
    date: 'Datum',
    filter: 'Filter op modificatie',
  },

  batchtable: {
    action: 'Acties',
    nodata: 'Geen batches gevonden',
    add: 'Nieuw lot toevoegen',
    deactive: 'Deactiveer',
    active: 'Activeer',
    damage: 'Beschadigd',
    sell: 'Verkoop',
    apply: 'Toediening',
    check: 'Controle',
    filter: 'Filter op resterend',
    success: 'success',
    lot: 'lot succesvol geactiveerd',
  },

  nomods: 'Geen stock modificaties voor product gevonden.',

  stocktable: {
    buy: 'Totale resterende aankoopprijs:',
    sell: 'Totale resterende verkoopprijs:',
    nodata: 'Geen producten gevonden',
  },

  transactionper: {
    title: 'Transacties per hengsthouder',
    year: 'Jaar',
    search: 'Hengsthouder zoeken',
    nodata: 'Geen transacties beschikbaar',
    stallion: 'Hengsthouder',
    horse: 'Naam paard',
    type: 'Type',
    action: 'Actie',
    date: 'Datum',
    destination: 'Bestemming',
  },

  uploadform: {
    file: 'Bestand kiezen',
    upload: 'Upload',
    uploadfile: 'upload .CSV bestanden',
  },

  embryogain: {
    nodata: 'Geen data beschikbaar',
    mare: 'Merries',
    win: 'Eicellen gewonnen/ontvangen',
    use: 'Eicellen gebruikt',
    breed: 'Ras',
    amount: 'Aantal',
    winamount: 'Aantal gewonnen eicellen',
    receiveamount: 'Aantal ontvangen eicellen',
    frozenamount: 'Aantal ingevroren eicellen',
    sentamount: 'Aantal verzonden eicellen',
    icsi: 'Aantal eicellen gebruikt voor ICSI op centrum zelf',
  },

  embryoicsi: {
    nodata: 'Geen data beschikbaar',
    mare: 'Merries',
    amountem: "Aantal embryo's",
    preg: 'Drachtigheid',
    breed: 'Ras',
    amount: 'Aantal',
    prod: 'Geproduceerd',
    frozen: 'Ingevroren',
    sent: 'Verzonden',
    planted: 'Ingeplant',
    min: 'Aantal draagmerries minstens 42 dagen drachtig',
  },

  embryorinsed: {
    nodata: 'Geen data beschikbaar',
    mare: 'Merries',
    amountem: "Aantal embryo's",
    preg: 'Drachtigheid',
    breed: 'Ras',
    amount: 'Aantal',
    win: 'Gewonnen',
    frozen: 'Ingevroren',
    sent: 'Verzonden',
    planted: 'Ingeplant',
    min: 'Aantal draagmerries minstens 42 dagen drachtig',
  },

  spermgain: {
    nodata: 'Geen data beschikbaar',
    noname: 'Geen naam',
    nobook: 'Geen stamboek',
    id: 'Identiteit van de hengsten',
    info: 'Gegevens spermawinning d = dosis vers / r = rietje diepvries',
    name: 'Stamboeknaam',
    enroll: 'Minstens één stamboek van inschrijving',
    amount: 'Aantal gewonnen dosissen vers / rietjes in centrum',
  },

  generalinfo: {
    company: '1. Gegevens van uw bedrijf.',
    name: 'naam',
    nr: 'ondernemingsnummer',
    address: 'straat english nummer',
    zip: 'postnummer english gemeente',
    contact: '2. Contactgegevens',
    fullname: 'voor- english achternaam bedrijfsverantwoordelijke',
    tel: 'telefoonnummer',
    phone: 'gsm-nummer',
    fax: 'faxnummer',
    mail: 'e-mailadres',
    site: 'website',
    fill: '3. Vul de onderstaande verklaring in.',
    accept: 'Ik verklaar de voorgaande gegevens naar de waarheid te hebben ingevuld.',
    date: 'Datum',
    sign: 'Handtekening',
    fullname2: 'voor- english achternaam',
    selection: 'Selectie velden',
    select: 'selecteer ondergetekende',
    print: 'print',
  },

  yearlyreport: {
    title: 'Jaarlijks rapport',
    gen: 'Algemene info',
    tables: 'Tabellen',
    select: 'Selecteer een jaar',
    table: 'Tabel',
    print: 'print alle tabellen',
  },

  medinidialog: {
    title: 'Imported batches',
    nodata: 'Geen leveringen gevonden',
    save: 'Opslaan',
    exit: 'Sluiten',
    cnk: 'CNK',
    name: 'Naam',
    tax: 'Tax',
    lot: 'Lotnummer',
    sup: 'Leverancier',
    buy: 'Aankoopprijs',
    sell: 'Verkoopsprijs',
    unit: 'Eenheidsprijs',
    amount: 'Aantal',
    dosage: 'Aantal/batch',
    units: 'Eenheid',
    expdate: 'expirationDate',
    deldate: 'deliveryDate',
  },

  login: {
    log: 'Login om toegang te krijgen',
    access1: 'Om toegang te krijgen tot',
    access2: 'moet je eerst ingelogd zijn',
    login: 'Inloggen',
  },

  getinfo: 'Ophalen van gegevens...',

  caseactiv: 'Hoofdactiviteit',

  coveredindex: {
    title: 'Lijst gedekte merries per dekhengst',
    year: 'Jaar van het dekseizoen',
  },

  customer: {
    gen: 'Algemene gegevens',
    contacts: 'Contacten',
    horses: 'Paarden',
    embryos: 'Embryos',
    semen: 'Sperma',
    research: 'Onderzoeksfiches',

    client: 'Klant',
    horse: 'Paard',
    extra: 'Extra',
    amount: 'Aantal',
    status: 'status',
    edit: 'Gewijzigd',
    made: 'Gemaakt',

    code: 'Code',
    donormare: 'Donor Merrie',
    donorstal: 'Donor Hengst',
    location: 'locatie & kleur',
    owner: 'Eigenaar',
    transfer: 'Getransfereerd op',
    export: 'Geëxporteerd op',
    import: 'Geïmporteerd op',
    last: 'Laatste facturatie',
    edited: 'Gewijzigd op',
    madeon: 'Aangemaakt op',
  },
  error: {
    title: 'Er liep iets mis...',
    subtitle: 'De pagina waar u naar zocht kon niet gevonden worden.',
    home: 'Home',
  },

  horse: {
    gen: 'Algemene gegevens',
    result: 'Labo-resultaten',
    pass: 'Paspoort',
    embryos: 'Embryos',
    semen: 'Sperma',
    stay: 'Verblijven',
    trans: 'Transacties',
    research: 'Onderzoeksfiches',

    client: 'Klant',
    horse: 'Paard',
    extra: 'Extra',
    amount: 'Aantal',
    status: 'status',
    edit: 'Gewijzigd',
    made: 'Gemaakt',

    code: 'Code',
    donormare: 'Donor Merrie',
    donorstal: 'Donor Hengst',
    location: 'locatie & kleur',
    owner: 'Eigenaar',
    transfer: 'Getransfereerd op',
    export: 'Geëxporteerd op',
    import: 'Geïmporteerd op',
    last: 'Laatste facturatie',
    edited: 'Gewijzigd op',
    madeon: 'Aangemaakt op',
  },

  mare: {
    add: 'toevoegen',
    name: 'Naam',
    type: 'Type',
    nr: 'Brandnummer',
    chip: 'Microchip',
    location: 'Locatie',
    owner: 'Eigenaar',
    surrogate: 'Draagmoeder',
    made: 'Aangemaakt op',
    edit: 'Gewijzigd op',
    birth: 'Geboortedatum',
    filter: 'Filter op locatie',
    show: 'Toon enkel draagmoeders',
    hide: 'Verberg gestorven paarden',
  },

  stallion: {
    add: 'toevoegen',
    name: 'Naam',
    type: 'Type',
    nr: 'Brandnummer',
    chip: 'Microchip',
    location: 'Locatie',
    owner: 'Eigenaar',
    surrogate: 'Draagmoeder',
    made: 'Aangemaakt op',
    edit: 'Gewijzigd op',
    birth: 'Geboortedatum',
    filter: 'Filter op locatie',
    show: 'Toon enkel dekhengsten',
    hide: 'Verberg gestorven paarden',
  },

  stud: {
    add: 'toevoegen',
    name: 'Naam',
    fam: 'Afstamming',
    type: 'Type',
    nr: 'Brandnummer',
    chip: 'Microchip',
    location: 'Locatie',
    owner: 'Eigenaar',
    surrogate: 'Draagmoeder',
    made: 'Aangemaakt op',
    edit: 'Gewijzigd op',
    birth: 'Geboortedatum',
    filter: 'Filter op locatie',
    hide: 'Verberg gestorven paarden',
  },

  surrogate: {
    add: 'toevoegen',
    name: 'Naam',
    embryo: 'Embryo',
    planted: '45 dagen',
    type: 'Type',
    nr: 'Brandnummer',
    chip: 'Microchip',
    location: 'Locatie',
    owner: 'Eigenaar',
    surrogate: 'Draagmoeder',
    made: 'Aangemaakt op',
    edit: 'Gewijzigd op',
    birth: 'Geboortedatum',
    filter: 'Filter op locatie',
    hide: 'Verberg gestorven paarden',
  },
  embryo: {
    code: 'Code',
    donormare: 'Donor Merrie',
    donorstal: 'Donor Hengst',
    location: 'locatie & kleur',
    owner: 'Eigenaar',
    transfer: 'Getransfereerd op',
    export: 'Geëxporteerd op',
    import: 'Geïmporteerd op',
    last: 'Laatste facturatie',
    edited: 'Gewijzigd op',
    madeon: 'Aangemaakt op',
  },
  appliedmed: {
    title: 'Toegediende medicatie',
    type: 'Type',
    prod: 'Product',
    lot: 'Lot nummer',
    horse: 'Paard',
    amount: 'Aantal',
    date: 'Datum',
    range: 'Datum bereik',
  },

  deathhorse: {
    name: 'Naam',
    nr: 'Brandnummer',
    type: 'Type',
    location: 'Locatie',
    owner: 'Eigenaar',
    surrogate: 'Draagmoeder',
    chip: 'Microchip',
    made: 'Aangemaakt op',
    edit: 'Gewijzigd op',
    birth: 'Geboortedatum',
    filtersex: 'Filter op geslacht',
    filterloc: 'Filter op locatie',
    showsur: 'Toon enkel draagmoeders',
    showstal: 'Toon enkel dekhengsten',
  },

  delivprod: {
    title: 'Geleverde producten',
    type: 'Type',
    prod: 'Product',
    lot: 'Lot nummer',
    sup: 'Leverancier',
    amount: 'Aantal',
    date: 'Datum',
    reach: 'Datum bereik',
  },

  gestation: {
    chip: 'Microchip:',
    ueln: 'UELN:',
    salt: 'Zoutleeuw,',
    title: 'Selectie velden',
    sign: 'selecteer ondergetekende',
    lang: 'selecteer een taal',
    back: 'terug',
    print: 'print',
  },
  outprod: {
    title: 'Geleverde producten',
    type: 'Type',
    prod: 'Product',
    lot: 'Lot nummer',
    client: 'Klant',
    amount: 'Aantal',
    date: 'Datum',
    horse: 'Paard',
    filter: 'Filter op modificatie',
    range: 'Datum bereik',
  },

  semenstock: {
    title: 'Stock rapport genereren',
    print: 'print',
    type: 'Filter op type',
    mod: 'Filter op stockmodificatie',
    semen: 'Sperma productie bereik',
    range: 'Modificatie bereik',
  },

  surperloc: {
    name: 'Naam',
    type: 'Type',
    nr: 'Brandnummer',
    chip: 'Microchip',
    location: 'Locatie',
    owner: 'Eigenaar',
    surrogate: 'Draagmoeder',
    made: 'Aangemaakt op',
    edit: 'Gewijzigd op',
    birth: 'Geboortedatum',
    filter: 'Filter op locatie',
    hide: 'Verberg gestorven paarden',
  },

  fatcert: {
    emcode: 'Embryo code',
    embryo: 'Embryo',
    eminfo: 'Embryo info',
    chip: 'Microchip:',
    salt: 'Zoutleeuw,',
    title: 'Selectie velden',
    sign: 'selecteer ondergetekende',
    lang: 'selecteer een taal',
    flush: 'Datum spoeling',
    ai: 'Datum A.I.',
    back: 'terug',
    print: 'print',
  },

  semencreate: {
    title: 'Algemeen',
    lot: 'lot',
    type: 'Type sperma lot *',
    prod: 'Datum productie sperma',
    import: 'Datum import sperma',
    invoice: 'Datum laatste facturatie',
    title2: 'Locatie',
    container: 'Stikstof vat *',
    cooker: 'Koker *',
    pos: 'Positie *',
    straw: 'Aantal rietjes *',
    color: 'Kleur rietjes',
    title3: 'Kwaliteitsparameters',
    conc: 'Concentratie',
    moti: 'Motiliteit',
    prog: 'Progressivieit',
    velo: 'Velocity',
    dose: 'Xpail/dosis',
    via: 'Viabiliteit',
    back: 'terug',
    edit: 'aanpassen',
    save: 'opslaan',
    req: 'Dit veld is verplicht',
  },

  semensee: {
    title: 'Sperma loten',
    add: 'Sperma lot toevoegen',
  },

  labosindex: {
    add: 'Labo-onderzoek toevoegen',
    nlname: 'Onderzoeksnaam - NL',
    enname: 'Onderzoeksnaam - EN',
    frname: 'Onderzoeksnaam - FR',
    account: 'Account',
    price: 'Prijs',
    fixed: 'Vaste prijs',
    tax: 'BTW',
    edit: 'Bewerken',
    filter: 'Filter op BTW',
  },

  laboslabo: {
    semen: 'Voor dit onderzoek wil ik een sperma locatie kunnen selecteren',
    embryo: 'Voor dit onderzoek wil ik een embryo locatie kunnen selecteren',
    horse: 'Voor dit onderzoek wil ik een paard kunnen selecteren',
    sex: 'met als geslacht',
  },

  locdialog: {
    stable: 'Stal',
    prefix: 'Prefix',
    name: 'Naam locatie*',
    amount: 'Aantal plaatsen*',
    cancel: 'Annuleer',
    save: 'Opslaan',
    new: 'Nieuwe locatie',
    edit: 'locatie bewerken',
  },

  locinfo: {
    nodata: 'Geen paarden gevonden',
    close: 'Sluiten',
    name: 'Naam',
    type: 'Type',
    nr: 'Brandnummer',
    chip: 'Microchip',
    owner: 'Eigenaar',
    birth: 'Geboortedatum',
  },

  nitroindex: {
    title: 'Stikstof Vaten',
    edit: 'Container bewerken',
    delete: 'Container verwijderen',
    nodata: 'Geen containers in de database',
    add: 'Vat toevoegen',
    name: 'Naam vat',
    amount: 'Aantal kokers',
    edit2: 'Bewerken',
  },

  nitrodialog: {
    name: 'Naam vat',
    amount: 'Aantal kokers',
    cancel: 'Annuleer',
    save: 'Opslaan',
    new: 'Nieuwe container',
    edit: 'Container bewerken',
  },

  pensionindex: {
    add: 'Pension type toevoegen',
    nlname: 'Pensionnaam - NL',
    enname: 'Pensionnaam - EN',
    frname: 'Pensionnaam - FR',
    account: 'Account',
    price: 'Prijs/dag',
    tax: 'BTW',
    edit: 'Bewerken',
    filter: 'Filter op BTW',
  },

  prodindex: {
    title: 'Producten',
    add: 'product toevoegen',
    name: 'Productnaam',
    type: 'Type',
    cnk: 'CNK',
    dose: 'Aantal/batch',
    unit: 'Eenheid',
    wait: 'Wachttijd (dagen)',
    tax: 'BTW',
    sup: 'Supplement (€)',
    edit: 'Bewerken',
    filter: 'Filter op type',
  },

  protindex: {
    add: 'protocol toevoegen',
    title: 'Protocollen',
    nlname: 'Protocolnaam - NL',
    enname: 'Protocolnaam - EN',
    frname: 'Protocolnaam - FR',
    account: 'Account',
    price: 'Prijs',
    fixed: 'Vaste prijs',
    tax: 'BTW',
    edit: 'Bewerken',
    filter: 'Filter op BTW',
  },

  protoprot: {
    semen: 'Voor dit onderzoek wil ik een sperma locatie kunnen selecteren',
    embryo: 'Voor dit onderzoek wil ik een embryo locatie kunnen selecteren',
    horse: 'Voor dit onderzoek wil ik een paard kunnen selecteren',
    sex: 'met als geslacht',
    client: 'Voor dit protocol wil ik een klant kunnen selecteren',
    amount: 'Voor dit protocol wil ik een specifiekere hoeveelheid ingeven',
    spec: 'specifieke hoeveelheid voor',
  },

  researchsheet: {
    nodata: 'Geen onderzoekfiches gevonden',
    download: 'onderzoeksfiches downloaden',
    title: 'Onderzoeksfiches',
    name: 'Naam',
    nr: 'Brandnummer',
    chip: 'Microchip',
    location: 'Locatie',
    birth: 'Geboortedatum',
    search: 'Zoeken',
  },

  tenant: {
    title: 'Teamleader',
    text: 'Sign-in to your teamleader account to enable sync',
    login: 'Sign in',
  },

  uploadstock: {
    semen: 'Upload sperma stock',
    stay: 'Upload verblijven',
  },

  stocksbatch: {
    details: 'Details',
    lot: 'Loten',
    mods: 'Mods',
    nr: 'lot nummer',
    exp: 'vervaldatum',
    deliv: 'leveringsdatum',
    sup: 'leverancier',
    buy: 'aankoopprijs',
    sell: 'verkoopprijs',
    sellunit: 'verkoopprijs/eenheid',
    ini: 'initieel aantal',
    rest: 'resterend',
    last: 'laatste update',
    new: 'Nieuwe batch',
    edit: 'batch bewerken',
  },
  yearly: {
    back: 'terug',
    download: 'download csv',
  },
};
