<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>{{ $t('horses.horseDetail.form.general.title') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="horse.name"
                  :rules="required"
                  :label="$t('horses.horseDetail.form.general.name')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="horse.type"
                  :rules="required"
                  :items="horseType"
                  :label="$t('horses.horseDetail.form.general.gender')"
                  :disabled="horse.death"
                  :loading="loading"
                  value="Merrie"
                  :menu-props="{ offsetY: true }"
                />
              </v-col>
              <v-col cols="12" md="4">
                <date-picker
                  :date="horse.date_of_birth"
                  :label="$t('horses.horseDetail.form.general.dateOfBirth')"
                  :close-on-input="true"
                  :disabled="horse.death"
                  :required="false"
                  @select-date="horse.date_of_birth = $event"
                />
              </v-col>
              <v-col cols="12" md="4">
                <search-customer
                  :outlined="false"
                  :customer="horse.owner"
                  :disabled="horse.death"
                  :label="$t('horses.horseDetail.form.general.owner')"
                  @emit-customer="updateCustomer"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="horse.ueln"
                  :counter="15"
                  :rules="rules"
                  :label="$t('horses.horseDetail.form.general.ueln')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="horse.microchip"
                  :rules="rules"
                  :counter="15"
                  max-length="15"
                  :label="$t('horses.horseDetail.form.general.chip')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col v-if="horse.stud_horse" cols="12" md="4">
                <search-customer
                  :outlined="false"
                  :customer="horse.stallionOwner"
                  :disabled="horse.death"
                  :label="$t('horses.horseDetail.form.general.stallionOwner')"
                  @emit-customer="updateStallionOwner"
                />
              </v-col>
              <v-slide-x-transition>
                <v-col v-if="horse.stud_horse" cols="12" md="4">
                  <v-text-field
                    v-model="horse.stud_fee"
                    :label="$t('horses.horseDetail.form.general.fee')"
                    :disabled="horse.death"
                    :loading="loading"
                  />
                </v-col>
              </v-slide-x-transition>
              <v-slide-x-transition>
                <v-col v-if="horse.death" cols="12" md="4">
                  <date-picker
                    :date="horse.date_of_death"
                    :label="$t('horses.horseDetail.form.general.deceasedDate')"
                    :close-on-input="true"
                    :required="horse.death"
                    @select-date="horse.date_of_death = $event"
                  />
                </v-col>
              </v-slide-x-transition>
              <v-slide-x-transition>
                <v-col v-if="horse.surrogate" cols="12" md="4">
                  <v-text-field
                    v-model="horse.surrogate_uid"
                    :label="$t('horses.horseDetail.form.general.sorrugateUid')"
                    :disabled="horse.death"
                  />
                </v-col>
              </v-slide-x-transition>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>{{ $t('horses.horseDetail.form.location.title') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-if="!horse._id"
                  v-model="location.stable"
                  :items="stables"
                  :label="$t('horses.horseDetail.form.location.stable')"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                  @input="selectStable"
                  @click:clear="clearStable"
                />
                <v-text-field v-else v-model="horse.location.stable" :label="$t('horses.horseDetail.form.location.stable')" readonly />
              </v-col>
              <v-col cols="12" md="4">
                <v-row v-if="!horse._id">
                  <v-col v-if="showPrefix" cols="12" md="4">
                    <v-select
                      v-model="locationPrefix"
                      :items="prefixes"
                      :label="$t('horses.horseDetail.form.location.prefix')"
                      :loading="loading"
                      :menu-props="{ offsetY: true }"
                      clearable
                      @click:clear="locationPrefix = ''"
                    />
                  </v-col>
                  <v-col cols="12" :md="showPrefix ? 8 : 12">
                    <v-select
                      v-model="location._id"
                      :items="filteredLocationsOnPrefix"
                      item-value="_id"
                      item-text="name"
                      item-disabled="disabled"
                      :suffix="location.disabled ? '(Volzet)' : ''"
                      :label="$t('horses.horseDetail.form.location.location')"
                      :disabled="!location.stable"
                      :menu-props="{ offsetY: true }"
                      return-object
                      clearable
                      @input="selectLocation"
                      @click:clear="clearLocation"
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-else
                  :label="$t('horses.horseDetail.form.location.location')"
                  :value="horse.location.prefix ? `${horse.location.prefix}-${horse.location.name}` : horse.location.name"
                  readonly
                />
              </v-col>
              <v-col v-if="!horse._id" cols="12" md="2" align-self="center" class="d-flex align-center">
                <v-checkbox v-model="billable" :label="$t('horses.horseDetail.form.location.billableLodging')" />
                <v-tooltip bottom :max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon class="ml-3" v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
                  </template>
                  <span>{{ $t('horses.horseDetail.form.location.tooltip') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>{{ $t('horses.horseDetail.form.features.title') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="horse.breed"
                  :rules="required"
                  :items="horseConfig?.breeds"
                  :label="$t('horses.horseDetail.form.features.breed')"
                  :disabled="horse.death"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="horse.studbook"
                  :items="horseConfig?.studbook"
                  :label="$t('horses.horseDetail.form.features.studbook')"
                  :disabled="horse.death"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="horse.coat_color"
                  :items="horseConfig?.colors"
                  :label="$t('horses.horseDetail.form.features.color')"
                  :disabled="horse.death"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="horse.father"
                  :label="$t('horses.horseDetail.form.features.father')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="horse.mother"
                  :label="$t('horses.horseDetail.form.features.mother')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="horse.grandfather"
                  :label="$t('horses.horseDetail.form.features.grandFather')"
                  :disabled="horse.death"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>{{ $t('horses.horseDetail.form.extra.title') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="horse.type === 'Hengst'" cols="12" md="3">
                <v-switch v-model="horse.stud_horse" inset :label="$t('horses.horseDetail.form.extra.stud')" :disabled="horse.death" />
              </v-col>
              <v-col v-if="horse.type === 'Hengst'" cols="12" md="3">
                <v-switch v-model="horse.WFFS_free" inset :label="$t('horses.horseDetail.form.extra.wffsFree')" :disabled="horse.death" />
              </v-col>
              <v-col v-if="horse.type === 'Merrie'" cols="12" md="3">
                <v-switch v-model="horse.surrogate" inset :label="$t('horses.horseDetail.form.extra.surrogate')" :disabled="horse.death" />
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  v-model="horse.keep_for_food_chain"
                  inset
                  :label="$t('horses.horseDetail.form.extra.foodChain')"
                  :disabled="horse.death"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-switch v-model="horse.death" color="red" inset :label="$t('horses.horseDetail.form.extra.deceased')" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-row justify="end" class="gap-4">
              <Button v-if="horse._id" type="delete" :action="() => (deleteDialog = true)">
                {{ $t('common.actions.deleteItem', { item: horse.type }) }}
              </Button>
              <v-spacer />
              <Button type="back" :action="() => $router.back()" />

              <Button
                v-if="horse._id && horse.surrogate && horse.name && horse.date_of_birth && horse.surrogate_uid && horse.microchip"
                :action="() => downloadResearchSheet()"
                type="secondary"
              >
                {{ $t('horses.horseDetail.form.actions.downloadFiche') }}
              </Button>

              <Button :action="() => (horse._id ? updateHorse() : createHorse())" :disabled="!valid">
                {{ $t(`common.actions.${horse._id ? 'editItem' : 'createItem'}`, { item: horse.type }) }}
              </Button>
              <v-dialog v-model="deleteDialog" persistent max-width="350">
                <v-card>
                  <v-card-title class="headline">
                    {{ $t('horses.horseDetail.form.confirmation.title') }}
                  </v-card-title>
                  <v-card-text> {{ $t('horses.horseDetail.form.confirmation.description', { horse: horse.name }) }}</v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <Button :action="() => (deleteDialog = false)" type="cancel" />
                    <Button :action="() => deleteHorse()" type="delete">
                      {{ $t('common.actions.delete') }}
                    </Button>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <snackbar
      :snackbar="snackbar"
      :text="$t('horses.horseDetail.form.confirmation.success')"
      color="success"
      @timeout="snackbar = $event"
    />
  </v-form>
</template>
<script>
import SearchCustomer from '@/components/search/SearchCustomer';
import DatePicker from '@/components/DateSelector/DatePicker';
import { formatDate, required } from '@/Helpers';
import horseAPI from '@/services/HorseAPI.js';
import Snackbar from '@/components/common/Snackbar';
import locationAPI from '@/services/LocationAPI';
import { mapActions, mapGetters } from 'vuex';
import { downloadResearchSheetPDF } from '@/Helpers/research-sheet.helper';
import { getLocationPrefixes } from '@/Helpers/location.helper';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, SearchCustomer, DatePicker, Snackbar },
  props: {
    horse: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    stud: { type: String, default: '' },
    type: { type: String, default: '' },
    surr: { type: String, default: '' },
  },
  data: () => ({
    locationsLoading: false,
    deleteDialog: false,
    snackbar: false,
    valid: false,
    horseType: ['Hengst', 'Merrie'],
    search: null,
    owner: '',
    billable: true,
    lodgings: [],
    rules: [
      (v) => {
        if (v) {
          return v.length <= 15 || 'Max 15 characters';
        }
        return true;
      },
    ],
    locations: [],
    prefixes: [],
    location: {},
    locationPrefix: '',
  }),
  computed: {
    ...mapGetters({
      horseConfig: 'generalConfig/horseConfig',
    }),
    required,
    filteredLocationsOnPrefix() {
      return this.locationPrefix
        ? this.locations.filter((loc) => loc.prefix?.toLowerCase() === this.locationPrefix.toLowerCase())
        : this.locations;
    },
    showPrefix() {
      return this.locations.some((loc) => loc.prefix);
    },
    stables() {
      return this.$store.state.tenantInfo.activeTenant?.stables || [];
    },
  },
  mounted() {
    if (this.type) {
      this.horse.type = this.type;
    }
    if (this.stud) {
      this.horse.stud_horse = this.stud;
    }
    if (this.surr) {
      this.horse.surrogate = this.surr;
    }
  },
  methods: {
    ...mapActions({
      resetError: 'errorMessage/resetError',
      setError: 'errorMessage/setError',
    }),
    formatDate,
    updateCustomer(searchResult) {
      this.horse.owner = searchResult ? searchResult._id : undefined;
    },
    updateStallionOwner(searchResult) {
      this.horse.stallionOwner = searchResult ? searchResult._id : undefined;
    },
    async createHorse() {
      try {
        if (!this.location || !this.location.name || !this.location._id) {
          this.horse.location = undefined;
        } else {
          this.lodgings.push({
            location: this.location._id,
            arrival: new Date(),
            billable: this.billable,
          });
          this.horse.location = this.location;
        }
        this.horse.lodgings = this.lodgings;
        const response = await horseAPI.postHorse(this.horse);

        this.$emit('update-horse', response.data);
        await this.resetError();
        await this.$router.back();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async updateHorse() {
      try {
        if (!this.horse.location || !this.horse.location.name || !this.horse.location._id) {
          this.horse.location = undefined;
        }
        const response = await horseAPI.putHorse(this.horse);
        this.$emit('update-horse', response.data);
        await this.resetError();
        await this.$router.back();
      } catch (err) {
        await this.setError(err.message);
      }
    },
    async deleteHorse() {
      try {
        await horseAPI.deleteHorse(this.horse._id);
        await this.resetError();
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.$router.back();
        this.dialog = false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    downloadResearchSheet() {
      downloadResearchSheetPDF(this.horse.name.replace(/\s+/g, '-').toLowerCase(), [this.horse]);
    },
    selectStable(stable) {
      if (stable) {
        this.location = {
          stable,
        };
        this.getLocations(this.location);
      }
    },
    clearStable() {
      this.locations = [];
      this.location = {};
    },
    selectLocation(location) {
      if (location) {
        this.location = location;
      }
    },
    clearLocation() {
      this.location = {};
    },
    async getLocations({ stable }) {
      this.loadingLocations = true;
      try {
        const {
          data: { locations },
        } = await locationAPI.getLocations({ stable });
        this.locations = locations.map((loc) => ({ ...loc, disabled: loc.horses.length === loc.places }));
        this.prefixes = getLocationPrefixes(this.locations);
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loadingLocations = false;
      }
    },
  },
};
</script>
<style scoped>
#booked-wrapper {
  color: #7f7f7f;
}
#booked {
  font-size: 0.75rem;
  margin: 0 0 0 5px;
}
</style>
