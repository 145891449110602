export const cases = {
  add: 'Add case',
  title: 'Cases',
  table: {
    headers: {
      ref: 'Ref',
      horse: 'Horse',
      client: 'Client',
      extra: 'Extra',
      activity: 'Main activity',
      amount: 'Amount',
      status: 'Status',
      updatedOn: 'Edited',
      createdAt: 'Created',
    },
  },
  search: 'Search by reference or horse name',
  filters: {
    status: 'Filter on status',
    activity: 'Filter on activity',
    hideSurrogates: 'Hide surrogates',
    onlySurrogates: 'Only surrogates',
  },
  dialog: {
    title: 'Create case',
    labels: {
      activity: 'Main activity',
    },
  },
  invoices: {
    overview: {
      title: 'Invoice overview',
      customer: 'Customer',
      handledDates: 'Handled research dates',
      mainActivity: 'Main activity',
      billHousing: 'Bill housing costs',
      invoice: 'Invoice',
      flyoutTable: {
        headers: {
          description: 'Description',
          amount: 'Amount',
          pricePerUnit: 'Price Per Unit',
          vat: 'VAT %',
          totalNoVAT: 'Total (excl VAT)',
        },
        totals: {
          totalNoVAT: 'Total excl VAT',
          vatTotal: 'VAT amount',
          total: 'Total',
        },
        groups: {
          products: 'Products',
          protocols: 'Protocols',
          labos: 'Labs',
          pension: 'Lodgings',
          'embryo storage': 'Embryo storage',
          'semen storage': 'Semen storage',
        },
      },
    },
  },
};
