<template>
  <div>
    <v-form ref="form">
      <template v-if="!horse.passport">
        <v-row>
          <v-col cols="8" md="4">
            <v-file-input v-model="file" show-size label="Paspoort" />
          </v-col>
          <v-col>
            <v-btn :loading="loading" color="success" depressed class="mt-3" :disabled="!file" @click="createPassport">
              {{ $t('passport.upload') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-btn color="primary" class="ma-2 white--text" target="_blank" @click="openFile">
            {{ $t('passport.see') }}
            <v-icon right dark> mdi-passport </v-icon>
          </v-btn>
          <v-btn color="warning" class="ma-2 white--text" target="_blank" @click="deletePassport()">
            {{ $t('passport.delete') }}
          </v-btn>
        </v-row>
      </template>
    </v-form>
  </div>
</template>
<script>
import horseAPI from '@/services/HorseAPI.js';

export default {
  props: {
    horse: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      file: null,
      url: '',
      baseURL: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    passportLink() {
      return `${this.baseURL}${this.horse.passport}`;
    },
  },
  methods: {
    async openFile() {
      try {
        const {
          data: { link },
        } = await horseAPI.getPdfUrl(this.horse._id, { filename: 'passport.pdf', passport: true });
        window.open(link, '_blank');
      } catch (err) {
        console.log(err);
      }
    },
    async createPassport() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        const { data } = await horseAPI.postPassport(this.horse._id, formData);
        this.$emit('update-passport', data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async deletePassport() {
      try {
        const { data } = await horseAPI.deletePassport(this.horse);
        this.$emit('update-passport', data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
