
import Vue, { PropType } from 'vue';
import { ownerName, formatDate } from '@/Helpers';
import Tag from '@/components/common/Tag.vue';
import i18n from '@/translations/i18n';
import { IHorse } from '@/models/horse';

interface ICoveredMaresRecord {
  stallion: IHorse;
  coveredMares: {
    horse: IHorse;
    date: string;
  };
}

export default Vue.extend({
  name: 'CoveredMaresTable',
  components: { Tag },
  props: {
    horses: { type: Array as PropType<ICoveredMaresRecord[]>, default: () => [] as ICoveredMaresRecord[] },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    headers: [
      { text: '', value: 'icon', sortable: false, width: '20px' },
      { text: i18n.t('coveredmares.name'), value: 'name', sortable: false },
      { text: i18n.t('coveredmares.type'), sortable: false },
      { text: i18n.t('coveredmares.cover'), sortable: false },
      { text: i18n.t('coveredmares.ueln'), sortable: false },
      { text: i18n.t('coveredmares.chip'), sortable: false },
      { text: i18n.t('coveredmares.owner'), sortable: false },
      { text: i18n.t('coveredmares.stallion'), sortable: false },
    ],
    expandedHorses: {},
  }),
  computed: {
    allHorsesExpanded(): boolean {
      return this.horses.length && this.horses.every((horse) => this.expandedHorses[horse.stallion._id]);
    },
  },
  watch: {
    horses(newHorses: ICoveredMaresRecord[], oldHorses: ICoveredMaresRecord[]) {
      if (!oldHorses?.length || oldHorses.every((old) => this.expandedHorses[old.stallion._id])) {
        // All are expanded so expand all newHorses and initial expand
        newHorses.forEach((record) => Vue.set(this.expandedHorses, record.stallion._id, true));
      }
    },
  },
  methods: {
    ownerName,
    formatDate,
    toggleExpand(id: string) {
      if (this.expandedHorses[id]) {
        Vue.delete(this.expandedHorses, id);
      } else {
        Vue.set(this.expandedHorses, id, true);
      }
    },
    toggleAll() {
      if (this.allHorsesExpanded) {
        this.horses.forEach((horse) => Vue.delete(this.expandedHorses, horse.stallion._id));
      } else {
        this.horses.forEach((horse) => Vue.set(this.expandedHorses, horse.stallion._id, true));
      }
    },
  },
});
