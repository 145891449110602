export const config = {
  locations: {
    title: 'Locaties',
    add: 'Locatie toevoegen',
    table: {
      headers: {
        stable: 'Stal',
        name: 'Naam locatie',
        places: '# Plaatsen',
        amountOfHorses: '# Paarden',
      },
      tooltips: {
        delete: 'Locatie verwijderen',
        edit: 'Locatie bewerken',
        presentHorses: 'Aanwezige paarden',
      },
    },
  },
};
