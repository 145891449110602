<template>
  <embryo-table :id="$route.params.id" :headers="headers" :fetch="fetchEmbryos" />
</template>

<script>
import EmbryoTable from '@/components/icsi/EmbryoTable';
import i18n from '@/translations/i18n';
import { icsiAPI } from '@/services';

export default {
  components: {
    EmbryoTable,
  },
  data: () => ({
    headers: [
      { text: i18n.t('embryo.code'), value: 'code', align: 'left', selected: true },
      { text: i18n.t('embryo.donormare'), value: 'donor_mare', align: 'left', selected: false },
      { text: i18n.t('embryo.donorstal'), value: 'donor_stallion', align: 'left', selected: false },
      { text: i18n.t('embryo.location'), value: 'location', selected: true },
      { text: i18n.t('embryo.owner'), value: 'owner', selected: true },
      { text: i18n.t('embryo.transfer'), align: 'end', value: 'transferDate', selected: false },
      { text: i18n.t('embryo.export'), align: 'end', value: 'exportDate', selected: false },
      { text: i18n.t('embryo.import'), align: 'end', value: 'importedAt', selected: false },
      { text: i18n.t('embryo.last'), align: 'end', value: 'lastInvoiceDate', selected: false },
      { text: i18n.t('embryo.edited'), align: 'end', value: 'updatedAt', selected: false },
      { text: i18n.t('embryo.madeon'), align: 'end', value: 'createdAt', selected: false },
    ],
  }),
  methods: {
    fetchEmbryos(URLparams) {
      return icsiAPI.getAllEmbryos({ ...URLparams, batch_id: this.$route.params.id });
    },
  },
};
</script>
