<template>
  <v-card outlined class="ma-5" :class="products.length ? 'pb-10' : ''">
    <v-toolbar flat dense light>
      <v-toolbar-title>{{ $t('addproduct.add') }}</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-row class="ma-5 ml-8">
      <v-col cols="12" md="6">
        <search-product :dense="true" @emit-product="addProduct" />
      </v-col>
    </v-row>
    <v-row v-for="(prod, i) in products" :key="i" class="mx-5">
      <template v-if="prod.product">
        <v-col cols="1" class="mt-1">
          <v-btn color="error" icon @click="removeProduct(i)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="7" md="5" class="py-0">
          <v-text-field v-model="prod.product.name" hide-details disabled />
        </v-col>
        <v-col cols="2" md="1" class="py-0">
          <v-text-field v-model="prod.amount" class="centered-input" type="number" hide-details />
        </v-col>
        <v-col cols="2" class="py-0">
          <v-text-field v-model="prod.product.outgoingUnit" class="centered-input" readonly hide-details />
        </v-col>
      </template>
      <template v-if="!prod.product">
        <v-col cols="1" class="py-0 d-flex justify-center">
          <v-btn color="error" icon @click="removeProduct(i)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="7">
          <p class="text-center grey--text">
            {{ $t('addproduct.id') }} <b>{{ prod._id }}</b> {{ $t('addproduct.gone') }}
          </p>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import SearchProduct from '@/components/search/SearchProduct';

export default {
  name: 'AddProduct',
  components: { SearchProduct },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    addProduct(item) {
      const product = { product: item, amount: 0 };
      this.products.push(product);
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
