
import Vue from 'vue';
import { formatDate, compareRouteAndPush, ownerName, horseName } from '@/Helpers';
import PrintHeader from '@/components/common/PrintHeader.vue';
import Filters from '@/components/filters/Filters.vue';
import StallionTransactionSum from '@/components/horse/StallionTransactionSum.vue';
import MareTransactionSum from '@/components/horse/MareTransactionSum.vue';
import TransactionAPI from '@/services/TransactionAPI';
import { YearlyReportType, YearlyReportAction } from '@/consts/yearlyReport/yearlyReport.consts';
import format from 'date-fns/format';
import startOfYear from 'date-fns/startOfYear';
import { IDateRange } from '@/models/general';
import { DataTableHeader } from 'vuetify';
import i18n from '@/translations/i18n';

export enum TransactionTableType {
  STALLION = 'Stallion',
  MARE = 'Mare',
}

export default Vue.extend({
  components: {
    PrintHeader,
    Filters,
    StallionTransactionSum,
    MareTransactionSum,
  },
  props: {
    horse: { type: Object, required: true },
  },
  data() {
    return {
      title: 'Transactie rapport',
      loading: false,
      transactions: [],
      totalTransactions: 0,
      options: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: Number(this.$route.query.limit) || 15,
        sortBy: ['date'],
        sortDesc: [false],
      },
      transactionTableTypes: TransactionTableType,
    };
  },
  computed: {
    filters(): { [key: string]: any } {
      return {
        reportTypes: {
          vTag: 'select',
          label: 'Filter op type',
          items:
            this.type === TransactionTableType.STALLION
              ? [YearlyReportType.FRESH_SEMEN, YearlyReportType.FROZEN_SEMEN]
              : [YearlyReportType.OVUM, YearlyReportType.RINSED_EMBRYO, YearlyReportType.ICSI_EMBRYO],
          options: { multiple: true },
        },
        reportActions: {
          vTag: 'select',
          label: i18n.t('transtable.filteraction'),
          items:
            this.type === TransactionTableType.STALLION
              ? [
                  YearlyReportAction.GAIN,
                  YearlyReportAction.USE,
                  YearlyReportAction.EXPORT,
                  YearlyReportAction.IMPORT,
                  YearlyReportAction.SENT,
                ]
              : [
                  YearlyReportAction.GAIN,
                  YearlyReportAction.USE,
                  YearlyReportAction.EXPORT,
                  YearlyReportAction.FROZEN,
                  YearlyReportAction.IMPLANTED,
                  YearlyReportAction.PREGNANT,
                ],
          options: { multiple: true },
        },
        dateRange: {
          vTag: 'dateRange',
          label: i18n.t('transtable.range'),
          from: 'from',
          to: 'to',
        },
      };
    },
    headers(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        { text: i18n.t('transtable.type') as string, value: 'type', sortable: false },
        { text: i18n.t('transtable.action') as string, value: 'action', sortable: false },
        { text: i18n.t('transtable.in') as string, value: 'in', sortable: false },
        { text: i18n.t('transtable.out') as string, value: 'out', sortable: false },
      ];
      if (this.type === TransactionTableType.STALLION) {
        headers.push({ text: i18n.t('transtable.dest') as string, value: 'destination', sortable: false });
      }
      headers.push({ text: i18n.t('date') as string, value: 'date' });

      return headers;
    },
    dateRange(): IDateRange {
      return {
        from: this.$route.query.from as string,
        to: this.$route.query.to as string,
      };
    },
    type(): TransactionTableType {
      if (this.horse) {
        if (this.horse.type === 'Hengst') {
          return TransactionTableType.STALLION;
        }
        if (this.horse.type === 'Merrie') {
          return TransactionTableType.MARE;
        }
      }
      return undefined;
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
  },
  async mounted() {
    this.loading = true;
    if (!this.$route.query.from) {
      this.$router.replace({
        query: { ...this.$route.query, from: format(startOfYear(new Date()), 'yyyy-MM-dd') },
      });
    }
    const result = await this.fetchTransactions(this.$route.query);
    this.loading = false;
    this.transactions = result?.data?.results;
    this.totalTransactions = result?.data?.total;
  },
  methods: {
    formatDate,
    fetchTransactions(URLparams): Promise<any> {
      switch (this.type) {
        case TransactionTableType.STALLION:
          return TransactionAPI.getStallionTransactions({ ...URLparams, horseId: this.horse._id });
        case TransactionTableType.MARE:
          return TransactionAPI.getMareTransactions({ ...URLparams, horseId: this.horse._id });
        default:
          return undefined;
      }
    },
    isInAction(action: YearlyReportAction): boolean {
      return [YearlyReportAction.GAIN, YearlyReportAction.IMPORT].includes(action);
    },
    isOutAction(action: YearlyReportAction): boolean {
      return [YearlyReportAction.USE, YearlyReportAction.EXPORT, YearlyReportAction.SENT].includes(action);
    },
    destinationText(transaction): string {
      if (!this.isOutAction(transaction.action)) {
        return '-';
      }
      if (transaction.destinationHorse && transaction.customer) {
        return `${ownerName(transaction.customer)} - ${horseName(transaction.destinationHorse)}`;
      }
      if (transaction.customer) {
        return ownerName(transaction.customer);
      }
      if (transaction.destinationHorse) {
        return horseName(transaction.destinationHorse);
      }
      return '-';
    },
  },
});
