<template>
  <horse-table :headers="headers" :filters="filters" />
</template>
<script>
import horseTable from '@/components/horse/Table';
import { displayLocation } from '@/Helpers/location.helper';
import i18n from '@/translations/i18n';
import { locationAPI } from '@/services';

export default {
  components: {
    horseTable,
  },
  data: () => ({
    locations: [],
    headers: [
      { text: i18n.t('deathhorse.name'), value: 'name', selected: true },
      { text: i18n.t('deathhorse.nr'), value: 'surrogate_uid', sortable: false, selected: false },
      { text: i18n.t('deathhorse.type'), value: 'type', sortable: false, selected: true },
      { text: i18n.t('deathhorse.location'), value: 'location', selected: true },
      { text: i18n.t('deathhorse.owner'), value: 'owner', selected: true },
      { text: i18n.t('deathhorse.surrogate'), value: 'surrogate', selected: false },
      { text: i18n.t('deathhorse.chip'), value: 'microchip', selected: false },
      { text: i18n.t('deathhorse.made'), align: 'end', value: 'createdAt', selected: false },
      { text: i18n.t('deathhorse.edit'), align: 'end', value: 'updatedAt', selected: true },
      { text: i18n.t('deathhorse.birth'), align: 'end', value: 'date_of_birth', selected: false },
    ],
    sortBy: 'updatedAt',
  }),
  computed: {
    filters() {
      return {
        type: {
          vTag: 'select',
          label: i18n.t('deathhorse.filtersex'),
          items: ['Merrie', 'Hengst'],
        },
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: i18n.t('deathhorse.filterloc'),
          items: this.locations,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
        },
        surrogate: {
          vTag: 'switch',
          label: i18n.t('deathhorse.showsur'),
          hideIf: {
            target: 'stud_horse',
            condition: 'true',
          },
        },
        stud_horse: {
          vTag: 'switch',
          label: i18n.t('deathhorse.showstal'),
          hideIf: {
            target: 'surrogate',
            condition: 'true',
          },
        },
      };
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    async getLocations() {
      try {
        const {
          data: { locations },
        } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
