<template>
  <v-layout column fill-height justify-space-between>
    <cases-table title="cases" :headers="headers" />
    <v-btn style="width: fit-content; align-self: flex-end" color="primary" class="mr-5 mb-5" depressed @click="newCaseDialog = true">
      {{ $t('cases.add') }}
      <v-icon right> mdi-plus </v-icon>
    </v-btn>
    <new-case-dialog :new-case-dialog="newCaseDialog" @toggle-dialog="newCaseDialog = $event" />
  </v-layout>
</template>

<script>
import CasesTable from '@/components/case/Table';
import newCaseDialog from '@/components/case/CreateDialog';
import i18n from '@/translations/i18n';

export default {
  components: { CasesTable, newCaseDialog },
  data: () => ({
    headers: [
      { text: i18n.t('cases.table.headers.ref'), value: 'reference', selected: true },
      { text: i18n.t('cases.table.headers.horse'), value: 'horse', selected: true },
      { text: i18n.t('cases.table.headers.client'), value: 'customer', selected: true },
      { text: i18n.t('cases.table.headers.extra'), value: 'extra', selected: true },
      { text: i18n.t('cases.table.headers.activity'), value: 'mainActivity', selected: true },
      { text: i18n.t('cases.table.headers.amount'), value: 'length', selected: true, sort: false },
      { text: i18n.t('cases.table.headers.status'), value: 'status', selected: true, width: '150', align: 'end' },
      { text: i18n.t('cases.table.headers.updatedOn'), value: 'updatedAt', selected: false },
      { text: i18n.t('cases.table.headers.createdAt'), value: 'createdAt', align: 'end', selected: true },
    ],
    newCaseDialog: false,
  }),
};
</script>
