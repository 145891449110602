
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { invoiceAPI, teamleaderAPI } from '@/services';
import PreviewOverview from '@/components/researchSheet/invoicePreview/PreviewOverview.vue';

export default Vue.extend({
  data: () => ({
    loading: false,
    groupedLines: [],
    note: '',
    caseInfo: {},
    fetchHousingCost: false,
  }),
  computed: {
    ...mapGetters({
      caseId: 'invoices/getCaseId',
      selectedItems: 'invoices/getSelectedItems',
    }),
  },
  beforeMount() {
    if (this.caseId && !this.groupedLines.length) {
      this.getToInvoiceCaseById();
    }
  },
  watch: {
    fetchHousingCost: {
      handler() {
        this.getToInvoiceCaseById();
      },
    },
  },
  methods: {
    async getToInvoiceCaseById() {
      this.loading = true;
      try {
        const { data } = await invoiceAPI.getInvoicePreview(this.caseId, {
          ...this.selectedItems,
          billHousingCost: this.fetchHousingCost,
        });
        this.groupedLines = data.grouped_lines;
        this.note = data.note;
        this.caseInfo = data.caseInfo;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async postInvoice() {
      try {
        await teamleaderAPI.postInvoice(this.caseId, { ...this.selectedItems, groupLines: this.groupedLines, note: this.note, billHousingCost: this.fetchHousingCost });
        await this.$router.push(`/case/${this.caseId}`);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
  components: { PreviewOverview },
});
