
import Vue from 'vue';
import { ownerName, formatDate, horseName } from '@/Helpers';
import transactionAPI from '@/services/TransactionAPI';
import SearchCustomer from '@/components/search/SearchCustomer.vue';
import i18n from '@/translations/i18n';

export default Vue.extend({
  name: 'TransactionPerStallionOwnerCard',
  components: { SearchCustomer },
  data: () => ({
    years: [],
    selectedYear: 0,
    loading: false,
    transactions: [],
    stallionOwnerId: '',
    options: {
      page: 1,
    },
    headers: [
      { text: i18n.t('transactionper.stallion'), value: 'stallionOwner', sortable: false },
      { text: i18n.t('transactionper.horse'), value: 'name', sortable: false },
      { text: i18n.t('transactionper.type'), value: 'type', sortable: false },
      { text: i18n.t('transactionper.action'), value: 'action', sortable: false },
      { text: i18n.t('transactionper.date'), value: 'date', sortable: false },
      { text: i18n.t('transactionper.destination'), value: 'destination', sortable: false },
    ],
  }),
  watch: {
    selectedYear: {
      handler() {
        if (this.$route.query.year !== this.selectedYear.toString()) {
          this.$router.replace({
            query: { ...this.$route.query, year: this.selectedYear.toString() },
          });
        }

        this.getTransactions(this.selectedYear, this.stallionOwnerId);

        this.options.page = 1;
      },
    },
    stallionOwnerId: {
      handler() {
        if (this.$route.query.stallionOwnerId !== this.stallionOwnerId) {
          this.$router.replace({
            query: { ...this.$route.query, stallionOwnerId: this.stallionOwnerId },
          });
        }

        this.getTransactions(this.selectedYear, this.stallionOwnerId);

        this.options.page = 1;
      },
    },
  },
  beforeMount() {
    this.getYears();
    if (this.$route.query.stallionOwnerId) {
      this.stallionOwnerId = this.$route.query.stallionOwnerId.toString();
    }
  },
  methods: {
    ownerName,
    formatDate,
    horseName,
    async getYears() {
      this.loading = true;
      try {
        const { data } = await transactionAPI.getYears();
        this.years = data;
        const [first] = data;
        this.selectedYear = first;
        await this.getTransactions(this.selectedYear, this.stallionOwnerId);
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async getTransactions(year: number, stallionOwnerId: string = '') {
      this.loading = true;
      try {
        const { data } = await transactionAPI.getTransactionsPerStallionOwner({ year, stallionOwnerId });
        this.transactions = data;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
    updateStallionOwner(searchResult) {
      this.stallionOwnerId = searchResult?._id;
    },
  },
});
