<template>
  <div>
    <v-toolbar flat>
      <v-spacer />
      <v-col>
        <search-customer
          :customer-props="customerProps"
          :customer="customer"
          :clearable="true"
          :outlined="true"
          :dense="true"
          :type="$route.query.type"
          :show-t-v-a="true"
          @emit-customer="updateSearch"
        />
      </v-col>
      <sync-button v-if="activeTenant.integrations.teamleader" :type="$route.query.type" :exec="syncCustomers" />
      <v-btn v-else style="width: fit-content" color="primary" class="ml-1" depressed @click="openCustomerPage()">
        {{ $t('customers.add') }}
        <v-icon right dark> mdi-plus </v-icon>
      </v-btn>
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      :headers="filteredHeaders"
      :items="customers"
      :server-items-length="totalCustomers"
      :options.sync="options"
      :loading="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading-text="$t('common.loading')"
      class="ma-5"
    >
      <template #no-data>
        {{ $t('common.noData') }}
      </template>
      <template #item="props">
        <tr class="clickable" @click="openCustomerPage(props.item._id)">
          <td v-if="showColumn('company')">
            <span v-if="props.item.company">{{ props.item.company | capitalize }}</span>
            <span v-else class="grey--text"
              ><em>{{ $t('customertable.column') }}</em></span
            >
          </td>
          <td v-if="$route.query.type === 'bedrijf' && showColumn('tva')">
            {{ props.item.tva || '-' }}
          </td>
          <td v-if="showColumn('first_name')">
            {{ props.item.first_name | capitalize }}
          </td>
          <td v-if="showColumn('last_name')">
            {{ props.item.last_name | capitalize }}
          </td>
          <td v-if="showColumn('createdAt')" class="text-right">
            {{ formatDate(props.item.createdAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SearchCustomer from '@/components/search/SearchCustomer';
import { formatDate, compareRouteAndPush } from '@/Helpers';
import { teamleaderAPI, customerAPI } from '@/services';
import SyncButton from '@/components/common/SyncButton';
import Columns from '@/components/filters/Columns';

export default {
  components: { Columns, SearchCustomer, SyncButton },
  props: {
    headers: { type: Array, required: true },
    type: { type: String, required: true },
  },
  data: (data) => ({
    loading: true,
    customers: [],
    totalCustomers: 0,
    customer: undefined,
    sortBy: 'last_name',
    sortDesc: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    filteredHeaders: [],
  }),
  computed: {
    customerProps() {
      if (this.$route.query.first_name && this.$route.query.last_name) {
        return {
          first_name: this.$route.query.first_name,
          last_name: this.$route.query.last_name,
        };
      }
      if (this.$route.query.company) {
        return { company: this.$route.query.company };
      }
      return undefined;
    },
    activeTenant() {
      return this.$store.state.tenantInfo.activeTenant;
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
    '$route.query': {
      deep: true,
      handler() {
        if (!this.$route.query.page && !this.$route.query.limit) {
          this.$router.replace({
            query: {
              page: 1,
              limit: this.options.itemsPerPage,
              sortBy: this.options.sortBy,
              sortDesc: this.options.sortDesc,
              ...this.$route.query,
            },
          });
        } else {
          if (this.$route.query.type === this.type) {
            this.loadCustomers();
          }
        }
      },
    },
  },
  methods: {
    formatDate,
    compareRouteAndPush,
    showColumn(col) {
      return this.headers.find((header) => header.value === col) ? this.headers.find((header) => header.value === col).selected : undefined;
    },
    openCustomerPage(id) {
      this.$router.push(`/customer/${id}`);
    },
    updateSearch(customer) {
      this.customer = customer;
      if (!customer) {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            first_name: undefined,
            last_name: undefined,
            company: undefined,
            page: 1,
          },
          this.$route,
          this.$router
        );
      }
      if (customer && this.$route.query.type === 'particulier') {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            first_name: customer.first_name,
            last_name: customer.last_name,
            page: 1,
          },
          this.$route,
          this.$router
        );
      }
      if (customer && this.$route.query.type === 'bedrijf') {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            company: customer.company,
            page: 1,
          },
          this.$route,
          this.$router
        );
      }
    },
    async loadCustomers() {
      try {
        const {
          data: { customers, total },
        } = await customerAPI.getCustomers(this.$route.query);
        this.customers = customers;
        this.totalCustomers = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async syncCustomers() {
      await teamleaderAPI.syncCustomers(this.$route.query.type === 'particulier' ? 'contacts' : 'companies');
      await this.loadCustomers();
    },
  },
};
</script>
