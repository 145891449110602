<template>
  <v-card flat class="light-grey">
    <v-form ref="form" v-model="valid">
      <v-card outlined class="ma-5">
        <v-toolbar flat dense light>
          <v-toolbar-title>{{ $t('protocolform.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <v-text-field v-model="protocol.name" :rules="required" :label="$t('protocolform.nlname')" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="protocol.translations.en" :label="$t('protocolform.enname')" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="protocol.translations.fr" :label="$t('protocolform.frname')" />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <v-select
              v-model="protocol.account"
              :menu-props="{ offsetY: true }"
              :items="accounts"
              :item-text="accountName"
              item-value="_id"
              :label="$t('protocolform.account')"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="protocol.tax"
              :rules="required"
              :items="taxes"
              :label="$t('protocolform.tax')"
              :menu-props="{ offsetY: true }"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="protocol.price"
              :rules="required"
              type="number"
              :label="$t('protocolform.price')"
              prefix="€"
              placeholder="0.00"
            />
          </v-col>
        </v-row>
        <v-row v-if="protocol.hasCustomDescription" class="ma-5">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="protocol.invoiceDescription"
              :label="$t('protocolform.invoice')"
              placeholder="Vul hier uw standaard factuur beschrijving in"
            />
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col cols="12" md="4">
            <v-switch v-model="protocol.fixedPrice" inset :label="$t('protocolform.pricefix')" />
          </v-col>
          <v-col cols="12" md="4">
            <v-switch v-model="configTab" inset :label="$t('protocolform.config')" @change="$emit('change-config', configTab)" />
          </v-col>
          <v-col cols="12" md="4">
            <v-switch v-model="protocol.hasCustomDescription" inset :label="$t('protocolform.custom')" />
          </v-col>
        </v-row>
      </v-card>
      <add-product :products="protocol.products" />
      <advanced-config
        :notifications="protocol.notifications"
        :parameters="protocol.parameters"
        :simple-parameters="protocol.simple_parameters"
        :parameter-options="parameterOptions"
        :simple-parameter-options="simpleParameterOptions"
        :config="config"
        :report-type="protocol.reportType"
        :transaction-config.sync="protocol.transactionConfig"
        @select-type="protocol.reportType = $event"
      />
    </v-form>
    <div class="mr-2 mb-5">
      <v-row justify="end" class="gap-4" dense>
        <Button type="back" :action="() => $router.back()" />

        <v-btn v-if="!protocol._id" :disabled="!valid" color="success" depressed @click="createProtocol">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('protocolform.save') }}
        </v-btn>
        <v-btn v-if="protocol._id" :disabled="!valid" color="success" depressed @click="editProtocol">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('protocolform.edit') }}
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { accountName, required } from '@/Helpers';
import { protocolAPI, configAPI, accountAPI } from '@/services';
import AddProduct from '@/components/common/AddProduct';
import AdvancedConfig from '@/components/extraConfig/AdvancedConfig';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, AddProduct, AdvancedConfig },
  props: {
    protocol: { type: Object, required: true },
    parameterOptions: { type: Array, required: true },
    simpleParameterOptions: { type: Array, required: true },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    config: { type: Boolean },
  },
  data: () => ({
    valid: false,
    taxes: [],
    products: [],
    accounts: [],
    configTab: false,
  }),
  computed: { required },
  watch: {
    config: {
      handler() {
        this.configTab = this.config;
      },
    },
  },
  created() {
    Promise.all([this.getProductConfig(), this.getAccounts()]);
  },
  methods: {
    accountName,
    async protocolHandler(data) {
      this.$emit('update-protocol', data);
      await this.$router.back();
    },
    async createProtocol() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await protocolAPI.postProtocol(this.protocol);
        await this.protocolHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async editProtocol() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await protocolAPI.putProtocol(this.protocol);
        await this.protocolHandler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getProductConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { tax },
        } = await configAPI.getProductConfig();
        this.taxes = tax;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getAccounts() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { results },
        } = await accountAPI.getAllAccounts({ 'sortBy[]': 'number', 'sortDesc[]': false });
        this.accounts = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
