<template>
  <v-card class="ma-5" outlined>
    <v-toolbar color="primary" flat dark>
      <v-toolbar-title>{{ capitalize(ownerName(customer)) }}</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="tab">
      <v-tab key="general" href="#general" class="d-print-none">
        <v-icon left> mdi-book-open-variant </v-icon>
        {{ $t('customer.gen') }}
      </v-tab>
      <v-tab v-if="customer.type === 'bedrijf'" key="contacts" href="#contacts" :disabled="checkId" class="d-print-none">
        <v-icon left> mdi-account-box </v-icon>
        {{ $t('customer.contacts') }}
      </v-tab>
      <v-tab key="horses" :disabled="checkId" href="#horses" class="d-print-none">
        <v-icon left> mdi-horseshoe </v-icon>
        {{ $t('customer.horses') }}
      </v-tab>
      <v-tab key="embryos" :disabled="checkId" class="d-print-none" href="#embryos">
        <v-icon left> mdi-sprout </v-icon>
        {{ $t('customer.embryos') }}
      </v-tab>
      <v-tab key="semen" href="#semen" :disabled="checkId" class="d-print-none">
        <v-icon left> mdi-water </v-icon>
        {{ $t('customer.semen') }}
      </v-tab>
      <v-tab key="research" href="#research" :disabled="checkId" class="d-print-none">
        <v-icon left> mdi-stethoscope </v-icon>
        {{ $t('customer.research') }}
      </v-tab>
      <v-tab-item value="general" class="ma-5">
        <v-card flat>
          <customer-form :customer="customer" :loading="loading" @update-customer="updateCustomer" />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="customer.type === 'bedrijf'" value="contacts" class="ma-5">
        <v-card flat>
          <customer-contacts :customer="customer" />
        </v-card>
      </v-tab-item>
      <v-tab-item value="horses" class="ma-5">
        <v-card flat>
          <customer-horses :customer="$route.params.id" />
        </v-card>
      </v-tab-item>
      <v-tab-item value="embryos">
        <embryo-table
          :customer-id="$route.params.id"
          :tab="true"
          :show-donors="true"
          :sort-by="['donor_mare', 'donor_stallion', 'code']"
          :sort-desc="[false, false, false]"
          :headers="embryoHeaders"
          :fetch="fetchEmbryos"
        />
      </v-tab-item>
      <v-tab-item value="semen">
        <semen-collection :customer="$route.params.id" :tab="true" />
      </v-tab-item>
      <v-tab-item value="research" class="ma-5">
        <v-card flat>
          <expandable-table
            :headers="headers"
            :customer="customer._id"
            :cases="cases"
            :total-cases="totalCases"
            :loading="loading"
            :options="options"
            @update-options="options = $event"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { ownerName, capitalize, compareRouteAndPush } from '@/Helpers';
import ExpandableTable from '@/components/case/ExpandableTable';
import customerAPI from '@/services/CustomerAPI.js';
import CustomerForm from '@/components/customer/Form';
import CustomerHorses from '@/components/customer/Horses';
import CustomerContacts from '@/components/customer/Contacts';
import EmbryoTable from '@/components/icsi/EmbryoTable';
import SemenCollection from '@/components/semenCollection/SemenTable';
import { CaseAPI, icsiAPI } from '../../services';
import i18n from '@/translations/i18n';

export default {
  components: { SemenCollection, EmbryoTable, CustomerForm, CustomerHorses, CustomerContacts, ExpandableTable },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data: (data) => ({
    customer: {},
    loading: false,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    cases: [],
    totalCases: 0,
    headers: [
      { text: i18n.t('customer.client'), value: 'customer', selected: true },
      { text: i18n.t('customer.horse'), value: 'horse', selected: true },
      { text: i18n.t('customer.extra'), value: 'stallion', selected: true },
      { text: i18n.t('customer.amount'), value: 'length', selected: true },
      { text: i18n.t('customer.status'), value: 'status', selected: true, width: '150', align: 'end' },
      { text: i18n.t('customer.edit'), value: 'updatedAt', selected: false },
      { text: i18n.t('customer.made'), value: 'createdAt', align: 'end', selected: true },
    ],
    embryoHeaders: [
      { text: i18n.t('customer.code'), value: 'code', align: 'left', selected: true },
      { text: i18n.t('customer.donormare'), value: 'donor_mare', align: 'left', selected: true },
      { text: i18n.t('customer.donorstal'), value: 'donor_stallion', align: 'left', selected: true },
      { text: i18n.t('customer.location'), value: 'location', selected: true },
      { text: i18n.t('customer.owner'), value: 'owner', selected: true },
      { text: i18n.t('customer.transfer'), align: 'end', value: 'transferDate', selected: false },
      { text: i18n.t('customer.export'), align: 'end', value: 'exportDate', selected: false },
      { text: i18n.t('customer.import'), align: 'end', value: 'importedAt', selected: false },
      { text: i18n.t('customer.last'), align: 'end', value: 'lastInvoiceDate', selected: false },
      { text: i18n.t('customer.edited'), align: 'end', value: 'updatedAt', selected: false },
      { text: i18n.t('customer.madeon'), align: 'end', value: 'createdAt', selected: false },
    ],
  }),
  computed: {
    checkId() {
      return this.id === 'undefined';
    },
    tab: {
      set(tab) {
        if (this.$route.query.tab !== tab) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              hideEmpty: tab === 'semen' ? true : undefined,
              tab,
            },
          });
        }
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  watch: {
    '$route.params.id': function (newId) {
      this.loadCustomer(newId);
    },
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
          },
          this.$route,
          this.$router
        );
        this.getCases(this.id);
      },
    },
  },
  mounted() {
    if (this.id !== 'undefined') {
      this.loadCustomer(this.id);
      this.getCases(this.id);
    } else this.customer = {};
  },
  methods: {
    ownerName,
    capitalize,
    compareRouteAndPush,
    updateCustomer(customer) {
      this.customer = customer;
    },
    fetchEmbryos(URLparams) {
      return icsiAPI.getEmbryosByCustomer(this.$route.params.id, URLparams);
    },
    async loadCustomer(id) {
      try {
        this.loading = true;
        const { data } = await customerAPI.getCustomer(id);
        this.customer = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async getCases(customerId) {
      try {
        this.loading = true;
        const caseQuery = { ...this.$route.query };
        delete caseQuery.tab;
        const {
          data: { results, total },
        } = await CaseAPI.getAll({ customer: customerId, ...caseQuery });
        this.cases = results;
        this.totalCases = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="css">
@media print {
  .v-tabs-bar {
    display: none !important;
    height: 0 !important;
  }
}
</style>
