import { get, post, put, deleteR } from '@/services/API';

export default {
  postNitrogenContainer: (nitrogenContainer) => post('/api/nitrogen-container', { nitrogenContainer }),
  getNitrogenContainers: (params) => get(`/api/nitrogen-container?${new URLSearchParams(params)}`),
  getAvailableSemenContainerSpace: (containerId, tube, position) =>
    post('/api/nitrogen-container/remaining-space/semen', { containerId, tube, position }),
  getAvailableEmbryoContainerSpace: (containerId, tube, position) =>
    post('/api/nitrogen-container/remaining-space/embryo', { containerId, tube, position }),
  putLocation: (location) => put(`/api/location/${location._id}`, { location }),
  putNitrogenContainer: (nitrogenContainer) => put(`/api/nitrogen-container/${nitrogenContainer._id}`, { nitrogenContainer }),
  deleteNitrogenContainer: (id) => deleteR(`/api/nitrogen-container/${id}`),
};
