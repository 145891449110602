<template>
  <v-layout column fill-height justify-space-between>
    <horse-table :headers="headers" :filters="filters" />
    <v-btn style="width: fit-content; align-self: flex-end" color="primary" class="mr-5 mb-5" depressed @click="openCreateHorsePage()">
      {{ $t('common.actions.addItem', { item: $t('common.horseTypes.stud') }) }}
      <v-icon right> mdi-plus </v-icon>
    </v-btn>
  </v-layout>
</template>
<script>
import horseTable from '@/components/horse/Table';
import { displayLocation } from '@/Helpers/location.helper';
import { locationAPI } from '@/services';
import i18n from '@/translations/i18n';

export default {
  components: { horseTable },
  data: () => ({
    locations: [],
    headers: [
      { text: i18n.t('stud.name'), value: 'name', selected: true, groupable: false },
      { text: i18n.t('stud.fam'), value: 'father', selected: true },
      { text: i18n.t('stud.type'), value: 'type', sortable: false, selected: false },
      { text: i18n.t('stud.nr'), value: 'surrogate_uid', selected: true, groupable: false },
      { text: i18n.t('stud.chip'), value: 'microchip', selected: false, groupable: false },
      { text: i18n.t('stud.location'), value: 'location', selected: true },
      { text: i18n.t('stud.owner'), value: 'owner', selected: true },
      { text: i18n.t('stud.surrogate'), align: 'end', value: 'surrogate', selected: true, groupable: false },
      { text: i18n.t('stud.made'), align: 'end', value: 'createdAt', selected: false, groupable: false },
      { text: i18n.t('stud.edit'), align: 'end', value: 'updatedAt', selected: false, groupable: false },
      { text: i18n.t('stud.birth'), align: 'end', value: 'date_of_birth', selected: false, groupable: false },
    ],
    downloadData: {},
  }),
  computed: {
    filters() {
      return {
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: i18n.t('stud.filter'),
          items: this.locations,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
          options: {
            multiple: true,
          },
        },
        aliveOnly: { vTag: 'switch', label: i18n.t('stud.hide') },
      };
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    openCreateHorsePage() {
      this.$router.push({ path: '/horse/create?type=Hengst&stud=true' });
    },
    async getLocations() {
      try {
        const {
          data: { locations },
        } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
