import { render, staticRenderFns } from "./LiveStockRegister.vue?vue&type=template&id=8fab64ac&scoped=true&"
import script from "./LiveStockRegister.vue?vue&type=script&lang=ts&"
export * from "./LiveStockRegister.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fab64ac",
  null
  
)

export default component.exports