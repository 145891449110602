<template>
  <v-card class="ma-5" outlined>
    <v-toolbar flat>
      <v-toolbar-title>{{ title | capitalize }}</v-toolbar-title>
      <v-spacer />
      <v-col class="pr-0">
        <basic-search
          :label="$t('cases.search')"
          :endpoint="search"
          :get-specific="getSpecificCase"
          search-key="reference"
          :outlined="true"
          :clearable="true"
          :dense="true"
          :format="(kees) => (kees ? kees.reference : '-')"
          @select="updateSearch"
        />
      </v-col>
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchCases"
        @fetching="loading = $event"
        @fetchedResults="cases = $event"
        @fetchedTotal="totalCases = $event"
      />
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="ma-5"
      :headers="filteredHeaders"
      :items="cases"
      :loading="loading"
      :loading-text="$t('common.loading')"
      :server-items-length="totalCases"
      :options.sync="options"
      multi-sort
    >
      <template #no-data>
        {{ $t('common.noneFound', { item: title.toLowerCase() }) }}
      </template>
      <template #item="props">
        <tr class="clickable" @click="openCasePage(props.item._id)">
          <td v-if="showColumn('reference')">
            {{ props.item.reference || '-' }}
          </td>
          <td v-if="showColumn('horse')">
            {{ horseName(props.item.horse) }}
          </td>
          <td v-if="showColumn('customer')">
            {{ ownerName(props.item.customer) }}
          </td>
          <td v-if="showColumn('extra')">
            {{ horseName(props.item.extra) }}
          </td>
          <td v-if="showColumn('mainActivity')">
            {{ props.item.mainActivity }}
          </td>
          <td v-if="showColumn('length')">
            {{ props.item.researchSheets.length }}
          </td>
          <td v-if="showColumn('status')" class="text-end">
            <Tag :text="props.item.status" :color="colorTag(props.item.status)" />
          </td>
          <td v-if="showColumn('updatedAt')">
            {{ formatDate(props.item.updatedAt) }}
          </td>
          <td v-if="showColumn('createdAt')" class="text-end">
            {{ formatDate(props.item.createdAt) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatDate, ownerName, horseName, compareRouteAndPush } from '@/Helpers';
import { CaseAPI, configAPI } from '@/services';
import Tag from '@/components/common/Tag';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';
import BasicSearch from '@/components/search/BasicSearch';
import i18n from '@/translations/i18n';

export default {
  components: { Tag, Filters, Columns, BasicSearch },
  props: {
    title: { type: String, default: 'cases' },
    headers: { type: Array, required: true },
  },
  data: (data) => ({
    filteredHeaders: [],
    cases: [],
    totalCases: 0,
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    loading: false,
    statuses: [],
    activities: [],
    sortBy: ['status', 'createdAt'],
    sortDesc: [true, true],
  }),
  computed: {
    filters() {
      return {
        horse: { vTag: 'searchHorse' },
        customer: { vTag: 'searchCustomer' },
        // TODO: horseType: { vTag: 'select', label: 'Filter op geslacht paard', items: ['Merrie', 'Hengst'] },
        status: {
          vTag: 'select',
          label: i18n.t('cases.filters.status'),
          items: this.statuses,
          cols: 6,
          options: {
            multiple: true,
          },
        },
        mainActivity: {
          vTag: 'select',
          label: i18n.t('cases.filters.activity'),
          items: this.activities,
          cols: 6,
          options: {
            multiple: true,
          },
        },
        hideSurrogate: {
          vTag: 'switch',
          label: i18n.t('cases.filters.hideSurrogates'),
          hideIf: {
            target: 'onlySurrogate',
            condition: 'true',
          },
        },
        onlySurrogate: {
          vTag: 'switch',
          label: i18n.t('cases.filters.onlySurrogates'),
          hideIf: {
            target: 'hideSurrogate',
            condition: 'true',
          },
        },
      };
    },
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
  },
  mounted() {
    this.getCaseStatus();
  },
  methods: {
    ownerName,
    horseName,
    formatDate,
    compareRouteAndPush,
    fetchCases(URLparams) {
      return CaseAPI.getAll(URLparams);
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    colorTag(tagText) {
      switch (tagText) {
        case 'Gesloten':
          return 'grey';
        case 'Factureerbaar':
          return 'green';
        default:
          return 'primary';
      }
    },
    openCasePage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/case/${id}`);
    },
    updateSearch(kees) {
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          reference: kees ? kees.reference : undefined,
          page: 1,
        },
        this.$route,
        this.$router
      );
    },
    async getCaseStatus() {
      try {
        const {
          data: { statuses, activities },
        } = await configAPI.getCaseStatus();
        this.statuses = statuses;
        this.activities = activities;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async search(v) {
      const { data } = await CaseAPI.search(v, { limit: 10 });
      return data;
    },
    async getSpecificCase(query) {
      const {
        data: { results },
      } = await CaseAPI.getAll(query);
      return results && results.length ? results[0] : undefined;
    },
  },
};
</script>
