<template>
  <div>
    <v-row class="mt-0 mx-0 mb-2">
      <v-col cols="12" class="information d-flex align-center">
        <span class="ml-2">{{ $t('paramselecttitle') }}</span>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <single-parameter
        v-for="(option, i) in simpleParameterOptions"
        :key="`${i}`"
        :option="option"
        :required="required"
        @check-parameter="checkSimpleParameter"
        @input-text="inputSimpleParameter"
      />
    </v-row>
    <v-row class="mx-5 mb-5">
      <single-parameter
        v-for="(option, i) in parameterOptions"
        :key="`${i}-${required ? required.length : '0'}`"
        :option="option"
        :required="required"
        :horse-types="horseConfig?.types"
        @select-horse="selectHorse"
        @check-parameter="checkParameter"
        @select-specification="selectSpecification"
      />
    </v-row>
  </div>
</template>

<script>
import SingleParameter from '@/components/extraConfig/SingleParameter';
import { mapGetters } from 'vuex';

export default {
  name: 'ParameterSelector',
  components: { SingleParameter },
  props: {
    parameters: { type: Array, default: () => [] },
    parameterOptions: { type: Array, required: true },
    required: { type: Array, default: () => [] },
    simpleParameters: { type: Array, required: true },
    simpleParameterOptions: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters({
      horseConfig: 'generalConfig/horseConfig'
    }),
  },
  methods: {
    checkParameter(param) {
      if (param.switch) {
        const parameter = { elementModel: param.value };
        this.parameters.push(parameter);
      } else {
        this.parameters.splice(
          this.parameters.findIndex((item) => item.elementModel === param.value),
          1
        );
      }
    },
    checkSimpleParameter(param) {
      if (param.switch) {
        const parameter = { elementType: param.value };
        this.simpleParameters.push(parameter);
      } else {
        this.simpleParameters.splice(
          this.simpleParameters.findIndex((item) => item.elementType === param.value),
          1
        );
      }
    },
    inputSimpleParameter(param) {
      const simpleParameter = this.simpleParameters.find((item) => item.elementType === param.value);
      if (simpleParameter) {
        simpleParameter.elementName = param.inputValue;
      }
    },
    selectHorse(param) {
      const index = this.parameters.findIndex((item) => item.elementModel === param.value);
      this.parameters[index].elementType = param.selectValue || undefined;
      // eslint-disable-next-line no-param-reassign
      param.specificationSwitch = false;
      this.parameters[index].elementSpecification = false;
    },
    selectSpecification(param) {
      const index = this.parameters.findIndex((item) => item.elementModel === param.value);
      this.parameters[index].elementSpecification = param.specificationSwitch;
    }
  },
};
</script>

<style scoped>
.information {
  background-color: #f6f6f6;
  border-left: solid 4px rgba(0, 0, 0, 0.12);
}
</style>
