export const fertility = {
  icsi: {
    title: 'ICSI loten',
    add: 'lot toevoegen',
    table: {
      headers: {
        code: 'Code',
        donorMare: 'Donor Merrie',
        stallion: 'Dekhengst',
        amount: "Aantal embryo's",
        updatedOn: 'Gewijzigd op',
        createdOn: 'Aangemaakt op',
      },
    },
    filters: {
      active: "Enkel loten met actieve embryo's",
    },
  },
  semen: {
    add: 'Sperma lot toevoegen',
    download: 'Download CSV',
    title: 'Paarden met sperma loten',
    table: {
      headers: {
        name: 'Naam paard',
        initial: 'Initieel # loten',
        current: 'Huidig # loten',
        mods: 'Modificaties',
      },
    },
    filters: {
      exportType: 'Filter op export type van sperma loten',
    },
  },
};
