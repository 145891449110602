<template>
  <v-card outlined class="ma-5">
    <v-toolbar flat dense light>
      <v-toolbar-title>{{ $t('pensionform.title') }}</v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-form ref="form" v-model="valid">
      <v-row class="ma-5">
        <v-col cols="12" md="4">
          <v-text-field v-model="pension.name" :rules="required" :label="$t('pensionform.nlname')" />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="pension.translations.en" :label="$t('pensionform.enname')" />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="pension.translations.fr" :label="$t('pensionform.frname')" />
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-col cols="12" md="4">
          <v-select
            v-model="pension.account"
            :items="accounts"
            :item-text="accountName"
            item-value="_id"
            :label="$t('pensionform.account')"
            :menu-props="{ offsetY: true }"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-select v-model="pension.tax" :rules="required" :items="taxes" :label="$t('pensionform.tax')" :menu-props="{ offsetY: true }" />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="pension.pricePerDay"
            :rules="required"
            type="number"
            :label="$t('pensionform.price')"
            prefix="€"
            placeholder="0.00"
          />
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-col cols="12" md="4">
          <v-select
            v-model="pension.availableFor"
            :items="horseConfig?.types"
            :menu-props="{ offsetY: true }"
            :label="$t('pensionform.available')"
          />
        </v-col>
      </v-row>
    </v-form>
    <div class="mr-2 mb-5">
      <v-row justify="end" class="gap-4" dense>
        <Button type="back" :action="() => $router.back()" />

        <v-btn v-if="!pension._id" :disabled="!valid" color="success" depressed @click="createPension">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('pensionform.save') }}
        </v-btn>
        <v-btn v-if="pension._id" :disabled="!valid" color="success" depressed @click="editPension">
          <v-icon left> mdi-content-save-outline </v-icon>
          {{ $t('pensionform.edit') }}
        </v-btn>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { accountName, required } from '@/Helpers';
import { pensionAPI, configAPI, accountAPI } from '@/services';
import { mapGetters } from 'vuex';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button },
  props: ['pension', 'loading', 'disabled'],
  data: () => ({
    valid: false,
    taxes: [],
    accounts: [],
  }),
  computed: {
    required,
    ...mapGetters({
      horseConfig: 'generalConfig/horseConfig'
    }),
  },
  created() {
    Promise.all([this.getProductConfig(), this.getAccounts()]);
  },
  methods: {
    accountName,
    async handler(data) {
      this.$emit('update-pension', data);
      await this.$router.back();
    },
    async createPension() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await pensionAPI.post(this.pension);
        await this.handler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async editPension() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await pensionAPI.put(this.pension);
        await this.handler(data);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getProductConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { tax },
        } = await configAPI.getProductConfig();
        this.taxes = tax;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getAccounts() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { results },
        } = await accountAPI.getAllAccounts();
        this.accounts = results;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>

<style scoped></style>
