import Vue from 'vue';
import Vuex from 'vuex';
import { errorMessage, YearlyReport, CompanyInfo, TenantInfo, GeneralConfig, Invoices } from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    companyInfo: CompanyInfo,
    errorMessage,
    generalConfig: GeneralConfig,
    tenantInfo: TenantInfo,
    yearlyReport: YearlyReport,
    invoices: Invoices
  },
});

export default store;
