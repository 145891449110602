export const customers = {
  add: 'Klant toevoegen',
  tabs: {
    private: 'Particulieren',
    company: 'Bedrijven',
  },
  table: {
    headers: {
      company: 'Bedrijf',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      createdAt: 'Aangemaakt op',
      vat: 'BTW-nr',
    },
  },
  general: {},
  horses: {
    table: {
      name: 'Naam paard',
      familyTree: 'Stamboom',
      type: 'Type paard',
    },
  },
  embryos: {},
  semen: {},
  researchFiles: {},
};
