export const lodging = {
    labels: {
        days: 'dagen',
        add: 'Nieuw verblijf toevoegen',
        arrivalDate: 'Aankomstdatum',
        departureDate: 'Vertrekdatum',
        stable: 'Stal',
        prefix: 'Prefix',
        location: 'Locatie',
        full: '(Volzet)',
        free: 'Aantal vrije plaatsen:',
        pension: 'Pension type',
        stay: 'Duur verblijf',
        status: 'Facturatie status',
        lodgings: 'Verblijven',
    },
    dialog: {
        edit: 'Verblijf bewerken',
        delete: 'Verblijf verwijderen',
        billable: 'Is dit verblijf factureerbaar?',
    },
    warnings: {
        foundDupe: 'Er zijn overlappingen gevonden',
        dateDupe: 'Met de huidige aankomst- english vertrekdatum zijn er overlappingen gevonden met andere verblijven',
        chooseLocation: 'Gelieve een locatie te kiezen',
        chooseStable: 'Gelieve een stal te kiezen',
    },
};
