<template>
  <v-layout column fill-height justify-space-between>
    <v-card class="mx-5 mt-5 mb-12" outlined>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('config.locations.title') }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="locations"
        :loading="loading"
        :loading-text="$t('common.loading')"
        :server-items-length="totalLocations"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :options.sync="options"
        class="ma-5"
      >
        <template #item="props">
          <tr>
            <td>{{ props.item.stable }}</td>
            <td v-if="props.item.prefix">{{ props.item.prefix }}-{{ props.item.name }}</td>
            <td v-else>
              {{ props.item.name }}
            </td>
            <td>{{ props.item.places }}</td>
            <td>{{ props.item.horses.length }}</td>
            <td class="text-right d-print-none">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon color="primary" class="mr-2" @click="locationInfo(props.item)" v-on="on"> mdi-horseshoe </v-icon>
                </template>
                <span>{{ $t('config.locations.table.tooltips.presentHorses') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon color="primary" class="mr-2" @click="editLocation(props.item)" v-on="on"> mdi-pencil </v-icon>
                </template>
                <span>{{ $t('config.locations.table.tooltips.edit') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon color="primary" @click="deleteLocation(props.item)" v-on="on"> mdi-delete </v-icon>
                </template>
                <span>{{ $t('config.locations.table.tooltips.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template #no-data>
          {{ $t('common.noneFound', { item: $t('config.locations.title').toLowerCase() }) }}
        </template>
      </v-data-table>
    </v-card>
    <v-btn style="width: fit-content; align-self: flex-end" color="primary" class="mr-5 mb-5" depressed @click="createLocation">
      {{ $t('config.locations.add') }}
      <v-icon right> mdi-plus </v-icon>
    </v-btn>
    <location-dialog
      :edit="edit"
      :dialog="locationDialog"
      :selected-location="selectedLocation"
      :stables="stables"
      @close="locationDialog = $event"
      @saved="newLocation = $event"
    />
    <location-info-dialog :info-dialog="infoDialog" :selected-location="selectedLocation" @close="infoDialog = $event" />
  </v-layout>
</template>

<script>
import configAPI from '@/services/ConfigAPI';
import LocationDialog from '@/views/settings/locations/LocationDialog';
import locationAPI from '@/services/LocationAPI.js';
import { compareRouteAndPush } from '@/Helpers';
import LocationInfoDialog from './LocationInfoDialog.vue';
import i18n from '@/translations/i18n';

export default {
  components: { LocationDialog, LocationInfoDialog },
  data: () => ({
    loading: false,
    locations: [],
    totalLocations: 0,
    options: {},
    sortBy: 'stable',
    sortDesc: false,
    headers: [
      { text: i18n.t('config.locations.table.headers.stable'), value: 'stable' },
      { text: i18n.t('config.locations.table.headers.name'), value: 'name' },
      { text: i18n.t('config.locations.table.headers.places'), value: 'places' },
      { text: i18n.t('config.locations.table.headers.amountOfHorses'), value: 'horses.length' },
      { text: i18n.t('common.actions.edit'), align: 'right', value: 'action', sortable: false },
    ],
    selectedLocation: undefined,
    locationDialog: false,
    infoDialog: false,
    edit: false,
    newLocation: undefined,
  }),
  computed: {
    stables() {
      return this.$store.state.tenantInfo.activeTenant?.stables || [];
    },
  },
  watch: {
    newLocation: {
      deep: true,
      handler() {
        this.getAllLocations();
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
    '$route.query': {
      deep: true,
      handler() {
        if (!this.$route.query.page && !this.$route.query.limit) {
          this.$router.replace({
            query: {
              page: 1,
              limit: this.options.itemsPerPage,
              sortBy: this.options.sortBy,
              sortDesc: this.options.sortDesc,
              ...this.$route.query,
            },
          });
        } else {
          this.getAllLocations();
        }
      },
    },
  },
  methods: {
    compareRouteAndPush,
    createLocation() {
      this.edit = false;
      this.selectedLocation = {};
      this.locationDialog = true;
    },
    editLocation(location) {
      this.edit = true;
      this.selectedLocation = location;
      this.locationDialog = true;
    },
    locationInfo(location) {
      this.infoDialog = true;
      this.selectedLocation = location;
    },
    async getAllLocations() {
      this.loading = true;
      try {
        const {
          data: { locations, total },
        } = await locationAPI.getLocations(this.$route.query);
        this.locations = locations;
        this.totalLocations = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async deleteLocation(location) {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        await locationAPI.deleteLocation(location._id);
        await this.getAllLocations();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
