<template>
  <v-card class="mx-5 mt-5 mb-5" outlined>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-col class="pr-0">
        <search-product :id="$route.query._id" :dense="true" :clearable="true" @emit-product="updateSearch" />
      </v-col>
      <filters
        :filters="filters"
        :options="options"
        :fetcher="fetchProducts"
        @fetching="loading = $event"
        @fetchedResults="products = $event"
        @fetchedTotal="totalProducts = $event"
      />
      <columns :headers="headers" @emit-headers="filteredHeaders = $event" />
    </v-toolbar>
    <v-data-table
      class="ma-5"
      :headers="filteredHeaders"
      :items="products"
      :loading="loading"
      loading-text="Bezig met laden..."
      :server-items-length="totalProducts"
      :options.sync="options"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template #no-data>
        {{ $t('producttable.nodata') }}
      </template>
      <template #item="props">
        <tr>
          <td v-if="showColumn('name')">
            {{ props.item.name }}
          </td>
          <td v-if="showColumn('type')">
            {{ props.item.type }}
          </td>
          <td v-if="showColumn('CNK')">
            {{ props.item.CNK }}
          </td>
          <td v-if="showColumn('dosage')">
            {{ props.item.dosage }}
          </td>
          <td v-if="showColumn('outgoingUnit')">
            {{ props.item.outgoingUnit }}
          </td>
          <td v-if="showColumn('waitingTime')">
            {{ props.item.waitingTime }}
          </td>
          <td v-if="showColumn('tax')">
            {{ props.item.tax }}
          </td>
          <td v-if="showColumn('supplementAdministration')" class="text-right">
            {{ props.item.supplementAdministration ? '€ ' + props.item.supplementAdministration.toFixed(2) : '' }}
          </td>
          <td v-if="showColumn('action')" class="text-right d-print-none">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon dark color="primary" class="mr-2" @click="() => openStockProductPage(props.item._id)" v-on="on">
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('producttable.edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon dark color="primary" @click="() => openDeleteDialog(props.item)" v-on="on"> mdi-delete </v-icon>
              </template>
              <span>{{ $t('producttable.delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <delete-dialog
      :delete-dialog="deleteDialog"
      item="Product"
      :delete-queue="deleteQueue"
      @delete="deleteItem"
      @close="closeDeleteDialog"
    />
  </v-card>
</template>

<script>
import productsAPI from '@/services/ProductsAPI';
import DeleteDialog from '@/components/common/DeleteDialog';
import SearchProduct from '@/components/search/SearchProduct';
import Filters from '@/components/filters/Filters';
import Columns from '@/components/filters/Columns';
import { compareRouteAndPush } from '@/Helpers';

export default {
  components: { Filters, Columns, SearchProduct, DeleteDialog },
  props: {
    title: { type: String, required: true },
    headers: { type: Array, required: true },
    filters: { type: Object, default: () => ({}) },
  },
  data: (data) => ({
    totalProducts: 0,
    filteredHeaders: [],
    products: [],
    loading: false,
    deleteDialog: false,
    deleteQueue: {},
    options: {
      page: Number(data.$route.query.page) || 1,
      itemsPerPage: Number(data.$route.query.limit) || 10,
    },
    sortBy: 'name',
    sortDesc: false,
  }),
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.compareRouteAndPush(
          {
            ...this.$route.query,
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
          this.$route,
          this.$router
        );
      },
    },
  },
  methods: {
    compareRouteAndPush,
    openProductPage(id) {
      this.$router.push(`/settings/product/${id}`);
    },
    showColumn(col) {
      return this.headers.find((header) => header.value === col).selected;
    },
    updateSearch(product) {
      this.compareRouteAndPush(
        {
          ...this.$route.query,
          _id: product ? product._id : undefined,
        },
        this.$route,
        this.$router
      );
    },
    openDeleteDialog(item) {
      this.deleteQueue = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog(state) {
      this.deleteQueue = {};
      this.deleteDialog = state;
    },
    fetchProducts(URLparams) {
      return productsAPI.getAllProducts(URLparams);
    },
    openStockProductPage(id) {
      document.body.style.cursor = 'default';
      this.$router.push(`/stock/${id}`);
    },
    async getProducts() {
      this.loading = true;
      try {
        const {
          data: { results, total },
        } = await productsAPI.getAllProducts(this.$route.query);
        this.products = results;
        this.totalProducts = total;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    async deleteItem(item) {
      try {
        this.loading = true;
        await this.$store.dispatch('errorMessage/resetError');
        await productsAPI.deleteProduct(item._id);
        this.deleteDialog = false;
        this.deleteQueue = {};
        await this.getProducts();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
