<template>
  <v-card flat>
    <v-data-table class="ma-5" :headers="headers" :items="protocols" item-key="_id">
      <template #no-data> {{ $t('certtable.nodata1') }} {{ title }} {{ $t('certtable.nodata2') }} </template>
      <template #item="props">
        <tr>
          <td>
            {{ getProtocolName(props.item.protocol) }}
          </td>
          <td>
            <span class="grey--text">{{ formatDateFull(props.item.date) }}</span>
          </td>
          <td class="text-right">
            <v-menu bottom left offset-y>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('certtable.span') }}</span>
                </v-tooltip>
              </template>

              <v-list>
                <v-list-item v-for="(report, i) in props.item.protocol.reportType" :key="i" dense @click="openReport(props.item, report)">
                  <v-list-item-title>{{ report.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { formatDateFull, embryoDescription, semenDescription, getProtocolName, reportQueryFormatter } from '@/Helpers';
import i18n from '@/translations/i18n';

export default {
  props: {
    sheet: { type: Object, default: undefined },
    title: { type: String, default: '' },
    protocols: { type: Array, default: undefined },
    caseId: { type: String, required: true },
  },
  data: () => ({
    headers: [
      { text: i18n.t('certtable.name'), value: 'name' },
      { text: i18n.t('certtable.date'), value: 'date' },
      { text: '', value: 'action', align: 'end' },
    ],
  }),
  methods: {
    getProtocolName,
    embryoDescription,
    semenDescription,
    formatDateFull,
    reportQueryFormatter,
    openReport(item, report) {
      const qs = reportQueryFormatter(item);
      this.$router.push(`/report/${report.url}?caseId=${this.caseId}&date=${item.date}&title=${report.name}${qs}`);
    },
    checkType(elementType) {
      switch (elementType) {
        case 'Merrie':
          return 'Draagmoeder: ';
        case 'Hengst':
          return 'Hengst: ';
        default:
          return '';
      }
    },
  },
};
</script>
