<template>
  <div class="d-print-none">
    <!-- SIDEBAR -->
    <v-navigation-drawer v-if="$auth.isAuthenticated" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <!-- SIDEBAR TOP -->
      <v-list dense>
        <template v-for="item in menu">
          <v-list-group v-if="item.submenu" :key="item.text" v-model="item.model" :prepend-icon="item.icon">
            <template #activator>
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.submenu" :key="i" :to="child.url" class="pl-8">
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.url">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <!-- SIDEBAR BOTTOM -->
      <template #append>
        <div class="px-2">
          <v-select
            v-model="$i18n.locale"
            :items="availableLanguages"
            item-text="label"
            item-value="value"
            :menu-props="{ top: true }"
            hide-details
            flat
            filled
            solo
            dense
            @change="updateLanguage()"
          />
        </div>
        <div class="pa-2">
          <v-btn v-show="$auth.isAuthenticated" depressed block @click="$auth.logout()">
            {{ $t('common.nav.logout') }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- TOPBAR -->
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      style="border-right: none; border-left: none; background: white"
      app
      flat
      outlined
    >
      <v-app-bar-nav-icon class="ml-auto" v-show="$auth.isAuthenticated" @click.stop="drawer = !drawer" />
      <v-toolbar-title class="ml-0 pl-3">
        <router-link class="d-flex" style="height: 36px" to="/">
          <equibase-logo />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <notification-menu v-if="$auth.isAuthenticated" />
      <search />
    </v-app-bar>
  </div>
</template>

<script>
import search from '@/components/search/Search';
import NotificationMenu from '@/components/notification/NotificationMenu';
import i18n from '@/translations/i18n';
import EquibaseLogo from '@/assets/EquibaseLogo.vue';
import { formatFullLanguage } from '@/Helpers';

export default {
  name: 'Navigation',
  components: { EquibaseLogo, search, NotificationMenu },
  data() {
    return {
      dialog: false,
      drawer: null,
    };
  },
  computed: {
    availableLanguages() {
      return this.$i18n.availableLocales.map((lang) => ({ value: lang, label: formatFullLanguage(lang) }));
    },
    menu() {
      return [
        {
          text: i18n.t('common.nav.client'),
          url: '/customer?type=particulier',
          // icon: "mdi-alpha-k-circle"
          // icon: "mdi-contacts",
        },
        {
          text: i18n.t('common.nav.horse.main'),
          // icon: "mdi-alpha-p-circle",
          // icon: "mdi-horseshoe",
          submenu: [
            {
              text: i18n.t('common.nav.horse.stallion'),
              url: '/horse/stallion?type=Hengst&aliveOnly=true',
            },
            {
              text: i18n.t('common.nav.horse.mare'),
              url: '/horse/mare?type=Merrie&aliveOnly=true',
            },
            {
              text: i18n.t('common.nav.horse.surrogate'),
              url: '/horse/surrogate?type=Merrie&surrogate=true&aliveOnly=true',
            },
            {
              text: i18n.t('common.nav.horse.stud'),
              url: '/horse/studhorse?type=Hengst&stud_horse=true&aliveOnly=true',
            },
          ],
        },
        {
          text: i18n.t('common.nav.medication'),
          url: '/stock',
        },
        {
          text: i18n.t('common.nav.fertility.main'),
          submenu: [
            {
              text: i18n.t('common.nav.fertility.icsi'),
              url: '/icsi?onlyActive=true',
            },
            {
              text: i18n.t('common.nav.fertility.semen'),
              url: '/semen',
            },
          ],
        },
        {
          text: i18n.t('common.nav.case'),
          url: '/cases?status=Factureerbaar&status=Open&hideSurrogate=true',
        },
        {
          text: i18n.t('common.nav.reports.main'),
          // icon: "mdi-alpha-r-circle",
          // icon: "mdi-google-analytics",
          submenu: [
            {
              text: i18n.t('common.nav.reports.locationOverview'),
              url: '/report/available-locations',
            },
            {
              text: i18n.t('common.nav.reports.surrogateByLocation'),
              url: '/report/location?type=Merrie&surrogate=true&aliveOnly=true',
            },
            {
              text: i18n.t('common.nav.reports.deceased'),
              url: '/report/death-horses?death=true',
            },
            {
              text: i18n.t('common.nav.reports.semenStock'),
              url: '/report/semen-stock',
            },
            {
              text: i18n.t('common.nav.reports.deliveredProducts'),
              url: '/report/delivered-products?type=Aankoop',
            },
            {
              text: i18n.t('common.nav.reports.outgoingProducts'),
              url: '/report/outgoing-products?out=true',
            },
            {
              text: i18n.t('common.nav.reports.appliedMedication'),
              url: '/report/applied-medication?type=Toediening',
            },
            {
              text: i18n.t('common.nav.reports.yearlyReports'),
              url: '/yearlyReport',
            },
            {
              text: i18n.t('common.nav.reports.presenceRegister'),
              url: '/livestock',
            },
            {
              text: i18n.t('common.nav.reports.deckedMares'),
              url: '/covered-mares',
            },
            {
              text: i18n.t('common.nav.reports.stallionOwnerTransactions'),
              url: '/transactions-per-stallion-owner',
            },
          ],
        },
        {
          text: i18n.t('common.nav.config.main'),
          // icon: "mdi-alpha-i-circle",
          // icon: "mdi-settings",
          submenu: [
            {
              text: i18n.t('common.nav.config.locations'),
              url: '/settings/location',
            },
            {
              text: i18n.t('common.nav.config.nitrogenContainers'),
              url: '/settings/nitrogen-container',
            },
            {
              text: i18n.t('common.nav.config.downloadResearchSheet'),
              url: '/settings/research-sheets',
            },
            {
              text: i18n.t('common.nav.config.products'),
              url: '/settings/products',
            },
            {
              text: i18n.t('common.nav.config.protocols'),
              url: '/settings/protocols',
            },
            {
              text: i18n.t('common.nav.config.labs'),
              url: '/settings/labos',
            },
            {
              text: i18n.t('common.nav.config.pensionTypes'),
              url: '/settings/pension',
            },
            {
              text: i18n.t('common.nav.config.upload'),
              url: '/settings/uploadSemenStock',
            },
            {
              text: i18n.t('common.nav.config.tenant'),
              url: '/settings/tenant',
            },
          ],
        },
      ];
    },
  },
  mounted() {
    if (localStorage.getItem('locale')) {
      this.$i18n.locale = localStorage.getItem('locale');
    } else {
      localStorage.setItem('locale', this.$i18n.locale);
    }
  },
  methods: {
    updateLanguage() {
      localStorage.setItem('locale', this.$i18n.locale);
    },
  },
};
</script>
