<template>
  <div>
    <v-row class="d-flex justify-end mr-5">
      <v-switch
        v-model="filters.stallion"
        class="ml-2"
        inset
        :label="$t('horses.nav.stallion')"
        :disabled="filters.surrogate"
        @change="!filters.stallion ? (filters.stud_horse = false) : ''"
      />
      <v-switch
        v-model="filters.stud_horse"
        class="ml-5"
        inset
        :label="$t('horses.nav.stud')"
        :disabled="!filters.stallion"
        @change="
          () => {
            if (filters.stud_horse) {
              filters.mare = false;
              filters.surrogate = false;
            }
          }
        "
      />
      <v-switch
        v-model="filters.mare"
        class="ml-5"
        inset
        :label="$t('horses.nav.mare')"
        :disabled="filters.stud_horse"
        @change="!filters.mare ? (filters.surrogate = false) : ''"
      />
      <v-switch
        v-model="filters.surrogate"
        class="ml-5"
        inset
        :label="$t('horses.nav.surrogate')"
        :disabled="!filters.mare"
        @change="
          () => {
            if (filters.surrogate) {
              filters.stallion = false;
              filters.stud_horse = false;
            }
          }
        "
      />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="horses"
      :loading="loading"
      :server-items-length="totalHorses"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :options.sync="options"
      :loading-text="$t('common.loading')"
    >
      <template #item="props">
        <tr class="clickable" @click="openHorsePage(props.item._id)">
          <td>{{ props.item.name }}</td>
          <td>{{ checkFamily(props.item.father, props.item.grandfather) }}</td>
          <td>{{ props.item.type }}</td>
        </tr>
      </template>
      <template #no-data>
        {{ $t('horses.noneFound') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import customerAPI from '@/services/CustomerAPI.js';
import i18n from '@/translations/i18n';

export default {
  props: {
    customer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      horses: [],
      totalHorses: 0,
      sortBy: 'name',
      sortDesc: false,
      options: {},
      filters: {
        stallion: false,
        mare: false,
        surrogate: false,
        stud_horse: false,
      },
      headers: [
        { text: i18n.t('customers.horses.table.name'), value: 'name', align: 'left', sortable: true },
        { text: i18n.t('customers.horses.table.familyTree'), align: 'left', sortable: false },
        { text: i18n.t('customers.horses.table.type'), sortable: false },
      ],
    };
  },
  computed: {
    URLParameters() {
      const types = [];
      if (this.filters.mare) {
        types.push('Merrie');
      }
      if (this.filters.stallion) {
        types.push('Hengst');
      }
      return {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        type: this.filters.mare || this.filters.stallion ? types : undefined,
        surrogate: this.filters.surrogate === true ? this.filters.surrogate : undefined,
        stud_horse: this.filters.stud_horse === true ? this.filters.stud_horse : undefined,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.getCustomerHorses(this.customer);
      },
      deep: true,
    },
    filters: {
      handler() {
        if (this.filters.type === 'Hengst') this.filters.surrogate = false;
        this.getCustomerHorses(this.customer);
      },
      deep: true,
    },
  },
  mounted() {
    this.getCustomerHorses(this.customer);
  },
  methods: {
    checkFamily(father, grandFather) {
      if (father && grandFather) {
        return `${father} & ${grandFather}`;
      }
      if (father) {
        return father;
      }
      if (grandFather) {
        return grandFather;
      }
      return '-';
    },
    async getCustomerHorses(id) {
      try {
        const {
          data: { horses, total },
        } = await customerAPI.getCustomerHorses(id, this.URLParameters);
        this.horses = horses;
        this.totalHorses = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    openHorsePage(id) {
      this.$router.push(`/horse/${id}`);
    },
  },
};
</script>
