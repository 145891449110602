
import Vue from 'vue';
import i18n from '@/translations/i18n';

export default Vue.extend({
  name: 'AvailableLocationsTable',
  props: {
    stable: { type: String, default: '' },
    availableLocations: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    headers: [
      { text: i18n.t('header1'), value: 'name', sortable: false },
      { text: i18n.t('header2'), value: 'remainingPlaces', sortable: false },
    ],
  }),
});
