type InvoiceState = {
  caseId: string;
  selectedItems: {
    pensions: [];
    sheets: [];
  };
};

export const invoices = {
  namespaced: true,
  state: (): InvoiceState => ({
    caseId: '',
    selectedItems: undefined,
  }),
  mutations: {
    setCaseId(state: InvoiceState, caseId: string) {
      state.caseId = caseId;
    },
    setSelectedItems(state: InvoiceState, items) {
      state.selectedItems = items;
    },
  },
  actions: {
    setCaseId({ commit }, { caseId }) {
      commit('setCaseId', caseId);
    },
    setSelectedItems({ commit }, { items }) {
      commit('setSelectedItems', items);
    },
  },
  getters: {
    getCaseId(state: InvoiceState) {
      return state.caseId;
    },
    getSelectedItems(state: InvoiceState) {
      return state.selectedItems;
    },
  },
};
