<template>
  <horse-table :headers="headers" :filters="filters" :sort-by="sortBy" :is-surrogate-per-location-list="true" />
</template>
<script>
import horseTable from '@/components/horse/Table';
import { horseAPI, locationAPI } from '@/services';
import { horseName } from '@/Helpers';
import { displayLocation } from '@/Helpers/location.helper';
import i18n from '@/translations/i18n';

export default {
  components: { horseTable },
  data: () => ({
    locations: [],
    headers: [
      { text: i18n.t('surperloc.name'), value: 'name', selected: true },
      { text: i18n.t('surperloc.type'), value: 'type', sortable: false, selected: false },
      { text: i18n.t('surperloc.nr'), value: 'surrogate_uid', selected: true },
      { text: i18n.t('surperloc.chip'), value: 'microchip', selected: true },
      { text: i18n.t('surperloc.location'), value: 'location', selected: true },
      { text: i18n.t('surperloc.owner'), value: 'owner', selected: false },
      { text: i18n.t('surperloc.surrogate'), value: 'surrogate', selected: false },
      { text: i18n.t('surperloc.made'), align: 'end', value: 'createdAt', selected: false },
      { text: i18n.t('surperloc.edit'), align: 'end', value: 'updatedAt', selected: false },
      { text: i18n.t('surperloc.birth'), align: 'end', value: 'date_of_birth', selected: false },
    ],
    sortBy: 'location',
  }),
  computed: {
    filters() {
      return {
        owner: { vTag: 'searchCustomer' },
        location: {
          vTag: 'select',
          label: i18n.t('surperloc.filter'),
          items: this.locations,
          cols: 6,
          objectItems: {
            text: (item) => displayLocation(item),
            value: '_id',
          },
        },
        microchip: {
          vTag: 'basicSearch',
          label: i18n.t('surperloc.chip'),
          searchFunction: this.searchMicrochip,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) {
            return item.microchip;
          },
          cols: 6,
        },
        surrogate_uid: {
          vTag: 'basicSearch',
          label: i18n.t('surperloc.nr'),
          searchFunction: this.searchSurrogateUID,
          format: this.horseName,
          getSpecific: this.getSpecificHorse,
          returnValue(item) {
            return item.surrogate_uid;
          },
        },
        aliveOnly: { vTag: 'switch', label: i18n.t('surperloc.hide') },
      };
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    horseName,
    displayLocation,
    async searchMicrochip(v) {
      const { data } = await horseAPI.searchMicrochip(v, {
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return data;
    },
    async searchSurrogateUID(v) {
      const { data } = await horseAPI.searchSurrogateUID(v, {
        surrogate: true,
        type: 'Merrie',
        limit: 10,
      });
      return data;
    },
    async getSpecificHorse(query) {
      const {
        data: { results },
      } = await horseAPI.getHorses({
        ...query,
        surrogate: true,
        type: 'Merrie',
      });
      return results && results.length ? results[0] : undefined;
    },
    async getLocations() {
      try {
        const {
          data: { locations },
        } = await locationAPI.getLocations();
        this.locations = locations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>
