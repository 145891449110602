<template>
  <icsi-form :icsi="icsi" :loading="loading" />
</template>

<script>
import icsiForm from '@/components/icsi/Form';
import icsiAPI from '@/services/icsi';

export default {
  components: {
    icsiForm,
  },
  props: ['id'],
  data() {
    return {
      icsi: {
        embryos: [],
        location: {},
      },
      loading: null,
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.icsi.collectionDate);
    },
  },
  methods: {
    async getICSI(id) {
      this.loading = true;
      try {
        const icsi = await icsiAPI.getICSI(id);
        this.icsi = icsi.data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    updateICSI(icsi) {
      this.icsi = icsi;
    },
  },
};
</script>
