<template>
  <v-container class="d-flex justify-center">
    <v-card class="d-flex justify-center flex-column pa-5 rounded">
      <v-card-title class="justify-center">
        {{ $t('error.title') }}
      </v-card-title>
      <v-card-subtitle class="justify-center">
        {{ $t('error.subtitle') }}
      </v-card-subtitle>
      <v-card-actions class="justify-start">
        <v-btn color="primary" depressed @click="goHome">
          <v-icon left> mdi-arrow-left </v-icon>
          {{ $t('error.home') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'ErrorPage404',
  methods: {
    goHome() {
      this.$router.push('/');
      this.$store.dispatch('errorMessage/resetError');
    },
  },
};
</script>

<style scoped></style>
