export const horses = {
  noneFound: 'No horses found',
  nav: {
    stallion: 'Stallions',
    stud: 'Studs',
    mare: 'Mares',
    surrogate: 'Surrogates',
  },
  horseDetail: {
    form: {
      general: {
        title: 'General',
        name: 'Name horse*',
        gender: 'Gender*',
        dateOfBirth: 'Birthdate',
        owner: 'Find owner',
        ueln: 'UELN',
        chip: 'Microchip',
        stallionOwner: 'Search stallion owner',
        fee: 'Fee',
        deceasedDate: 'Date of death',
        surrogateUid: 'Surrogate brand number',
      },
      location: {
        title: 'Location',
        prefix: 'Prefix',
        location: 'Location',
        billableLodging: 'Is this lodging billable?',
        tooltip: 'When creating a new horse with location, a running residence will automatically be created as well',
      },
      features: {
        title: 'Features',
        breed: 'Breed',
        studbook: 'Pedigree',
        color: 'Coat color',
        father: 'Father',
        mother: 'Mother',
        grandFather: 'Grandfather',
      },
      extra: {
        title: 'Extra',
        stud: 'Stud',
        wffsFree: 'WFFS free',
        surrogate: 'surrogate',
        foodChain: 'Hold for foodchain',
        deceased: 'Deceased',
      },
      confirmation: {
        title: 'Delete horse?',
        description: 'Are you sure you want to delete the horse {horse}? This action cannot be reversed',
        success: 'Horse has been successfully saved',
      },
      actions: {
        downloadFiche: 'Researchsheet downloaden',
      },
    },
  },
};
