<template>
  <v-layout column fill-height justify-space-between>
    <pension-table title="Pension types" :headers="headers" :filters="filters" />
    <v-btn style="width: fit-content; align-self: flex-end" color="primary" class="mr-5 mb-5" depressed @click="() => openPensionPage()">
      {{ $t('pensionindex.add') }}
      <v-icon right> mdi-plus </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import pensionTable from '@/components/pension/Table';
import i18n from '@/translations/i18n';
import { configAPI } from '@/services';

export default {
  components: { pensionTable },
  data: () => ({
    headers: [
      { text: i18n.t('pensionindex.nlname'), value: 'name', selected: true },
      { text: i18n.t('pensionindex.enname'), value: 'en', selected: false },
      { text: i18n.t('pensionindex.frname'), value: 'fr', selected: false },
      { text: i18n.t('pensionindex.account'), value: 'account', selected: true },
      { text: i18n.t('pensionindex.price'), value: 'pricePerDay', selected: true },
      { text: i18n.t('pensionindex.tax'), value: 'tax', align: 'left', selected: true },
      { text: i18n.t('pensionindex.edit'), value: 'action', align: 'end', sortable: false, selected: true },
    ],
    taxes: [],
  }),
  computed: {
    filters() {
      return {
        tax: {
          vTag: 'select',
          label: i18n.t('pensionindex.filter'),
          items: this.taxes,
          options: { multiple: true },
        },
      };
    },
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    openPensionPage(id) {
      this.$router.push(`/settings/pension/${id}`);
    },
    async getConfig() {
      try {
        const {
          data: { tax },
        } = await configAPI.getProductConfig();
        this.taxes = tax;
      } catch (err) {
        await this.setError(err.message);
      }
    },
  },
};
</script>
