<template>
  <v-app>
    <app-navigation />
    <v-main class="light-grey">
      <router-view />
      <error-message />
    </v-main>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppNavigation from '@/components/AppNavigation';
import ErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'Equibase',
  components: {
    AppNavigation,
    ErrorMessage,
  },
  data: () => ({
    //
  }),
  watch: {
    '$store.state.errorMessage.code': {
      immediate: true,
      handler(code) {
        if (code === 404) {
          this.$router.push('/not-found');
          this.$store.dispatch('errorMessage/resetError');
        }
      },
    },
  },
};
</script>

<style>
@import './assets/styles/app.scss';
</style>

<style scoped>
@media print {
  main {
    padding: 0 !important;
  }
}
</style>
