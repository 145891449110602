
import Vue from 'vue';
import { configAPI, tenantAPI } from '@/services';

export default Vue.extend({
  components: {},
  async beforeMount() {
    if (!this.$store.state.tenantInfo.activeTenant) {
      await this.getSetUserTenant();
    }
    await this.getSetGeneralConfig();
  },
  methods: {
    async getSetUserTenant() {
      try {
        const { data: tenant } = await tenantAPI.getUserTenant();
        await this.$store.dispatch('tenantInfo/setActiveTenant', { tenant });
      } catch (err) {
        // TODO
      }
    },
    async getSetGeneralConfig() {
      try {
        const [{ data: horse } ] = await Promise.all([
          configAPI.getHorseConfig()
        ]);
        await this.$store.dispatch('generalConfig/setGeneralConfig', { horse })
      } catch (err) {
        console.log('err:', err);
        // TODO
      }
    }
  },
});
