export const companyInfo = {
  namespaced: true,

  state: () => ({
    name: '',
    address: '',
    postalCode: '',
    country: '',
    city: '',
    website: '',
    vatNumber: '',
    phone: '',
    mail: '',
  }),
};
