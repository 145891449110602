import { Config } from '@/models/config';

export const generalConfig = {
  namespaced: true,
  state: (): Config => ({
    horse: undefined,
  }),
  mutations: {
    setGeneralConfig(state, horse) {
      state.horse = horse;
    },
  },
  actions: {
    setGeneralConfig({ commit }, { horse }) {
      commit('setGeneralConfig', horse);
    },
  },
  getters: {
    horseConfig(state) {
      return state.horse;
    },
  },
};
