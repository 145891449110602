<template>
  <v-layout column fill-height>
    <print-header class="print-only" :title="language === 'NL' ? report.name : report.eng" />
    <v-card class="mx-5 mt-4 no-border-print" :title="report.name" outlined>
      <v-toolbar flat class="no-print">
        <v-toolbar-title>
          {{ language === 'NL' ? report.name : report.eng }}
        </v-toolbar-title>
      </v-toolbar>
      <v-layout column class="mx-4 mb-4">
        <div class="my-2">
          <p>
            {{ strings[language].undersigned }}
            {{ doctor ? doctor : strings[language].select }}
            {{ strings[language].scans(calculateScanDate(caseDate)) }}
          </p>
        </div>
        <div class="mb-2 grid">
          <span>{{ strings[language].surrogate_mare }}:</span>
          <span>{{ horseName(surrogate) }}</span>
          <span>{{ $t('gestation.chip') }}</span>
          <span>{{ surrogate.microchip }}</span>
          <span>{{ strings[language].age }}:</span>
          <span>{{ formatDate(surrogate.date_of_birth) }}</span>
        </div>
        <div class="my-2 grid">
          <span>{{ strings[language].donor_mare }}:</span>
          <span>{{ horseName(kees.horse) }}</span>
          <span>{{ $t('gestation.ueln') }}</span>
          <span>{{ kees.horse.ueln }}</span>
          <span>{{ $t('gestation.chip') }}</span>
          <span>{{ kees.horse.microchip }}</span>
        </div>
        <div class="my-2 grid">
          <span>{{ strings[language].donor_stallion }}:</span>
          <span>{{ horseName(kees.extra) }}</span>
          <span>{{ $t('gestation.ueln') }}</span>
          <span>{{ kees.extra.ueln }}</span>
          <span>{{ $t('gestation.chip') }}</span>
          <span>{{ kees.extra.microchip }}</span>
        </div>
        <div class="my-2">
          <p>
            {{ strings[language].condition(kees.extra, caseDate) }}
          </p>
        </div>
        <div>
          <p>
            {{ strings[language].declaration }}
          </p>
          <strong> {{ $t('gestation.salt') }} {{ formatDate(currentDate) }} </strong>
        </div>
        <signature-display v-if="doctor" class="ma-5" :doctor="doctor" />
      </v-layout>
    </v-card>
    <v-card class="ma-5 no-print" outlined>
      <v-card-title>{{ $t('gestation.title') }}</v-card-title>
      <v-row class="mx-1 mb-2">
        <v-col cols="6">
          <v-select
            v-model="doctor"
            :items="doctors"
            :label="$t('gestation.sign')"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="language"
            :items="languages"
            :label="$t('gestation.lang')"
            :menu-props="{ offsetY: true }"
            hide-details
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="end" class="gap-4 mb-5 no-print" dense>
      <Button type="back" :action="() => $router.back()" />

      <v-btn style="width: fit-content" color="success" depressed @click="print">
        {{ $t('gestation.print') }}
      </v-btn>
    </v-row>
  </v-layout>
</template>

<script>
import { print, formatDate, horseName, ownerName } from '@/Helpers';
import { CaseAPI, horseAPI, configAPI } from '@/services';
import SignatureDisplay from '@/components/SignatureDisplay/SignatureDisplay';
import PrintHeader from '@/components/common/PrintHeader.vue';
import { strings } from '@/consts/translations/Gestation.report.translation';
import Button from '@/components/buttons/Button.vue';

export default {
  components: { Button, PrintHeader, SignatureDisplay },
  data: () => ({
    doctor: null,
    doctors: [],
    caseDate: null,
    language: 'NL',
    languages: ['NL', 'ENG'],
    title: '',
    strings,
    surrogate: {},
    kees: { extra: {}, horse: {} },
    report: {},
    currentDate: null,
    protocol: '',
  }),
  beforeMount() {
    const { caseId, date, horse, title, protocol } = this.$route.query;
    this.caseDate = new Date(date);
    this.title = title;
    this.currentDate = new Date();
    this.protocol = protocol;
    Promise.all([this.getAllCases(caseId), this.getSurrgateHorse(horse), this.getProtocolReportTypes(title)]);
  },
  methods: {
    print,
    horseName,
    ownerName,
    formatDate,
    calculateAge(date) {
      return Math.abs(this.currentDate.getYear() - date.getYear());
    },
    calculateScanDate(scanDate) {
      const newDate = new Date(scanDate);
      let resultDate = new Date();
      if (this.protocol && this.protocol.split) {
        const aiType = this.protocol.split('-');
        switch (aiType[0].trim()) {
          case 'VERS':
            resultDate = aiType[1].includes('Embryotransplantatie')
              ? newDate.setDate(newDate.getDate() + 37)
              : newDate.setDate(newDate.getDate() + 45);
            break;
          case 'ICSI':
            resultDate = newDate.setDate(newDate.getDate() + 40);
            break;
          default:
            resultDate = newDate.setDate(newDate.getDate() + 45);
        }
      }
      return resultDate;
    },
    async getSurrgateHorse(horseId) {
      try {
        if (horseId) {
          const { data } = await horseAPI.getHorse(horseId);
          this.surrogate = data;
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getAllCases(caseId) {
      try {
        const { data } = await CaseAPI.get(caseId);
        this.kees = data;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    async getProtocolReportTypes(title) {
      try {
        const {
          data: { reportTypes },
        } = await configAPI.getProtocolReportTypes();
        const [report] = reportTypes.filter((rep) => rep.name === title);
        this.report = report;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
  },
};
</script>

<style scoped>
h4 {
  text-decoration: underline;
  margin-bottom: 0.5em;
}
b,
h4,
span,
time,
div {
  color: #314d9e;
}
strong,
.v-toolbar__title {
  font-weight: bolder;
  color: #e9622c;
}
div.grid {
  color: #314d9e;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
