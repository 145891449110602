import { get, post, put, deleteR } from '@/services/API';

export default {
  postLocation: (location) => post('/api/location', { location }),
  getLocations: (params) => get(`/api/location?${new URLSearchParams(params)}`),
  getLocation: (id) => get(`/api/location/${id}`),
  putLocation: (location) => put(`/api/location/${location._id}`, { location }),
  deleteLocation: (id) => deleteR(`/api/location/${id}`),
  getAvailableLocations: () => get('/api/location/available-locations'),
};
