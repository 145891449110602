
import Vue from 'vue';
import { exportTypes } from '@/consts';
import semenAPI from '@/services/SemenAPI';

export default Vue.extend({
  name: 'ExportTypeDialog',
  props: {
    selectedLots: { type: Array, default: () => [] },
    openDialog: { type: Boolean, default: false },
    maxLots: { type: Number, default: 0 },
  },
  data() {
    return {
      exportTypes,
      selectedExportType: '',
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog', !this.openDialog);
    },
    async updateSelectedLots() {
      try {
        await this.$store.dispatch('errorMessage/resetError');

        await Promise.all(
          this.selectedLots
            .map((lot) => {
              // eslint-disable-next-line no-param-reassign
              lot.export_type = this.selectedExportType;
              return lot;
            })
            .map(async (lot) => semenAPI.putSemenCollection(lot))
        );
        this.$emit('success', { type: 'Export type selecteren' });
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
  },
});
