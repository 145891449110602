<template>
  <v-card class="mx-5 mt-5 mb-5" outlined>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('researchsheet.search')"
        outlined
        dense
        single-line
        hide-details
      />
    </v-toolbar>
    <v-data-table class="ma-5" :headers="headers" :search="search" :items="horses" :loading="loading" :loading-text="$t('common.loading')">
      <template #no-data>
        {{ $t('researchsheet.nodata') }}
      </template>
      <template #item="props">
        <tr>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.surrogate_uid }}</td>
          <td>{{ props.item.microchip }}</td>
          <td>{{ displayLocation(props.item.location) }}</td>
          <td class="text-right">
            {{ formatDate(props.item.date_of_birth) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn bottom color="primary" class="ma-2 white--text" depressed dark fixed right @click="downloadResearchSheet()">
      {{ $t('researchsheet.title') }}
      <v-icon right dark> mdi-arrow-collapse-down </v-icon>
    </v-btn>
  </v-card>
</template>
<script>
import horseAPI from '@/services/HorseAPI.js';
import { formatDate } from '@/Helpers';
import { downloadResearchSheetPDF } from '@/Helpers/research-sheet.helper';
import { displayLocation } from '@/Helpers/location.helper';
import i18n from '@/translations/i18n';

export default {
  data() {
    return {
      title: i18n.t('researchsheet.title'),
      search: '',
      horses: [],
      headers: [
        { text: i18n.t('researchsheet.name'), value: 'name', selected: true },
        { text: i18n.t('researchsheet.nr'), value: 'surrogate_uid', sortable: false, selected: false },
        { text: i18n.t('researchsheet.chip'), value: 'microchip', selected: false },
        { text: i18n.t('researchsheet.location'), value: 'location', selected: true },
        { text: i18n.t('researchsheet.birth'), align: 'end', value: 'date_of_birth', selected: false },
      ],
      loading: true,
      URLParameters: {
        type: 'Merrie',
        death: false,
        surrogate: true,
        location: true,
        date_of_birth: true,
        surrogate_uid: true,
        microchip: true,
      },
    };
  },
  mounted() {
    this.getHorses();
  },
  methods: {
    displayLocation,
    formatDate,
    async getHorses() {
      this.loading = true;
      try {
        const {
          data: { results, total },
        } = await horseAPI.getHorses(this.URLParameters);
        this.horses = results;
        this.totalHorses = total;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      } finally {
        this.loading = false;
      }
    },
    downloadResearchSheet() {
      downloadResearchSheetPDF('onderzoeksfiches', this.horses);
    },
  },
};
</script>
