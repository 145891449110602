<template>
  <div>
    <v-toolbar flat>
      <v-spacer />
      <v-btn depressed color="primary" class="d-print-none" @click="openCreateDialog">
        {{ $t('common.actions.checkIn') }}
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="d-print-none ml-3"
        :disabled="!runningLodgings || !horse.lodgings.length"
        @click="endLodging()"
      >
        {{ $t('common.actions.checkOut') }}
      </v-btn>
    </v-toolbar>
    <v-data-table :headers="headers" sort-by="arrival" :items="horse.lodgings" :loading="loading" :loading-text="$t('common.loading')">
      <template #no-data>
        {{ $t('common.noneFound', { item: $t('horse.stays').toLowerCase() }) }}
      </template>
      <template #item="props">
        <tr>
          <td>
            <b>{{ horse.name }}</b>
          </td>
          <td>{{ formatDate(props.item.arrival) }}</td>
          <td v-if="props.item.departure">
            {{ formatDate(props.item.departure) }}
          </td>
          <td v-else>-</td>
          <td>{{ differenceDays(props.item.departure || new Date(), props.item.arrival) }} {{ $t('lodging.labels.days') }}</td>
          <td v-if="props.item.location">
            <label v-if="props.item.location.prefix">
              {{ props.item.location.stable }} {{ props.item.location.prefix }}-{{ props.item.location.name }}
            </label>
            <label v-else> {{ props.item.location.stable }} {{ props.item.location.name }} </label>
          </td>
          <td v-else>-</td>
          <td>
            <Tag :color="props.item.billable ? 'success' : 'grey'" :text="props.item.status" />
          </td>
          <td class="text-right">
            <v-tooltip v-if="checkForOverlap(horse.lodgings, props.item)" bottom>
              <template #activator="{ on }">
                <v-icon dark color="secondary" class="d-print-none mr-5" v-on="on"> mdi-alert </v-icon>
              </template>
              <span>{{ $t('lodging.warnings.foundDupe') }}</span>
            </v-tooltip>
            <v-tooltip v-if="props.item.status !== LodgingStatus.INVOICED" bottom>
              <template #activator="{ on }">
                <v-icon dark color="primary" @click="openEditDialog(props.item)" v-on="on"> mdi-pencil </v-icon>
              </template>
              <span>{{ $t('common.actions.edit') }}</span>
            </v-tooltip>
            <v-tooltip v-if="props.item.status !== LodgingStatus.INVOICED" bottom>
              <template #activator="{ on }">
                <v-icon dark color="primary" @click="deleteLodging(props.item)" v-on="on"> mdi-delete </v-icon>
              </template>
              <span>{{ $t('common.actions.delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="openDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="!editing" class="headline">{{ $t('lodging.labels.add') }}</span>
          <span v-else class="headline">{{ $t('common.actions.edit') }}</span>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <date-picker
                  :label="$t('lodging.labels.arrivalDate')"
                  :date="arrival"
                  :no-border="true"
                  :required="true"
                  mode="dateTime"
                  :max="departure || new Date()"
                  @select-date="arrival = $event"
                />
                <date-picker
                  :label="$t('lodging.labels.departureDate')"
                  :date="departure"
                  :no-border="true"
                  mode="dateTime"
                  :min="arrival || new Date()"
                  @select-date="departure = $event"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="location.stable"
                  :items="stables"
                  :label="$t('lodging.labels.stable')"
                  :disabled="horse.death || (!!location.stable && editing)"
                  :loading="loading"
                  :menu-props="{ offsetY: true }"
                  clearable
                  required
                  :rules="[(stable) => !!stable || $t('lodging.warnings.chooseStable')]"
                  @input="selectStable"
                  @click:clear="clearStable"
                />
              </v-col>
              <v-col v-if="showPrefix" cols="12" md="4">
                <v-select
                  v-model="locationPrefix"
                  :items="prefixes"
                  :label="$t('lodging.labels.prefix')"
                  :loading="loadingLocations"
                  :menu-props="{ offsetY: true }"
                  clearable
                  @click:clear="locationPrefix = ''"
                />
              </v-col>
              <v-col cols="12" :md="showPrefix ? 8 : 12">
                <v-select
                  v-model="location._id"
                  :items="filteredLocationsOnPrefix"
                  item-value="_id"
                  item-text="name"
                  item-disabled="disabled"
                  :suffix="location.disabled ? '(Volzet)' : ''"
                  :label="$t('lodging.labels.location')"
                  :disabled="horse.death || !location.stable"
                  :menu-props="{ offsetY: true }"
                  :loading="loadingLocations"
                  :rules="[(loc) => !!loc || $t('lodging.warnings.chooseLocation')]"
                  required
                  return-object
                  clearable
                  @input="selectLocation"
                  @click:clear="clearLocation"
                >
                  <template #item="{ item }">
                    <div v-if="item.disabled">
                      {{ item.name }} <span class="sub">{{ $t('lodging.labels.full') }}</span>
                    </div>
                    <div v-else class="below">
                      {{ item.name }}
                      <span class="free sub">{{ $t('lodging.labels.free') }} {{ item.places - item.horses.length }}</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="billable" :label="$t('lodging.dialog.billable')" />
              </v-col>
            </v-row>
            <v-row v-if="!editing" dense>
              <v-col>
                <v-select
                  v-model="selectedPensionType"
                  :items="pensionTypes"
                  item-text="name"
                  item-value="_id"
                  :label="$t('lodging.labels.pension')"
                  :disabled="horse.death || !billable"
                  :menu-props="{ offsetY: true }"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1">
            <div v-if="checkForOverlap(horse.lodgings, { _id: lodging._id, arrival, departure })" class="d-flex align-center caption mb-1">
              <v-icon dark color="secondary" class="d-print-none mr-2 flex-shrink-0"> mdi-alert </v-icon>
              {{ $t('lodging.warnings.dateDupe') }}
            </div>
            <div class="d-flex justify-end">
              <v-btn color="blue darken-1" text @click="closeDialog">
                {{ $t('common.actions.cancel') }}
              </v-btn>
              <v-btn :disabled="!valid" text color="blue darken-1" @click="saveLodging">
                {{ $t('common.actions.save') }}
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { configAPI, pensionAPI } from '@/services';
import horseAPI from '@/services/HorseAPI.js';
import { formatDate, differenceDays } from '@/Helpers';
import locationAPI from '@/services/LocationAPI';
import DatePicker from '@/components/DateSelector/DatePicker';
import Tag from '@/components/common/Tag';
import { startOfDay } from 'date-fns';
import { LodgingStatus } from '@/consts/index.ts';
import { getLocationPrefixes } from '@/Helpers/location.helper';
import i18n from '@/translations/i18n';

export default {
  components: { DatePicker, Tag },
  props: {
    horse: { type: Object, required: true },
  },
  data: () => ({
    loading: false,
    arrival: '',
    departure: '',
    headers: [
      { text: i18n.t('common.horse'), align: 'left', sortable: false },
      { text: i18n.t('lodging.labels.arrivalDate'), sortable: false },
      { text: i18n.t('lodging.labels.departureDate'), sortable: false },
      { text: i18n.t('lodging.labels.stay'), sortable: false },
      { text: i18n.t('lodging.labels.location'), sortable: false },
      { text: i18n.t('lodging.labels.status'), sortable: false },
      { text: i18n.t('common.actions.edit'), align: 'right', value: 'action', sortable: false, class: 'd-print-none' },
    ],
    openDialog: false,
    editing: false,
    locations: [],
    pensionTypes: [],
    selectedPensionType: '',
    location: {},
    billable: true,
    loadingLocations: false,
    lodging: {},
    valid: true,
    LodgingStatus,
    locationPrefix: '',
    prefixes: [],
  }),
  computed: {
    runningLodgings() {
      if (this.horse.lodgings?.length) {
        return this.horse.lodgings.some((lodging) => !lodging.departure);
      }
      return true;
    },
    showPrefix() {
      return this.locations.some((loc) => loc.prefix);
    },
    filteredLocationsOnPrefix() {
      return this.locationPrefix
        ? this.locations.filter((loc) => loc.prefix?.toLowerCase() === this.locationPrefix.toLowerCase())
        : this.locations;
    },
    stables() {
      return this.$store.state.tenantInfo.activeTenant?.stables || [];
    },
  },
  methods: {
    formatDate,
    differenceDays,
    getLocationPrefixes,
    checkForOverlap(lodgings, currentLodging) {
      return lodgings?.some(
        (lodging) =>
          lodging._id !== currentLodging._id &&
          (!lodging.departure || startOfDay(new Date(lodging.departure)) > startOfDay(new Date(currentLodging.arrival))) &&
          (!currentLodging.departure || startOfDay(new Date(lodging.arrival)) < startOfDay(new Date(currentLodging.departure)))
      );
    },
    async saveLodging() {
      if (this.$refs.form.validate()) {
        try {
          // Wait untill save operation is complete before closing the dialog
          await (this.editing ? this.editLodging(this.arrival, this.departure) : this.createLodging(this.arrival, this.departure));
          this.closeDialog();
        } catch (error) {
          // Do not close dialog
          await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
        } finally {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        }
      }
    },
    async createLodging(arrival, departure) {
      try {
        const { data } = await horseAPI.postLodging(
          this.horse._id,
          {
            arrival,
            departure: departure || undefined,
            location: this.location,
            billable: this.billable,
            status: this.billable ? LodgingStatus.INVOICEABLE : LodgingStatus.NOT_INVOICEABLE,
          },
          this.selectedPensionType
        );
        this.horse.lodgings = data.lodgings;

        if (!departure) {
          this.horse.location = this.location;
        }

        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
        throw err;
      }
    },
    async editLodging(arrival, departure) {
      try {
        const lodgingToUpdate = {
          ...this.lodging,
          arrival,
          departure: departure || undefined,
          location: this.location,
          billable: this.billable,
          status: this.billable ? LodgingStatus.INVOICEABLE : LodgingStatus.NOT_INVOICEABLE,
        };
        await horseAPI.putLodging(this.horse._id, lodgingToUpdate);
        this.horse.lodgings = this.horse.lodgings.map((lodge) => {
          if (lodge._id === lodgingToUpdate._id) {
            return { ...lodgingToUpdate };
          }
          return lodge;
        });
        if (!departure) {
          this.horse.location = this.location;
        }
        await this.$store.dispatch('errorMessage/resetError');
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
        throw error;
      }
    },
    async endLodging() {
      try {
        this.horse.lodgings = await Promise.all(
          this.horse.lodgings.map(async (lodging) => {
            if (!lodging.departure) {
              // eslint-disable-next-line no-param-reassign
              lodging.departure = new Date();
              await horseAPI.putLodging(this.horse._id, lodging);
            }
            return lodging;
          })
        );
        this.runningLodgings = false;
        this.horse.location = { stable: '', name: '' };

        await this.$store.dispatch('errorMessage/resetError');
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      }
    },
    async deleteLodging(lodgingToDelete) {
      try {
        await horseAPI.deleteLodging(this.horse, { ...lodgingToDelete, _id: lodgingToDelete._id.toString() });
        this.horse.lodgings = this.horse.lodgings.filter((lodging) => lodging._id !== lodgingToDelete._id);
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    openCreateDialog() {
      this.openDialog = true;
      this.getPensionTypesForHorse();
    },
    async openEditDialog(lodging) {
      this.arrival = lodging.arrival;
      this.departure = lodging.departure || '';
      this.location = { ...lodging.location } || { stable: '', name: '', _id: '' };
      this.billable = lodging.billable;
      if (lodging.location) {
        this.getLocations(lodging.location);
      }
      this.lodging = lodging;
      this.editing = true;
      this.openDialog = true;
    },
    unsetData() {
      this.arrival = undefined;
      this.departure = undefined;
      this.location = {};
      this.lodging = {};
      this.locationPrefix = '';
    },
    closeDialog() {
      this.openDialog = false;
      this.editing = false;
      this.unsetData();
    },
    selectStable(stable) {
      if (stable) {
        this.location = {
          stable,
        };
        this.getLocations(this.location);
      }
    },
    clearStable() {
      this.locations = [];
      this.location = {};
    },
    selectLocation(location) {
      if (location) {
        this.location = location;
      }
    },
    clearLocation() {
      this.location = {};
    },
    async getLocations({ stable }) {
      this.loadingLocations = true;
      try {
        const {
          data: { locations },
        } = await locationAPI.getLocations({ stable });
        this.locations = locations.map((loc) => ({
          ...loc,
          disabled: !loc.horses.some((horse) => horse._id === this.horse._id) && loc.horses.length === loc.places,
        }));
        this.prefixes = getLocationPrefixes(locations);
      } catch (err) {
        await this.setError(err.message);
      } finally {
        this.loadingLocations = false;
      }
    },
    async getPensionTypesForHorse() {
      try {
        this.loading = true;
        const {
          data: { results },
        } = await pensionAPI.getAll({ availableFor: this.horse.type });
        this.pensionTypes = results;
      } catch (error) {
        await this.$store.dispatch('errorMessage/setError', { message: error.message, code: error.statusCode });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.below {
  display: flex;
  flex-direction: column;
}
.sub {
  color: #7f7f7f;
  font-size: 0.75rem;
  margin: 0 0 0 5px;
}
.free {
  margin: 0;
}
</style>
