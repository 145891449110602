import { auth } from '@/translations/dutch/auth';
import { cases } from '@/translations/dutch/cases';
import { common } from '@/translations/dutch/common';
import { config } from '@/translations/dutch/config';
import { customers } from '@/translations/dutch/customers';
import { dashboard } from '@/translations/dutch/dashboard';
import { fertility } from '@/translations/dutch/fertility';
import { horses } from '@/translations/dutch/horses';
import { medication } from '@/translations/dutch/medication';
import { reports } from '@/translations/dutch/reports';
import { lodging } from '@/translations/dutch/lodging';
import { dutchToRefactor } from '@/translations/dutch/dutchToRefactor';

export const dutch = {
  ...dutchToRefactor,
  // One translation file per primary nav item + common
  auth,
  cases,
  common,
  config,
  customers,
  dashboard,
  fertility,
  horses,
  medication,
  reports,
  lodging,
};
