
import { notificationAPI } from '@/services';
import Vue from 'vue';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import { formatDate, printName } from '@/Helpers';

export default Vue.extend({
  name: 'EventCalendar',
  props: {},
  data: () => ({
    type: 'month',
    value: '',
    events: [],
    title: '',
    selectedEvent: null,
    selectedElement: null,
    selectedOpen: false,
  }),
  methods: {
    next() {
      (this.$refs.calendar as any).next();
    },
    previous() {
      (this.$refs.calendar as any).prev();
    },
    async getEvents({ start, end }) {
      this.title = format(new Date(start.date), 'MMM yy');
      const from = format(subDays(new Date(start.date), 7), 'yyyy-MM-dd');
      const until = format(addDays(new Date(end.date), 7), 'yyyy-MM-dd');
      const result = await notificationAPI.filter({ from, until });

      this.events = result.data.map((notification) => {
        const horseTag = notification.tags.find((tag) => tag.elementModel === 'Horse');
        return {
          start: startOfDay(new Date(notification.expirationDate)),
          name: `${horseTag ? `${printName(horseTag)} - ` : ''}${notification.text}`,
          timed: false,
          notification,
        };
      });
    },
    getEventColor(event) {
      return event.notification.active ? '#c87669' : '#d9bfb3';
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async deactivateEvent(event) {
      try {
        await notificationAPI.put({ ...event.notification, active: false });
        const newEvent = {
          ...event,
          notification: {
            ...event.notification,
            active: false,
          },
        };
        this.selectedEvent = newEvent;
        this.events = this.events.map((e) => (e === event ? newEvent : e));
      } catch (err) {
        await this.$store.dispatch('errorMessage/setError', { message: err.message, code: err.statusCode });
      }
    },
    formatDate,
    printName,
  },
});
